import useBackend from "@/services/api2.service";
import useEventService from "@/services/events.service";
import { DiscoveryStatus, FinancialAccount, Institution, useV3Store } from '../store/navi.store';
import { computed, defineAsyncComponent, watch } from "vue";
import { AccountFilterParser } from "./aa-filters";
import useUtilService from "@/services/util.service";
import { storeToRefs } from "pinia";
import { Logger } from 'tslog';
import { AxiosError } from "axios";
import { AccountLinkedResProto, AccountLinkingResponseProto } from "@/store/v2.store";
import AALogin from "@/views/navi/AALogin.vue";
import AccountDiscovery from "@/views/navi/AccountDiscovery.vue";
import SelectBanks from '@/views/navi/SelectBanks.vue';
import { useResetStore } from "../../../services/reset-store";
import { AuthInitRequest, LinkedAccount, ConsentAction } from "@finarkein/aasdk-core";
import { ERROR_RESPONSE_CODES } from "@/constants/constants";
import emitter from "@/composables/event.emitter";
import { ResponseWrapper } from './response-wrapper';
import moment from "moment";


export const enum ConductorEvent {
  OPEN = 'OPEN',
  ERROR = 'ERROR',
  EXIT = 'EXIT',

  AA_SELECTED = 'AA_SELECTED',
  FIP_SELECTED = 'FIP_SELECTED',
  AA_OTP_SENT = 'AA_OTP_SENT',
  AA_OTP_RESENT = 'AA_OTP_RESENT',
  UNAVAILABLE_FIP_SELECTED = 'UNAVAILABLE_FIP_SELECTED',
  FIP_SEARCH = 'FIP_SEARCH',

  // AUTO Discovery Mode
  AUTO_DISCOVERY_MODE = 'AUTO_DISCOVERY_MODE',
  /**
   * Failed to Send AA OTP.
   */
  AA_OTP_FAILED = 'AA_OTP_FAILED',
  AA_OTP_VALID = 'AA_OTP_VALID',
  AA_OTP_INVALID = 'AA_OTP_INVALID',
  /**
   * Failed to verify the OTP due to an interaction error with the AA service.
   */
  AA_OTP_VERIFY_FAILED = 'AA_OTP_VERIFY_FAILED',
  ALT_MOBILE_AA_OTP_SENT = 'ALT_MOBILE_AA_OTP_SENT',
  ALT_MOBILE_AA_OTP_RETRY = 'ALT_MOBILE_AA_OTP_RETRY',
  ALT_MOBILE_AA_OTP_RESENT = 'ALT_MOBILE_AA_OTP_RESENT',
  ALT_MOBILE_AA_OTP_FAILED = 'ALT_MOBILE_AA_OTP_FAILED',
  ALT_MOBILE_AA_OTP_VALID = 'ALT_MOBILE_AA_OTP_VALID',
  ALT_MOBILE_AA_OTP_INVALID = 'ALT_MOBILE_AA_OTP_INVALID',
  /**
   * Failed to verify the Alt mobile OTP due to an interaction error with the AA service.
   */
  ALT_MOBILE_AA_OTP_VERIFY_FAILED = 'ALT_MOBILE_AA_OTP_VERIFY_FAILED',
  ACCOUNTS_DISCOVERED = 'ACCOUNTS_DISCOVERED',
  ACCOUNTS_DISCOVERY_FAILED = 'ACCOUNTS_DISCOVERY_FAILED',
  LINKED_ACCOUNTS_FETCHED = 'LINKED_ACCOUNTS_FETCHED',
  NO_LINKED_ACCOUNTS = 'NO_LINKED_ACCOUNTS',
  ACCOUNTS_LINKED = 'ACCOUNTS_LINKED',
  ACCOUNTS_LINKING_SKIPPED = 'ACCOUNTS_LINKING_SKIPPED',
  ACCOUNTS_LINKING_FAILED = 'ACCOUNTS_LINKING_FAILED',
  ACCOUNTS_LINKING_RETRY = 'ACCOUNTS_LINKING_RETRY',
  ACCOUNTS_LINKING_OTP_SENT = 'ACCOUNTS_LINKING_OTP_SENT',
  ACCOUNTS_LINKING_OTP_RESENT = 'ACCOUNTS_LINKING_OTP_RESENT',
  ACCOUNTS_LINKING_OTP_VALID = 'ACCOUNTS_LINKING_OTP_VALID',
  ACCOUNTS_LINKING_OTP_INVALID = 'ACCOUNTS_LINKING_OTP_INVALID',
  ACCOUNTS_LINKING_OTP_FAILED = 'ACCOUNTS_LINKING_OTP_FAILED',

  CONSENT_GRANTED = 'CONSENT_GRANTED',
  CONSENT_DENIED = 'CONSENT_DENIED',
  CONSENT_FAILED = 'CONSENT_FAILED',
  CONSENT_PENDING = 'CONSENT_PENDING',

  AA_ERROR = 'AA_ERROR',

  // Screen view related
  TRANSITION_VIEW = 'TRANSITION_VIEW',
  SESSION_TIMED_OUT = 'SESSION_TIMED_OUT',

  // Journey metric related
  JOURNEY_METRIC = 'JOURNEY_METRIC'
}

export const enum ErrorTypes {
  API_ERROR = "API_ERROR",
  NET_ERROR = "NET_ERROR",
  INSTITUTION_ERROR = "INSTITUTION_ERROR",
  FLOW_ERROR = "FLOW_ERROR",
  INPUT_ERROR = "INPUT_ERROR"
}

export const enum ErrorCodes {
  UNKNOWN = "UNKNOWN",
  NO_NETWORK = "NO_NETWORK",
  DATA_INVALID = "DATA_INVALID",
  ALTERNATIVE_CHOSEN = "ALTERNATIVE_CHOSEN",
  BAD_REQUEST = "BAD_REQUEST",
  INVALID_CONFIG = "INVALID_CONFIG",
  INVALID_INSTITUTION = "INVALID_INSTITUTION",
  FAILURE = "FAILURE"
}

export const enum LastStatus {
  REQUIRES_INIT = 'requires_init',
  REQUIRES_FIP_SELECTION = 'requires_fip_selection',
  REQUIRES_AA_SELECTION = 'requires_aa_selection',
  REQUIRES_AA_OTP = 'requires_aa_otp',
  REQUIRES_LINKED_ACCOUNTS = 'requires_linked_accounts',
  REQUIRES_ACCOUNTS_DISCOVERY = 'requires_accounts_discovery',
  REQUIRES_ACCOUNTS_SELECTION = 'requires_accounts_selection',
  REQUIRES_ACCOUNT_LINKING='requires_account_linking',
  REQUIRES_CONSENT_ACTION='requires_consent_action',
  UNKNOWN = 'unknown'
}

export const enum ErrorMessages {
  OTP_VALIDATION_FAILED = 'OTP Validation Failed.',
  INVALID_ACCOUNTS = 'INVALID_ACCOUNTS_LINKED',
  SESSION_EXPIRED = 'Session Expired',
  BAD_REQUEST = 'Bad Request',
  SOME_UNKOWN_ERROR = 'Some unknown error',
  MOBILE_SAME_AS_USERID = 'Please try with another mobile number, this one is same as currently active mobile number',
  OTP_EXPIRED = 'Otp already used or expired.',
  NO_INSTITUTION = 'An institution must be provided',
  UNSUPPORTED_FIP = 'UNSUPPORTED_FIP',
  JOURNEY_CANCELLED="Journey Cancelled"
}

export const enum CAUSE{
  NO_DATA = 'NO_DATA',
  DATA_INVALID="DATA_INVALID",
  UNKNOWN="UNKNOWN",
  FAILURE="FAILURE"
}

export const enum CAUSE_TYPE{
  FIP_ERROR = "FIP_ERROR",
  AA_ERROR = "AA_ERROR",
}

export const views = {
  discovery: {
    name: 'accounts',
    comp: AccountDiscovery
  },
  login: { 
    name: 'login',
    comp: AALogin 
  },
  selectBanks: { 
    name: 'select-fip',
    comp: SelectBanks 
  },
  thankYou: { 
    name: 'thank-you',
    comp: defineAsyncComponent(() => import('@/views/navi/ThankYou.vue')) 
  },
}

const log = new Logger({
  name: '[conductor-lite]',
  // prefix: ['[conductor-lite]'],
  prettyLogTimeZone: 'local',
  hideLogPositionForProduction: true // process.env.NODE_ENV !== 'production' 
});

export const eventPayload = (requestId: string, fields?: Record<string, any>) => {
  return {
    requestId,
    timestamp: new Date().toISOString(),
    ...fields
  }
}

export const enum ConductorFeatures {
  ACCOUNTS_AUTO_SELECT = "accounts.auto-select",
  AUTO_DISCOVERY_FIP_COUNT="consent.auto.discovery.top_n",
  FIP_ALT_MOBILE="fip.alt-mobile.enable",
  ACCOUNTS_AUTO_DISCOVERY_LIMIT = "accounts.discovery.auto.implicit-retries"
}
const knownTenantFipAaPrefs = new Map<string, Map<string, string>>();
const naviPrefs = new Map<string, string>();
naviPrefs.set("sbi-fip-uat", 'nadl');
knownTenantFipAaPrefs.set("navi", naviPrefs);

export default function useConductor() {
  const events = useEventService();
  const api = useBackend();
  const store = useV3Store();
  const utils = useUtilService();
  const { filterParser, lastKnownStatus, viewHandler,
    aaSdkAdapter, aaHandle, aaSdk, isProcessing, troubleshootError,
    otpReference, awaitNext, exitWorld, aaAuth/* ,otpForUser  */, institutionWiseAccounts, currentMobile, autoDiscoveryCount, totalAccountsCount, consentAction,
    removeListener } = storeToRefs(store);
  const resetStore = useResetStore();
  const operationWrapper = new ResponseWrapper();

  /**
   * Initialize conductor using just the request identifier.
   * 
   * @param requestId 
   */

  // const consentRes = {} as any;

  const init = async (requestId: string) => {
    lastKnownStatus.value = LastStatus.REQUIRES_INIT;
    events.fire(ConductorEvent.OPEN, eventPayload(requestId));
    
    return await api.getRequestDetails(requestId)
      .then(async (data) => {
        // consentRes.value = data.webviewConsentTemplate.def
        // consentRes.push({
        //   datalife: data.webviewConsentTemplate.def.dataLife,
        // })
        // console.log(consentRes.value);
        // IMPORTANT!
        store.updateFromRequestDetails(requestId, data);

        lastKnownStatus.value = LastStatus.REQUIRES_FIP_SELECTION
        // Decide one of two things
        // 1. Show bank-selection (if not filters are available)
        // 2. Directly move to AA-selection
        data.accountFilters = data.accountFilters || [];
        if (data.accountFilters) {
          filterParser.value = new AccountFilterParser(data.accountFilters);

          if (filterParser.value.getInstitutions().size > 0) {
            // EVENT: FIPs are selected
            // TODO: Change to INSTITUTION_SELECTED ?
            events.fire(ConductorEvent.FIP_SELECTED, eventPayload(requestId, {
              institutions: Array.from(filterParser.value.getInstitutions()).map((v) => {
                return { 
                  id: v, // @DEPRECATED
                  fipId: v 
                };
              })
            }))

            // We need to move to aa-login view
            lastKnownStatus.value = LastStatus.REQUIRES_AA_SELECTION;
            //Available Fips 
            let availableFips: never[] = [];
            //get bank list before we initialize with AA
            await api.getBankList(store.requestId)
              .then(response => {
                const data = response.data;
                availableFips = response.data.content;
                store.updateInstitutions(data.content); // paginated response this is!
                log.silly("Fetched all financial institutions for requestId:", store.requestId)
              }).catch((e: any) => {
                log.fatal("Fetched all financial institutions for requestId:", store.requestId, e)
              });
           
            prepareAccountAggregator();
            // viewHandler.value = components.login;
            transitionToView(views.login)
            return; // go back from here!
          }
        }

        // TODO: handle show bank selection screen
        log.fatal("Unable to complete the journey at the moment, no institution provided");
        handleBadRequest(ErrorCodes.INVALID_INSTITUTION,ErrorTypes.INPUT_ERROR, ErrorMessages.NO_INSTITUTION,false);
        //_fireExit("INVALID_INSTITUTION", "INPUT_ERROR", ErrorMessages.NO_INSTITUTION);
      }).catch((e: any) => {
        log.fatal("Failed while initializing the journey", e?.message);
        if (e instanceof AxiosError) {
          const result = apiErrorHandler(e, events, requestId);
          if (result === true) {
            return; // return only if apiErrorHandler is showing modal to the user
          }
        }

        _fireExit("UNKNOWN", "API_ERROR", "Unhandled API error, reach out to Finarkein support");
      });
  };

  function transitionToView(view: any, layout = 'v3') {
    if (view) {
      if (view.comp) {
        viewHandler.value = view.comp;
      }

      events.fire(ConductorEvent.TRANSITION_VIEW, eventPayload(store.requestId, {
        name: view.name,
        layout
      }));
    }
  }

  // ==================
  // Consent Detail
  // =================

  // function consentDetail(requestId: string) {
  //   useBackend().getRequestDetails(requestId)
  //   .then((response: any) => {
  //     console.log(response.webviewConsentTemplate.def);
  //   })
  // }


  // ==================
  // AA LOGIN stuff : start 
  // =================

  function prepareAccountAggregator() {
    // try and use the filters and/or stored selected FIP(s)
    let selectedFips: String[] = [];
    if(filterParser.value){
      selectedFips = filterParser.value?.getInstitutions().size > 0 ? Array.from(filterParser.value?.getInstitutions()) : [];
    }
    const availableFips = Array.from(store.getInstitutions().value.values());
    try{
      aaHandle.value = utils.getAAFromFip(selectedFips,availableFips,store.availableAAs, store.tenantId);
    }catch(error:any){
      if(error?.message ===ErrorMessages.UNSUPPORTED_FIP){
        aaHandle.value = store.availableAAs[0].handle;
        fireJourneyEvents(ConductorEvent.UNAVAILABLE_FIP_SELECTED,JSON.stringify(selectedFips));
      }
    }
    events.fire(ConductorEvent.AA_SELECTED, eventPayload(store.requestId, {
      aaHandle: aaHandle.value,
      autoSelection: true
    }))
    lastKnownStatus.value = LastStatus.REQUIRES_AA_OTP;
    // trigger the next screen process
    awaitNext.value = _initForAaLogin();
  }

  const apiErrorHandler = (error: AxiosError, events: any, reqId: string) => {
    if (error.code == "ERR_NETWORK") {
      events.fire(ConductorEvent.ERROR, eventPayload(reqId, {
        errorCode: ErrorCodes.NO_NETWORK,
        errorMessage: error.message,
        errorType: ErrorTypes.NET_ERROR,
      }));
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      // Known API status codes handling
      const data: any = error.response.data;
      if (error.response.status === 409) {

        if (data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED || data?.errorCode === ERROR_RESPONSE_CODES.LINK_EXPIRED) {
          handleAPIError("Err, already processed!", "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.", 'Invalid Request ID/URL provided');
          return true;
        }
      }
      // if the request id is invalid
      if (error.response.status === 404) {
        if (data?.errorCode === ERROR_RESPONSE_CODES.DATA_NOT_FOUND) {
          handleAPIError("Sorry Could not fetch details", "Please try other methods to upload bank statement", 'Invalid Request ID/URL provided');
          return true;
        }
      }
      //any internal error
      if (error.response.status === 500) {
        if (data?.errorCode === ERROR_RESPONSE_CODES.IN_PROCESS || data?.errorCode===ERROR_RESPONSE_CODES.INTERNAL_ERROR) {
          handleAPIError("Sorry Could not fetch details", "Please try other methods to upload bank statement", 'Service Unavailable.', ErrorCodes.ALTERNATIVE_CHOSEN,ErrorTypes.FLOW_ERROR );
          return true;
        }
      }

      events.fire(ConductorEvent.ERROR, eventPayload(reqId, {
        errorCode: error.response.status.toString(),
        errorMessage: error.message,
        errorType: ErrorTypes.API_ERROR,
      }));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
  }

  function invokeTroubleshoot(ctx: any, resetData = false) {
    // modify the onPrimary call handler to add state clean as well
    const _primaryHandler = ctx.onPrimary;
    const _secondaryHandler = ctx.onSecondary;

    ctx.onPrimary = () => {
      // now invoke the supplied primary handler
      if (_primaryHandler) { // ensure handler is present
        _primaryHandler();
      }
      
      if (resetData) {
        troubleshootError.value = undefined
      }
    }

    ctx.onSecondary = () => {
      // now invoke the supplied secondary handler
      if (_secondaryHandler) { // ensure handler is present
        _secondaryHandler();
      }
    }

    troubleshootError.value = ctx;
  }

  async function resendAaOTP() {
    //reset store to get updated valuee
    store.otpReference = undefined;
    return await _sendOtpWithAA(true);
  }

  async function _sendOtpWithAA(isRetry = false) {
    const params = await updateAndGetWebviewParams(aaHandle.value);
    // At this stage, SDK Adapter must have been initialized, else this is way way wrong!
    aaSdk.value = aaSdkAdapter.value.getSdk(aaHandle.value)!!;
    aaSdk.value.cleanUp();
    return aaSdk.value?.authInit(params as AuthInitRequest)
      .then(async (response: any) => {
        const validateWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,'VALIDATE_USER');
        // aaError.value = false;
        if (validateWrapped?.status === 'SUCCESS') {
          // SEND - Request ok, otp has been sent, send OTP
          otpReference.value = validateWrapped.data.otpReference;
          log.silly(`${isRetry ? 'Re-' : ''}Sent OTP from Account Aggregator`, aaHandle.value);
          events.fire(isRetry ? ConductorEvent.AA_OTP_RESENT : ConductorEvent.AA_OTP_SENT, eventPayload(store.requestId, {
            aaHandle: aaHandle.value
          }));
        } else if (validateWrapped?.status === 'RETRY') {
          log.debug(`Recoverable error while ${isRetry ? 'Re-' : ''}sending OTP from Account Aggregator`, aaHandle.value);
          events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(store.requestId, {
            aaHandle: aaHandle.value,
            retriable: true
          }));
        } 
        else if (validateWrapped?.status === 'BAD_REQUEST') {
          events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(store.requestId, {
            aaHandle: aaHandle.value,
            retriable: false
           /*  message: validateWrapped.message,
            status: validateWrapped.status */
          }));
          handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.INPUT_ERROR,validateWrapped.message ,true);
        } else if(validateWrapped?.status === 'FAILURE'){
          //TODO : Switch AA 
          events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(store.requestId, {
            aaHandle: aaHandle.value,
            retriable: false
           /*  message: validateWrapped.message,
            status: validateWrapped.status */
          }));
          handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,validateWrapped.message,true);
        }else if(validateWrapped?.status ==='SESSION_ERROR'){
          handleSessionError();
        }
        else if(validateWrapped?.status ==='UNKNOWN_ERROR'){
          handleFailures(ErrorCodes.UNKNOWN,ErrorTypes.FLOW_ERROR,validateWrapped.message,true);
        }

        
       /*  else if (status === 'FAILURE' && (response['message'] === 'Consent request not in PENDING status' || response['message'] === 'Error retreiving consent request: Consent request not found')) {
          log.error(`Failed to ${isRetry ? 'Re-' : ''}send OTP, consent request already processed with`, aaHandle.value);
          events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(store.requestId, {
            aaHandle: aaHandle.value,
            retriable: false
          }));
          // TODO: Trigger an exit here!?
          _raiseErrorEventForAA("Request already processed", "AA_ERROR")
          _fireExit();
        } else if (status === 'FAILURE' && response['message'] === 'Invalid request or request timed out.') {
          // aaError.value = true;
          log.fatal('Failed to ' + (isRetry ? 'Re-' : '') + 'send OTP, some error occurred due to invalid request or request timeout, AA message?', response?.message);
          _raiseErrorEventForAA(response?.message || "Session/request timeout");
        } else if (status === 'FAILURE' && response['message'] === 'Maximum OTP limit reached for session.') {
          const ctx = {
            error: true,
            title: "Service Unavailable",
            useDefaultDesc: true,
            primaryText: 'Try other method',
            onPrimary: () => denyAndExit(
              'Service Unavailable', // message for SDK users
              ErrorCodes.ALTERNATIVE_CHOSEN, // errorCode
              false,        // false => don't exit once consent is rejected
              ErrorTypes.FLOW_ERROR // errorType
            )
          }
          //max attempts reached. wont be able to proceed further, try manually and ask user to use another way
          _raiseErrorEventForAA("Failed to connect to Account Aggregator", "AA_OTP_FAILED", ErrorTypes.INSTITUTION_ERROR, {
            aaHandle: aaHandle.value,
            retriable: false
          })
          log.fatal('Failed to ' + (isRetry ? 'Re-' : '') + 'send OTP, some error occurred due to session or request timeout, AA message?', response?.message);
          // invoke troubleshoot helper
          invokeTroubleshoot(ctx);

          return;
        }
        else if (status === 'FAILURE') {
          // otpErrorMsg.value = 'Otp validation failed, try again';
          // otpResendable.value = true;
          // Error retreiving consent request: Consent request not found
          log.fatal('Failed to ' + (isRetry ? 'Re-' : '') + 'send OTP, some error occurred due to session or request timeout, AA message?', response?.message);
          _raiseErrorEventForAA(response?.message || "Session/request timeout");
        } */
      }).catch((e: any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        } else {
          _raiseErrorEventForAA("Failed to connect to Account Aggregator", "AA_UNREACHABLE")
        }
        log.fatal('Failed to ' + (isRetry ? 'Re-' : '') + 'send OTP, some error occurred due to session or request timeout');
      });
  }

  async function _initForAaLogin() {
    // Initialize based on selected account aggregator
    await aaSdkAdapter.value.setUpSdk(aaHandle.value);
    log.silly("Initiating OTP from Account Aggregator", aaHandle.value);
    return await _sendOtpWithAA();
  }

  function _raiseErrorEventForAA(errorMessage: string, errorCode = "AA_ERROR", errorType = "INSTITUTION_ERROR", extraParams:any = {}) {
    events.fire(ConductorEvent.ERROR, eventPayload(store.requestId, {
        errorCode,
        errorType,
        errorMessage,
        ...extraParams
    }))
  }

  async function updateAndGetWebviewParams(aaHandle: string) {
    let isError = false;
    const webviewDetails = await api.getWebviewDetails(store.requestId, { aaHandle })
      .then((response) => {
        log.silly("Sucessfully resolved request details");
        return response.data;
      })
      .catch((e:any) => {
        isError = true;
        log.fatal("Encountered error while getting webview request details", e | e?.message);
        if (e instanceof AxiosError) {
          apiErrorHandler(e, events, store.requestId);
        }
      });
    
    if (isError) {
      log.fatal("Stopping at 'updateAndGetWebviewParams'");
      return;
    }
    
    const encryptedParams = utils.getEncrypedParams(webviewDetails.redirectUrl);
    store.updateWebViewParams(encryptedParams);
    store.updateConsentHandle(webviewDetails.consentHandle);
    return encryptedParams;
  }

  function verifyAaOTPAndNext(otpFromUser: string,startAuto = false) {
    return new Promise<void>((resolve, reject) => {
      aaSdk.value?.authVerify({
        otp: otpFromUser,
        otpReference: otpReference.value!
      }).then(async (response: any) => {
        const loginWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"AA_LOGIN");
        if (loginWrapped?.status === 'SUCCESS') {
          fireJourneyEvents(ConductorEvent.AA_OTP_VALID)
          aaAuth.value = true;
          // TODO: prepare for next screen to show
          lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
          prepareAccountsAndDiscoveryLinking();
          // viewHandler.value = components.discovery;
          transitionToView(views.discovery)
          log.silly("Verified AA OTP successfully")
          resolve(); // update the otp verification status
        } 
        else if(loginWrapped?.status ==='RETRY') {
          fireJourneyEvents(ConductorEvent.AA_OTP_INVALID)
          log.warn("Please retry again");
          reject("RETRY");
        }
        else if(loginWrapped?.status ==='FAILURE') {
          fireJourneyEvents(ConductorEvent.AA_OTP_INVALID)
          handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR, ErrorMessages.SOME_UNKOWN_ERROR,true);
         /*  log.warn("Please retry again");
          reject("RETRY"); */
        }
        else if(loginWrapped?.status ==='BAD_REQUEST') {
          fireJourneyEvents(ConductorEvent.AA_OTP_INVALID)
          handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.FLOW_ERROR, ErrorMessages.BAD_REQUEST,true);
         /*  log.warn("Please retry again");
          reject("RETRY"); */
        } else {
          // TODO: handle AA OTP verification error
          fireJourneyEvents(ConductorEvent.AA_OTP_VERIFY_FAILED)
          log.error("AA OTP verifification failed, with AA message?", response?.message);
          reject();
        }
      }).catch((e:any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        fireJourneyEvents(ConductorEvent.AA_OTP_VERIFY_FAILED)
        log.fatal("AA OTP verifification failed", e | e?.message);
        if(e===ErrorMessages.SESSION_EXPIRED){
          handleSessionError();
          return;
        }
        reject();
      });
    })
  }

  async function prepareAccountsAndDiscoveryLinking() {
    isProcessing.value = true; // Completes at the end!
    // 0. Fetch and update user details, need mobile number to be available for account discovery to work
    log.silly("Fetching consent request details with consent handle:", store.consentHandle);
    await aaSdk.value!.consentRequestDetails(store.consentHandle!) // should never be null here
      .then((response: any) => {
        const consentDetailsWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"CONSENT_DETAILS")
        if (consentDetailsWrapped?.status === 'SUCCESS') {
          // store them response first
          log.silly("Successully resolved consent details with consent handle:", store.consentHandle);
          store.updateConsentRequestInfo(consentDetailsWrapped.data);
        } else {
          // TODO: handle non-success scenario for getting linked accounts.
          handleBadRequest(ErrorCodes.ALTERNATIVE_CHOSEN, ErrorTypes.FLOW_ERROR)
          // invoke troubleshoot helper
          //invokeTroubleshoot(ctx);
          log.debug("Failed to get consent details with consent handle:", store.consentHandle);
        }
      }).catch((e:any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if(e===ErrorMessages.SESSION_EXPIRED){
          handleSessionError();
        }
        log.fatal("Error while trying to get consent details with consent handle:", store.consentHandle, e);
      });

    log.silly("Fetching user details from AA");
    await aaSdk.value!.getUserInfo()
      .then((response: any) => {
        const userInfoWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"USER_INFO");
        if (userInfoWrapped?.status === 'SUCCESS') {
          // store the response first
          log.silly("Got the user details from AA");
          store.updateUserInformation(userInfoWrapped.data.UserInfo);
        } else if(userInfoWrapped?.status === 'BAD_REQUEST'){
          handleBadRequest(ErrorCodes.DATA_INVALID,ErrorTypes.INPUT_ERROR,userInfoWrapped.message,true);
        }
        else {
          // TODO: handle non-success scenario for getting linked accounts.
          log.debug("Failed to get the user details from AA");
          handleBadRequest(ErrorCodes.ALTERNATIVE_CHOSEN, ErrorTypes.FLOW_ERROR)
        }
      }).catch((e:any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if(e===ErrorMessages.SESSION_EXPIRED){
          handleSessionError();
        }
        log.fatal("Error while trying to get user details from AA", e);
      });

    log.silly("Fetching available financial institutions for requestId:", store.requestId);
    // 1. Fetch linked accounts
    // 2. Check if selected FIP accounts are present
    // 3. Trigger discovery for missing FIP accounts
    //  3.1 Retry discovery a few times if fails
    awaitNext.value = Promise.resolve()
      .then(() => {
        log.silly("Fetching already linked accounts from AA");
        //just to check whether we get user linked acc or not
        //sessionStorage.removeItem(FinvuConstants.UID);
        lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
        return aaSdk.value!.getLinkedAccounts()
          .then(async (response: any) => {
            const linkedAccWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"LINKED_ACCOUNTS");
            if (linkedAccWrapped?.status === 'SUCCESS') {
              // store them first
              const linkedAccounts = linkedAccWrapped.data.LinkedAccounts;
              log.silly("Fetched already linked accounts from AA, there are ", linkedAccounts?.length > 0 ? 'a few' : 'none', 'linked');
              store.updateLinkedAccounts(linkedAccounts);
              fireJourneyEvents(ConductorEvent.LINKED_ACCOUNTS_FETCHED);
              return updateAndGetDiscoveryQueue(linkedAccounts);
            } else {
              // TODO: handle non-success scenario for getting linked accounts
              log.debug("Failed(?) to get already linked accounts from AA");
              fireJourneyEvents(ConductorEvent.NO_LINKED_ACCOUNTS);
              return updateAndGetDiscoveryQueue();
            }
          }).catch((e:any) => {
            // TODO: handle AA interaction error here
            // maybe inform the caller that we could not talk to AA here?
            if(e===ErrorMessages.SESSION_EXPIRED){
              handleSessionError();
              return new Set<string>();
            }
            log.error("Error while trying to get already linked accounts from AA", e);
            return updateAndGetDiscoveryQueue();
          });
      }).then(async (discoveryQue: Set<string>) => {
        lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_DISCOVERY;
        const institutions = store.getInstitutions();
        const financialInstruments = store.financialInstruments;
        let result = Promise.resolve();
        if(discoveryQue.size>0){
          discoveryQue.forEach(async id => {
            const institution = institutions.value?.get(id);
            if (!institution) {
              // TODO: we have a problem, requested FIP not present
              log.error(`Institution '${id}' in queue for discovery, but it's unavailable at the moment`);
              return;
            }
            result = result.then(async () => {
              store.updateDiscoveryStatus({
                discovering: true,
                auto: false
              }, institution);
              await doAccountDiscovery(store.customer.mobile!, institution, financialInstruments, 0 /* this is the default identifier */)
            });
          });
          return result;
        }else if(discoveryQue.size===0 && totalAccountsCount.value===0){
          result = result.then(async ()=>{
            fireJourneyEvents(ConductorEvent.AUTO_DISCOVERY_MODE,{},{},{userInitiated: false});
            autoDiscoveryCount.value+=1;
            await autoDiscovery();
          });
          return result;
        }
      
      }).finally(() => {
        // mark the loading false
        isProcessing.value = false;
      })
  }

  async function doAccountDiscovery(mobile: string, institution: Institution, instruments: string[], idSeq: number, retry = 0, limit = 3, auto = false) {
    log.silly("Discovering accounts from AA for", institution.id, retry > 0 ? "retry (x" + retry + ")" : '');
    if (retry >= limit) { // TODO: make the max retry count a global config?
      store.updateDiscoveryStatus({
        discovering: false ,// MARK Discovery as complete
        auto: auto
      }, institution);
      //cleanUpFips(auto,institution);
      return; // stop the retry loop here
    }

    // Prepare the discovery request
    const request = {
      FIPDetails: {
        fipId: institution.id,
        fipName: institution.name
      },
      FITypes: instruments,
      Customer: {
        Identifiers: [
          {
            category: "STRONG",
            type: "MOBILE",
            value: mobile
          }
        ]
      }
    };

    let doRetry = false;
    let retryLimit = limit;
    currentMobile.value = mobile;
    //isProcessing.value = true;
    const sts = moment();
    await aaSdk.value?.discoverAccounts(request)
      .then((response: any) => {
        const latency = moment.duration(moment().diff(sts)).asMilliseconds();
        let fipRequestSuccess = false;
        const discAccWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"ACCOUNT_DISCOVERY");
        if (discAccWrapped?.status === 'SUCCESS') {
          // all good! some accounts were found
          fipRequestSuccess = true;
          fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERED, request, {}, { 
            aaHandle: aaHandle.value,
            auto 
          });
          store.updateDiscoveredAccounts(discAccWrapped.data.DiscoveredAccounts, institution, idSeq);
          store.updateDiscoveryStatus({
            status: DiscoveryStatus.SUCCESS,
            auto:auto
          }, institution);
          lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_SELECTION;
          log.silly("Successful discovery from", institution.id);
        } else if (discAccWrapped?.status === 'RETRY') {
          fipRequestSuccess = true;
          // no accounts found
          // maybe re-try once more?
          doRetry = true;
          retryLimit = 1;
          fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, {}, {}, {
            aaHandle: aaHandle.value,
            cause: CAUSE.NO_DATA,
            // type: CAUSE_TYPE.FIP_ERROR,
            fipId: institution.id,
            auto: auto
          });
          log.debug("Successful discovery response(? trouble the institution once more), but no accounts found from", institution.id)
        } else if(discAccWrapped?.status === 'BAD_REQUEST' && !auto){
          doRetry = false;
          //cleanUpFips(auto, institution);
          handleBadRequest();
        } else if (discAccWrapped?.status === 'UNKNOWN_ERROR') {
          doRetry = false;
          //cleanUpFips(auto, institution);
          fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, request, {}, {
            aaHandle: aaHandle.value,
            cause: CAUSE.UNKNOWN, 
            // type: CAUSE_TYPE.AA_ERROR,
            fipId: institution.id,
            auto
          });
          log.error("Failed discovery response(? trouble the institution once more), but no accounts found from", institution.id)
          handleFailures(ErrorCodes.UNKNOWN, ErrorTypes.FLOW_ERROR, discAccWrapped.message, true);
          
        } else if(discAccWrapped?.status === 'SESSION_ERROR'){
          handleSessionError();
        } else {
          log.error("Some error while discovering accounts for {} with AA: {}", institution.id, aaHandle.value)
          doRetry = true; // try few more times
          fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, request, {}, {
            aaHandle: aaHandle.value,
            cause: CAUSE.UNKNOWN, 
            // type: CAUSE_TYPE.AA_ERROR,
            fipId: institution.id,
            auto
          });
        }

        fireJourneyEvents(ConductorEvent.JOURNEY_METRIC, request, {}, { 
          name: "FIP:AA:UserDiscoveryResponse",
          aaHandle: aaHandle.value,
          fipId: institution.id,
          latency,
          success: fipRequestSuccess
        }, { internal: true });
      }).catch((e: any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        // if session has expired , restart the journey, take user to login
        const latency = moment.duration(moment().diff(sts)).asMilliseconds()
        fireJourneyEvents(ConductorEvent.JOURNEY_METRIC, request, {}, { 
          name: "FIP:AA:UserDiscoveryResponse",
          aaHandle: aaHandle.value,
          fipId: institution.id,
          latency,
          success: false
        }, { internal: true });
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        } else {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, request, {}, {
            aaHandle: aaHandle.value,
            cause: CAUSE.UNKNOWN, 
            //type: CAUSE_TYPE.AA_ERROR,
            fipId: institution.id,
            auto
          });
        }
        log.fatal("Error while discovering accounts from {} with AA: {}", institution.id, aaHandle.value, e);
      }).finally(() => {
        store.updateDiscoveryStatus({
          discovering: doRetry, // MARK Discovery as complete
          auto:auto
        }, institution);
      })

    // Trigger retry if applicable
    if (doRetry) {
      doAccountDiscovery(mobile, institution, instruments, idSeq, ++retry, retryLimit, auto);
    }
    //cleanUpFips(auto, institution);
    //isProcessing.value = false;
  }

  function updateAndGetDiscoveryQueue(linkedAccounts: LinkedAccount[] = []) {
    log.silly("Evaluating institutions for account discovery");
    //const toDiscover = new Set<string>(filterParser.value?.getInstitutions() as Set<string>);
    const toDiscover = filterKnownFips(Array.from(store.getInstitutions().value.values()),filterParser.value?.getInstitutions() as Set<string> );
    log.silly("Requested institutions for account discovery", Array.from(toDiscover));
    const alreadyLinked = linkedAccounts
      .reduce((result, current) => result.add(current.fipId),
        new Set<string>());
    log.silly("Already linked institutions", Array.from(alreadyLinked));
    // Remove already linked accounts from discovery queue
    // alreadyLinked.forEach((value) => toDiscover.delete(value));
    store.updateInstitutionDiscoveryQueue(Array.from(toDiscover));
    log.silly("Institution(s) eligible for account discovery", Array.from(toDiscover));
    return toDiscover;
  }

  function filterKnownFips(fipList: Institution[], accountFilters: Iterable<any> | ArrayLike<any>){
    const filteredFips = fipList.filter((instituition: Institution) => {
      return Array.from(accountFilters).some((account) => {
        return account === instituition.id;
      });
    });
    return filteredFips.length> 0 ? new Set(filteredFips.map((data: { id: any; })=>data.id)) : new Set([]);
  }

  async function handleConsentAction(action: ConsentAction, selectedAccounts: FinancialAccount[], exitWithConsentReject = true, retryCount = 0, retryLimit  = 3) {
    consentAction.value = action;
    const request = {
      consentHandleId: store.consentHandle!,
      handleStatus: action,
      ver: store.consentRequestInfo?.ver!,
      FIPDetails: _shapeFIPDetails(selectedAccounts),
      FIU: {
        id: store.consentRequestInfo?.FIU.id!
      }
    };

    const retryLimitCount = retryLimit;
    return aaSdk.value?.consent(request)
      .then(async (response: any) => {
        const userConsentAction = operationWrapper.getModifiedResponse(response,aaHandle.value,"CONSENT_ACTION");
        if (userConsentAction?.status === 'SUCCESS') {
          log.silly("Consent granted for request", store.requestId);
          if (action === ConsentAction.ACCEPT) {
            const institutions = utils.getUniqueElementsByKeys(selectedAccounts, ['fipId', 'fipName'])
            .map((i: any) => {
                return {
                    id: i.fipId,
                    name: i.fipName
                }
            });
            fireJourneyEvents(ConductorEvent.CONSENT_GRANTED, {}, {}, eventPayload(store.requestId, {
              institutions
            }));
            events.fire('app_success', eventPayload(store.requestId, {
              consentStatus: action
            }));
          } else if (exitWithConsentReject) {
            fireJourneyEvents(ConductorEvent.CONSENT_DENIED);
            _fireExit('CONSENT_REJECTED', 'FLOW_ERROR', 'Consent rejected by user', {
              consentStatus: action,
              status: lastKnownStatus.value  
            });
          } else {
            // Fire an error instead
            const payload = eventPayload(store.requestId, {
              errorCode: 'CONSENT_REJECTED',
              errorType: 'FLOW_ERROR',
              errorMessage: 'Consent rejected by user',
              exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
            });
            events.fire(ConductorEvent.ERROR, payload);
          }

          return true;
        } 
        else if(userConsentAction?.status ==='RETRY'){
          if(retryCount< retryLimitCount){
            await handleConsentAction(action,selectedAccounts, true, ++retryCount, retryLimit);
          }else{
            handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,userConsentAction?.message, true);
          }
        }
        else if(userConsentAction?.status==='BAD_REQUEST'){
          handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.INPUT_ERROR,userConsentAction.message,true);
        }else {
          handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,userConsentAction?.message, true);
        }
      })
      .catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        } else {
          log.fatal(`Error while processing consent ${action} with AA`, aaHandle.value, e?.message | e);
          _fireExit('AA_ERROR', 'FLOW_ERROR', 'Error while confirming consent with Account Aggregator', {
            consentStatus: action
          });
        }
      }).finally(()=>{
        removeEventListeners();
      })
  }

  function _shapeFIPDetails(selectedAccounts: FinancialAccount[]) {
    const mapped = selectedAccounts.reduce((result, current) => {
      let ob = result.get(current.fipId);
      if (!ob) {
        ob = {
          FIP: {
            id: current.fipId
          },
          Accounts: []
        }
        result.set(current.fipId, ob);
      }
      const acc = { ...current } as any;
      delete acc.identifierSeq;

      ob.Accounts.push(acc); // now push

      return result;
    }, new Map<string, any>());

    return Array.from(mapped.values());
  }

  async function ensureNext() {
    if (awaitNext.value) {
      await awaitNext.value;
      awaitNext.value = undefined;
    }
  }

  function _fireExit(errorCode = "UNKNOWN", errorType = "UNKNOWN",
    errorMessage = "Unhandled error encountered", extraPayload = {}) {
    const payload = eventPayload(store.requestId, {
      errorCode,
      errorType,
      errorMessage,
      exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
      ...extraPayload
    });
    events.fire(ConductorEvent.EXIT, payload);
    // Trigger app_failure as well
    events.fire('app_failure', payload);
  }

  async function denyAndExit(exitErrorMessage: string = '', errorCode: string = 'CANCELLED', exitWithConsentReject: boolean = true, errorType = 'FLOW_ERROR') {
    const canDeny = aaAuth.value;
    if (canDeny) {
      await handleConsentAction(ConsentAction.DENY, [], exitWithConsentReject);
    }
    else if(!canDeny && lastKnownStatus.value === LastStatus.REQUIRES_AA_OTP){
      _fireExit(errorCode, errorType, ErrorMessages.JOURNEY_CANCELLED);
    }
    // If exit was not triggered by handleConsent, we need to do it now
    else if (!exitWithConsentReject || !canDeny) {
      _fireExit(errorCode, errorType, exitErrorMessage || "");
    }
   
  }

  async function _initForAlternateMobile(mobileNumber: string, resend = false) {
    if (mobileNumber === store.customer.mobile) {
      return Promise.reject(ErrorMessages.MOBILE_SAME_AS_USERID)
    }
    const req = { mobileNum: mobileNumber };
    return new Promise<void>((resolve, reject) => {
      aaSdk.value?.mobileAuthInit(req)
        .then((response: any) => {
          const altMobileWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"ALT_MOBILE");
          if (altMobileWrapped?.status === 'SUCCESS') {
            //checkForOTP('ALT_MOBILE');
            fireJourneyEvents(resend ? ConductorEvent.ALT_MOBILE_AA_OTP_RESENT : ConductorEvent.ALT_MOBILE_AA_OTP_SENT)
            resolve();
          } else if (altMobileWrapped?.status === 'RETRY') {
            // retriable?
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_RETRY)
            reject();
          } else if(altMobileWrapped?.status ==='BAD_REQUEST'){
            handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.INPUT_ERROR, altMobileWrapped.message, true);
          }
          else {
            //error or any other response
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_FAILED)
            reject();
          }
        }).catch((error: any) => {
          reject(error);
        });
    })

  }

  async function _confirmAlternateMobile(mobileNumber: string, inpOtpFromUser: string) {
    const request = {
      mobileNum: mobileNumber,
      otp: inpOtpFromUser
    }
    return new Promise<void>((resolve, reject) => {
      aaSdk.value?.mobileAuthVerify(request)
        .then((response: any) => {
          const altMobileWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"CONFIRM_ALT_MOBILE");

          if (altMobileWrapped?.status === 'SUCCESS') {
            store.additionalMobiles.push(mobileNumber);
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VALID)
            resolve();
            //start discovery of the accounts using new mobile number

          } else if (altMobileWrapped?.status === 'RETRY') {
            //otpMessage.value = response['message'];
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID)
            reject(ErrorMessages.OTP_VALIDATION_FAILED);

          }
          else if(altMobileWrapped?.status === 'BAD_REQUEST'){
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID)
            reject(ErrorMessages.OTP_VALIDATION_FAILED);
            handleBadRequest(ErrorCodes.DATA_INVALID,ErrorTypes.INPUT_ERROR, altMobileWrapped.message,true);
          } 
          else if(altMobileWrapped?.status === 'FAILURE'){
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID)
            reject(ErrorMessages.OTP_VALIDATION_FAILED);
          } 
          else {
            /* otpMessage.value = response['message'];
            console.error("there is some error", +response['message']); */
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED)
            reject();
          }
        }).catch((error: any) => {
          /* aaError.value = true;
          console.error('Faced issue in alternate mobile verification ' + e.message); */
          fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED)
          reject(error);
        });
    })
  }

  // Account linking related stuff
  async function initiateLinking(institution: Institution, selectedAccounts: FinancialAccount[], resend = false, retryCount = 0, retryLimit  = 3) {
    const fip = { fipId: institution.id, fipName: institution.name };
    const accounts = selectedAccounts.map(a => a.shapedForLinking());
    const accountLinkingRequest = {
      FIPDetails: fip,
      Customer: {
        Accounts: accounts
      }
    }
    const retryLimitCount =  retryLimit;
    return await aaSdk.value?.linkAccounts(accountLinkingRequest)
      .then(async (response: any) => {
        const linkingWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"ACCOUNT_LINKING");
        if (linkingWrapped?.status === 'SUCCESS') {
          // OTP for linking sent successfully
          fireJourneyEvents(resend ? ConductorEvent.ACCOUNTS_LINKING_OTP_RESENT: ConductorEvent.ACCOUNTS_LINKING_OTP_SENT)
          return { retryNow: false, response: linkingWrapped.data as AccountLinkingResponseProto }
        } else if (linkingWrapped?.status === 'RETRY') {
          // update the caller to store the state and enable retry?
          return { retryNow: true, response: linkingWrapped.data as AccountLinkingResponseProto }
        }else if(linkingWrapped?.status ==='BAD_REQUEST'){
          if(retryCount< retryLimitCount){
            await initiateLinking(institution,selectedAccounts,true,++retryCount, retryLimit);
          }else{
            handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,linkingWrapped.message,true);
          }
        } else if(linkingWrapped?.status ==='FAILURE'){
          handleFailures(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,linkingWrapped.message,true);
        }
         else {
          handleBadRequest(ErrorCodes.UNKNOWN, ErrorTypes.INSTITUTION_ERROR);
          log.error(`Error while initating account linking for ${fip.fipName} with AA`, aaHandle.value, response?.message);
        }
      }).catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
          throw ErrorMessages.SESSION_EXPIRED;
        }
        log.fatal(`Error while initating account linking for ${fip.fipName} with AA`, aaHandle.value, e?.message | e);
      });
  }

  async function confirmLinking(otp: number, refNum: string, meta: Institution) {
    const fipName = meta.name;
    const request = {
      AccountsLinkingRefNumber: refNum,
      token: otp
    }
    //const request = new ConfirmLinkingRequest(refNum, otp);
    return await aaSdk.value?.confirmLinking(request)
      .then((response: any) => {
        const confirmLinkingWrapped = operationWrapper.getModifiedResponse(response,aaHandle.value,"CONFIRM_ACCOUNT_LINKING");

        if (confirmLinkingWrapped?.status === 'SUCCESS') {
          // update the store
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_VALID);
          store.linkDiscoveredAccounts(confirmLinkingWrapped.data as AccountLinkedResProto, meta);
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKED)
        } 
        else if(confirmLinkingWrapped?.status ==='BAD_REQUEST'){
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.INPUT_ERROR,confirmLinkingWrapped.message,true);
        }
        else if(confirmLinkingWrapped?.status ==='FAILURE'){
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          handleBadRequest(ErrorCodes.FAILURE,ErrorTypes.FLOW_ERROR,confirmLinkingWrapped.message,true);
        }else if(confirmLinkingWrapped?.status==='RETRY'){
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          log.error(ErrorMessages.OTP_VALIDATION_FAILED);
          throw new Error(ErrorMessages.OTP_VALIDATION_FAILED);
        }
        else {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_FAILED);
          handleBadRequest(ErrorCodes.UNKNOWN, ErrorTypes.INSTITUTION_ERROR);
          log.error(`Error while confirm account linking using ref: '${refNum}' for ${fipName} with AA`, aaHandle.value);
        }
      }).catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        }
        log.fatal(`Error while confirm account linking using ref with unknown error: '${refNum}' for ${fipName} with AA`, aaHandle.value, e?.message | e);
        throw e;
      });
  }

  function exitTheWorld(quitely: boolean = false, errorMessage?: string, errorCode?: string) {
    if (quitely) {
      denyAndExit(errorMessage, errorCode, !quitely /** quitely = don't trigger exit*/);
    } else {
      // show the modal 
      exitWorld.value = true;
    }
  }

  function addMoreBankAccounts() {
    // viewHandler.value = components.selectBanks;
    transitionToView(views.selectBanks)
  }

  async function discoverAccountsFromInstitutions(institutions: Institution | Array<Institution>,retryLimit?:number, auto = false, mobile?:string) {
    let discoverList = [] as Array<Institution>;
    if (!(institutions instanceof Array)) {
      discoverList.push(institutions);
    } else {
      discoverList = institutions;
    }

    // Now trigger discovery
    isProcessing.value = true;
    //let result = Promise.resolve();
    for(const institution of discoverList){
      //discoverList.forEach( async (institution: any) => {
        //result = result.then( async () => {
          store.updateDiscoveryStatus({
            discovering: true,
            auto:auto
          }, institution);
          if(mobile && mobile!==store.customer.mobile){
            await doAccountDiscovery(mobile!, institution, store.financialInstruments, 1, 0, retryLimit, auto)
          }else{
            await doAccountDiscovery(store.customer.mobile!, institution, store.financialInstruments, 0 /* this is the default identifier */, 0, retryLimit, auto)
  
          }
        //});
        //return result;
      //});
    }
    // discoverList.forEach( async (institution: any) => {
    //   //result = result.then( async () => {
    //     store.updateDiscoveryStatus({
    //       discovering: true,
    //       auto:auto
    //     }, institution);
    //     if(mobile){
    //       await doAccountDiscovery(mobile!, institution, store.financialInstruments, 1, 0, retryLimit, auto)
    //     }else{
    //       await doAccountDiscovery(store.customer.mobile!, institution, store.financialInstruments, 0 /* this is the default identifier */, 0, retryLimit, auto)

    //     }
    //   //});
    //   //return result;
    // });
    // viewHandler.value = components.discovery;
    transitionToView(views.discovery)
    isProcessing.value = false;
  }

  function navigateBack(from?: string) {
    if (from === 'selectBanks') {
      // render the discovery view
      // viewHandler.value = components.discovery;
      transitionToView(views.discovery)
    }
  }
  //if the session is expired, ask user to login and restart again
  function handleSessionError() {
    const ctx = {
      error: true,
      title: "Session timeout",
      useDefaultDesc: false,
      primaryText: 'Retry Again',
      secondaryText: 'Try Another Method',
      onSecondary: () => denyAndExit(
        'Session Error', // message for SDK users
        ErrorCodes.ALTERNATIVE_CHOSEN, // errorCode
        false,        // false => don't exit once consent is rejected
        ErrorTypes.INSTITUTION_ERROR // errorType
      ),
      onPrimary: () => retryLogin(),
      btnStyle:'space-x-2'
    }
    _raiseErrorEventForAA("Session timeout", "AA_ERROR", ErrorTypes.INSTITUTION_ERROR, {
      aaHandle: aaHandle.value,
      retriable: true,
      status : lastKnownStatus.value
    });
    // invoke troubleshoot helper and ask user to restart the journey
    invokeTroubleshoot(ctx, true);
  }

  async function retryLogin() {
    // reset complete store??
    //store.otpReference = undefined;
    //store.aaAuth = false;
    isProcessing.value = true;
    resetStore.storeV3();
    await init(store.requestId);
    isProcessing.value = false;
  }

  function handleBadRequest(errorCode = ErrorCodes.BAD_REQUEST, errorType = ErrorTypes.API_ERROR, errorMessage = 'Bad request', aaError = true) {
    //handle common bad request and generate terminal error
    const ctx = {
      error: true,
      title: "Sorry, could not fetch details",
      useDefaultDesc: true,
      primaryText: 'Try other method',
      onPrimary: () => denyAndExit(
        errorMessage, // message for SDK users
        errorCode, // errorCode
        false,        // false => don't exit once consent is rejected
        errorType // errorType
      )
    }
    if (aaError) {
      //some issue with the data sent to AA, hence exit the journey
      _raiseErrorEventForAA("Some issue in the request that is sent to AA", ConductorEvent.AA_ERROR, ErrorTypes.INSTITUTION_ERROR, {
        aaHandle: aaHandle.value,
        retriable: false,
        status : lastKnownStatus.value
      });
    }
    // invoke troubleshoot helper
    invokeTroubleshoot(ctx);
  }

  async function resendMobileAuthOTP(mobileNum: string) {
    //reset store to get updated valuee
    await _initForAlternateMobile(mobileNum);
  }

  function handleAPIError(title: string, desc: string, errorMessage: string, errorCode = ErrorCodes.INVALID_CONFIG, errorType = ErrorTypes.API_ERROR) {
    const ctx = {
      error: true,
      title: title,
      description: desc,
      useDefaultDesc: false,
      primaryText: 'Got it, try other method',
      onPrimary: () => denyAndExit(
        errorMessage,
        errorCode,
        false,
        errorType
      )
    }
    // invoke troubleshoot helper
    invokeTroubleshoot(ctx);
  }

  function fireJourneyEvents(eventType: any, request: any = {}, errorObject = {} as any, extras = {}, labels = {}) {
    let error = {};
    //add specific request details in extraparams
    const extraParams = {
      aaHandle: aaHandle.value,
      requestId: store.requestId,
    }
    if (Object.entries(errorObject).length > 0) {
      error = {
        errorCode: errorObject.errorCode,
        errorType: errorObject.errorType,
        errorMessage: errorObject.errorMessage
      }
    }
    const finalPayload = { ...extraParams, ...error, ...extras };
    events.fire(eventType, eventPayload(store.requestId, finalPayload), labels);
  }
 
  function getAABasedOnFip(selectedFips: any){
   
    let preferredAa = [] as any;
    if (knownTenantFipAaPrefs.has('navi')) {
      const tenantPrefs = knownTenantFipAaPrefs.get('navi')!;
      preferredAa = getPrefAAList(selectedFips, tenantPrefs);
    }
    return preferredAa.length>0? preferredAa[0]: ['nadl'];
  }

  function getPrefAAList(filtered: any[], fipMappings: Map<string, string>) {
    const prefList = [];
    for (const value of filtered) {
      if (fipMappings.has(value)) {
        const aaList = fipMappings.get(value) || [];
        if (aaList.length > 0) {
          prefList.push(aaList);
        }
      }
    }
    return prefList.length>0 ? prefList: ['finvu'];
  }
  // some failures which can't be handled at our end 

  function handleFailures(errorCode = ErrorCodes.FAILURE, errorType = ErrorTypes.API_ERROR, errorMessage = 'Failure', aaError = true){
    //handle common bad request and generate terminal error
    const ctx = {
      error: true,
      title: "Sorry, could not fetch details",
      useDefaultDesc: true,
      primaryText: 'Try other method',
      onPrimary: () => denyAndExit(
        errorMessage, // message for SDK users
        errorCode, // errorCode
        false,        // false => don't exit once consent is rejected
        errorType // errorType
      )
    }
    if (aaError) {
      //some issue with the data sent to AA, hence exit the journey
      _raiseErrorEventForAA("Some issue in the request that is sent to AA", ConductorEvent.AA_ERROR, ErrorTypes.INSTITUTION_ERROR, {
        aaHandle: aaHandle.value,
        retriable: false,
        status : lastKnownStatus.value
      });
    }
    // invoke troubleshoot helper
    invokeTroubleshoot(ctx);
  }

  async function switchAA(){
    if(aaHandle.value ==='nadl'){
      aaHandle.value = 'finvu';
    }
    isProcessing.value = true;
    resetStore.storeV3();
    await _initForAaLogin();
    isProcessing.value = false;
  }

  function collectTopPrefFips(){    
    const countForAutoDisc = store.getFeature(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT) as number;
    const filteredFips = utils.loadFipsBasedOnAA(store.consentDetail.value.fiTypes, Array.from(store.getInstitutions().value.values()), aaHandle.value);
    return filteredFips.slice(0,countForAutoDisc);
  }
  async function autoDiscovery(mobile?:string){
    //use the collected top fips & trigger discovery
    const collectFips  = collectTopPrefFips() as any;
    const autoRetryLimit = store.getFeature(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT) as number;
    const knownFilteredFips = filterKnownFips(Array.from(store.getInstitutions().value.values()),filterParser.value?.getInstitutions() as Set<string>);
    const accFilterList = Array.from(knownFilteredFips).map((data)=>{
      return store.getInstitutions().value.get(data.toString());
    });
    let autoDiscList = [] as any;
    if(mobile){
       // take the fip from account filter also to trigger alt mobile autodiscovery
       if(filterParser.value){
         autoDiscList = Array.from(new Set(accFilterList.concat(collectFips)));
       }
      await discoverAccountsFromInstitutions(autoDiscList,autoRetryLimit, true, mobile);
    }else{
      // remove fip present in the account filter so that we dont trigger re-discovery
     
      autoDiscList = collectFips.filter((item:any) => !Array.from(knownFilteredFips).includes(item.id));
      await discoverAccountsFromInstitutions(collectFips,autoRetryLimit, true, store.customer.mobile!);
    }
    
  }

  function cleanUpFips(auto:boolean, institution: { id: string; }){
    if(auto){
      const fa = institutionWiseAccounts.value.get(institution.id);
      if (fa && fa.getDiscoveredAccStatus() !== DiscoveryStatus.SUCCESS) {
        institutionWiseAccounts.value.delete(institution.id);
      }
    }
  
  }

  //manipulate browser / mobile back buttons
  const onLoad = disableBackOnLoad.bind(null);
  const onPopState = disableOnPopState.bind(null);
  function disableBackOnLoad(){
    window.history.pushState({}, '')
  }
  function disableOnPopState(){
    window.history.pushState({}, '');
    exitWorld.value = true;
  }

  function removeEventListeners(){
    window.removeEventListener('load',onLoad);
    window.removeEventListener('popstate',onPopState);
  }
  
  watch(removeListener,(newValue,oldValue)=>{
    if(newValue!==oldValue){
      removeEventListeners();
    }
  })
  return {
    viewHandler,
    init,
    ensureNext,
    cancelAndExit: denyAndExit,
    exitTheWorld,
    handleSessionError,
    handleBadRequest,
    handleAPIError,


    // AA specific stuff
    resendAaOTP,
    verifyAaOTPAndNext,
    handleConsentAction,
    doAccountDiscovery,
    _initForAlternateMobile,
    _confirmAlternateMobile,
    initiateLinking,
    confirmLinking,
    addMoreBankAccounts,

    //exporting the components 
    components: views,
    //getInstitutionListForDisplay
    discoverAccountsFromInstitutions,
    navigateBack,
    resendMobileAuthOTP,

    //events 
    fireJourneyEvents,
    getAABasedOnFip,
    handleFailures,
    switchAA,
    autoDiscovery,
    collectTopPrefFips,

    // for disabling back functionality
    disableBackOnLoad,
    disableOnPopState,
    onLoad,
    onPopState,
    removeEventListeners
  }

}