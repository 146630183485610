
import { defineComponent, nextTick, ref, watch } from "vue";
import { NumberOnly } from "@/services/onlyNum";
import { useTimer } from "vue-timer-hook";

export default defineComponent({
  name: "OTPField",
  model: {
    prop: "modelValue",
    event: "input",
  },
  props: {
    inputContainerStyle: [Array],
    placeholder: String,
    modelValue: [Number, String],
    inputStyle: [Array],
    disabled: Boolean,
    maxlength: [String, Number],
    otpInfoStyle: [String, Array],
    errorStyle: [Array, String],
    errorText: [String],
    errorTextIcon: Boolean,
    expireTextStyle: [String, Array],
    resendTextStyle: [String, Array],
    expiryTimeInSeconds: [Number],
    doOnResend: [Function],
    startTimer: {
      type: Boolean,
      default: false,
    },
    resendMessagev2: Boolean,
    id: String,
    isProcessing: Boolean,
    resendIcon: Boolean,
    resendIconStyle: [String],
    parentDivStyle: [Array],
    resendCounter: Boolean,
    resendCounterStyle: [Array],
    docUpload: Boolean,
    timerText: String,
    timerTextStyle: [String, Array],
    afterExpireText: [String],
    resendText: String,
    increaseClickCount: [Function],
  },
  // emits: ["uploadManually"],

  /* props: [ 'inputContainerStyle', 'placeholder', 'modelValue', 'inputStyle', 'disabled',
    'maxlength', 'otpInfoStyle', 'errorStyle', 'errorText', 'expireTextStyle', 'resendTextStyle',
    'expiryTimeInSeconds', 'doOnResend','startTimer' ], */

  setup(props, context) {
    const expiryInSeconds = (props.expiryTimeInSeconds as number) || 30;
    const enableResend: boolean =
      !props.resendMessagev2 &&
      props.doOnResend !== undefined &&
      props.doOnResend instanceof Function;
    const enableResendV2: boolean =
      props.resendMessagev2 &&
      props.doOnResend !== undefined &&
      props.doOnResend instanceof Function;
    const timer = ref({} as any);
    const updateValue = (event: any) => {
      context.emit("update:modelValue", event.target.value);
    };
    const resendCounterCount = ref(props.resendCounter ? 0 : undefined);

    watch(
      () => props.startTimer,
      () => {
        if (props.startTimer) {
          startTimerNow();
        }
      },
      { deep: true }
    );
    function startTimerNow() {
      const time = new Date();
      if (expiryInSeconds > 60) {
        // If expiryInSeconds is more than 60, set the timer in minutes
        const expiryTime = expiryInSeconds / 60; // Convert seconds to minutes
        time.setMinutes(time.getMinutes() + expiryTime);
      } else {
        time.setSeconds(time.getSeconds() + expiryInSeconds); // 10 minutes timer
      }
      if (enableResend) {
        timer.value = useTimer(time.getTime(), enableResend);
      } else {
        timer.value = useTimer(time.getTime(), enableResendV2);
      }
    }

    const handleResend = async () => {
      // context.emit('resend');
      if (props.increaseClickCount) props.increaseClickCount();

      if (props.doOnResend) {
        await props.doOnResend();
        resendCounterCount.value != undefined
          ? (resendCounterCount.value = resendCounterCount.value + 1)
          : resendCounterCount.value;
        const time = new Date();

        if (expiryInSeconds > 60) {
          const expiryTime = expiryInSeconds / 60;
          time.setMinutes(time.getMinutes() + expiryTime);
          timer.value?.restart(time.getTime());
        } else {
          time.setSeconds(time.getSeconds() + expiryInSeconds); // 10 minutes timer
          timer.value?.restart(time.getTime());
        }
      }
    };

    const upload = () => {
      context.emit("uploadManually");
    };

    const exit = () => {
      context.emit("tryAnotherTime");
    };

    nextTick(() => {
      if (props.startTimer) {
        startTimerNow();
      }
    });

    return {
      enableResend,
      enableResendV2,
      timer,
      NumberOnly,
      updateValue,
      handleResend,
      startTimerNow,
      resendCounterCount,
      upload,
      exit,
    };
  },
});
