import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f50b4dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onUpdate:modelValue", "autofocus", "placeholder", "onKeydown", "id", "disabled", "autocomplete", "onPaste", "onChange"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClockIcon = _resolveComponent("ClockIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full", [_ctx.otpContainerStyle]])
  }, [
    _createElementVNode("div", {
      ref: "otpCont",
      class: _normalizeClass(["grid", [_ctx.gridCol, _ctx.inpContainerStyle]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.digits, (el, ind) => {
        return _withDirectives((_openBlock(), _createElementBlock("input", {
          type: "text",
          class: _normalizeClass(["w-full h-12 rounded-lg text-center text-[#2F343A] outline", [_ctx.inpStyle, _ctx.inputStyleChange[ind]]]),
          key: el + ind,
          "onUpdate:modelValue": ($event: any) => ((_ctx.digits[ind]) = $event),
          autofocus: ind === 0,
          placeholder: _ctx.placeholder,
          onKeydown: ($event: any) => (_ctx.handleKeyDown($event, ind)),
          id: _ctx.id,
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.NumberOnly && _ctx.NumberOnly(...args))),
          inputmode: "numeric",
          oninput: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
          autocomplete: _ctx.preferredAutoComplete,
          onPaste: ($event: any) => (_ctx.onPasteData($event, ind)),
          onChange: ($event: any) => (_ctx.handleChange($event, ind))
        }, null, 42, _hoisted_1)), [
          [_vModelText, _ctx.digits[ind]]
        ])
      }), 128))
    ], 2),
    (_ctx.errorText && _ctx.errorText.length > 0)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.errorStyle)
        }, _toDisplayString(_ctx.errorText), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["text-sm text-center mt-4", [_ctx.otpTextContainerStyle]])
    }, [
      (!_ctx.hideResendText)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.extraTextStyle)
          }, "Didn’t get the OTP?", 2))
        : _createCommentVNode("", true),
      (!_ctx.isVerified)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["cursor-pointer font-semibold text-[#AFB3C0]", [_ctx.resendTextStyle, _ctx.timer.seconds > 0 ? '' : _ctx.afterExpiryTextStyle]]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleResend && _ctx.handleResend(...args))),
            style: _normalizeStyle({
    'pointer-events': _ctx.timer.seconds > 0 || _ctx.isProcessing ? 'none' : 'auto',
  })
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex justify-between", _ctx.resendTextContainerStyle])
            }, [
              (!_ctx.resendText)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Resend "))
                : _createCommentVNode("", true),
              (_ctx.resendText)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.resendText), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.showIcon && _ctx.showTimer)
                  ? (_openBlock(), _createBlock(_component_ClockIcon, {
                      key: 0,
                      class: _normalizeClass(["w-4 h-4 mr-1", [_ctx.expireTextStyle]])
                    }, null, 8, ["class"]))
                  : _createCommentVNode("", true),
                ((_ctx.timer !== undefined && _ctx.timer?.seconds > 0) || (_ctx.timer !== undefined && _ctx.showTimer && _ctx.timer?.seconds >= 0))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(_ctx.expireTextStyle)
                    }, "(00:" + _toDisplayString(_ctx.timer?.seconds >= 10 ? _ctx.timer?.seconds : "0" + _ctx.timer?.seconds) + ")", 3))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 6))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}