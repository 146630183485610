import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import router from "./router";
import { createPinia } from "pinia";
//import { Vue } from "vue-class-component";

//export const eventBus = new Vue(); // creating an event bus.

createApp(App)
  // use the router
  .use(router)
  // set pinia storage
  .use(createPinia())
  .mount("#app");
