<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="240" height="106"
    viewBox="0 0 240 106" preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,106.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M180 625 l0 -275 100 0 100 0 0 85 c0 47 3 85 8 84 4 0 36 -37 72
-82 l66 -82 107 -3 107 -3 0 276 0 275 -100 0 -99 0 -3 -122 -3 -123 -99 123
-98 122 -79 0 -79 0 0 -275z" />
      <path d="M1267 894 c-4 -4 -7 -128 -7 -276 l0 -268 118 0 c137 0 176 9 234 53
125 96 138 295 27 406 -57 57 -118 78 -249 86 -63 4 -118 3 -123 -1z m241
-189 c17 -14 33 -39 37 -55 14 -63 -37 -140 -91 -140 -24 0 -24 1 -24 110 0
110 0 110 24 110 14 0 38 -11 54 -25z" />
      <path d="M1787 893 c-4 -3 -7 -127 -7 -275 l0 -268 205 0 205 0 0 85 0 85
-100 0 -100 0 0 190 0 190 -98 0 c-54 0 -102 -3 -105 -7z" />
      <path d="M946 705 c-88 -31 -136 -95 -136 -183 0 -59 9 -83 48 -125 33 -34
119 -61 170 -53 60 11 113 37 109 55 -4 18 -4 18 -67 -5 -119 -43 -230 29
-217 142 12 106 129 173 230 130 50 -21 72 -52 72 -101 0 -48 -33 -95 -67 -95
-10 0 -18 1 -18 3 0 1 7 27 15 57 20 76 20 82 -12 79 -16 -1 -39 1 -53 5 -57
16 -120 -43 -120 -111 0 -60 58 -92 107 -58 18 13 22 13 33 0 17 -21 59 -19
96 4 41 25 69 95 59 147 -17 94 -140 148 -249 109z m85 -137 c5 -49 -21 -98
-51 -98 -26 0 -34 32 -16 74 12 28 21 36 40 36 15 0 27 -6 27 -12z" />
      <path d="M178 248 c16 -16 16 -90 0 -106 -9 -9 -8 -12 8 -12 17 0 19 6 15 47
l-3 48 37 -45 c20 -25 39 -46 40 -48 2 -2 5 26 7 62 2 42 -1 66 -7 66 -7 0
-10 -16 -7 -37 l3 -38 -31 38 c-18 21 -41 37 -53 37 -16 0 -18 -3 -9 -12z" />
      <path d="M315 195 l0 -65 46 0 c34 0 49 4 53 16 9 23 1 28 -14 9 -21 -25 -50
-19 -50 11 0 19 4 25 15 20 9 -3 15 0 15 9 0 9 -6 12 -15 9 -11 -5 -15 1 -15
20 0 31 33 37 49 9 9 -16 10 -16 11 5 0 20 -5 22 -47 22 l-48 0 0 -65z" />
      <path d="M442 248 c-20 -20 -14 -35 23 -64 19 -14 32 -30 29 -35 -9 -15 -42
-10 -53 9 -9 15 -10 15 -11 -5 0 -19 5 -23 34 -23 64 0 73 50 14 83 -24 14
-28 21 -19 30 9 9 16 9 31 -1 17 -10 20 -10 20 3 0 18 -50 21 -68 3z" />
      <path d="M538 248 c7 -7 12 -30 12 -53 0 -23 -5 -46 -12 -53 -9 -9 1 -12 45
-12 53 0 57 2 57 23 -1 20 -2 20 -11 5 -5 -10 -19 -18 -30 -18 -19 0 -20 5
-17 60 l3 60 -29 0 c-23 0 -27 -3 -18 -12z" />
      <path d="M742 228 c-6 -17 -9 -34 -7 -37 3 -2 5 1 5 8 0 7 5 9 10 6 16 -10 11
-25 -8 -25 -11 0 -24 -11 -30 -25 -9 -20 -9 -25 2 -25 8 0 13 8 12 17 0 12 7
19 22 21 20 3 27 -6 23 -30 0 -5 10 -8 23 -8 l25 0 -25 58 c-33 74 -37 78 -52
40z" />
      <path d="M1175 195 c0 -65 0 -65 28 -65 16 0 38 5 50 11 67 36 39 119 -40 119
l-38 0 0 -65z m77 30 c14 -32 0 -78 -25 -83 -14 -3 -17 5 -17 52 0 43 3 56 15
56 9 0 21 -11 27 -25z" />
      <path d="M1605 195 l0 -65 48 0 c38 0 47 3 47 17 0 13 -3 14 -12 5 -7 -7 -20
-12 -31 -12 -17 0 -18 7 -15 60 3 55 1 60 -17 60 -18 0 -20 -7 -20 -65z" />
      <path d="M1727 254 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
      <path d="M1930 245 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z" />
      <path d="M2167 241 c4 -15 -1 -20 -19 -23 -20 -3 -23 -9 -23 -43 0 -31 4 -41
18 -43 16 -3 17 -1 7 17 -12 23 -5 61 11 61 5 0 9 -18 9 -40 0 -29 4 -40 15
-40 12 0 15 14 15 65 0 57 -2 65 -19 65 -14 0 -18 -5 -14 -19z" />
      <path d="M1075 233 c-15 -26 -7 -103 10 -103 8 0 17 4 20 10 3 6 1 10 -4 10
-6 0 -11 14 -11 30 0 17 5 30 13 30 8 0 9 3 1 8 -6 4 -13 13 -15 20 -3 8 -7 7
-14 -5z" />
      <path d="M1409 234 c-14 -17 -13 -83 1 -98 5 -5 17 -6 27 -2 12 5 14 9 5 15
-7 4 -11 25 -10 49 2 49 -4 59 -23 36z" />
      <path d="M1989 234 c-14 -17 -13 -83 1 -98 5 -5 17 -6 27 -2 12 5 14 9 5 15
-7 4 -11 25 -10 49 2 49 -4 59 -23 36z" />
      <path d="M836 205 c-3 -9 6 -25 22 -40 19 -17 22 -25 11 -25 -8 0 -20 5 -27
12 -9 9 -12 8 -12 -5 0 -19 39 -24 57 -6 8 8 13 8 17 0 11 -17 53 -13 61 6 4
12 -1 24 -14 35 -24 19 -28 33 -6 24 8 -3 15 -1 15 5 0 6 -10 9 -22 7 -31 -4
-37 -44 -9 -53 12 -3 21 -11 21 -16 0 -18 -26 -7 -65 28 -32 28 -35 35 -17 30
12 -3 22 -1 22 4 0 15 -47 10 -54 -6z" />
      <path d="M992 208 c-33 -33 1 -90 41 -70 19 10 20 11 2 9 -12 -1 -21 5 -23 16
-2 12 3 17 18 17 16 0 20 4 16 16 -3 9 -6 18 -6 20 0 9 -37 3 -48 -8z m38 -8
c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z" />
      <path d="M1307 205 c-4 -8 -2 -17 4 -21 6 -4 6 -11 -1 -19 -17 -21 -4 -33 35
-33 33 0 35 2 35 32 0 18 -5 37 -12 44 -17 17 -55 15 -61 -3z m43 -25 c0 -16
-5 -30 -11 -30 -6 0 -9 7 -5 15 3 8 1 15 -4 15 -5 0 -7 7 -4 15 10 25 24 17
24 -15z" />
      <path d="M1465 209 c-4 -6 -4 -13 0 -17 4 -4 10 -1 12 6 6 17 23 15 23 -2 0
-8 -9 -18 -20 -21 -11 -3 -20 -15 -20 -25 0 -16 7 -18 38 -16 36 2 37 3 34 36
-2 19 -6 38 -9 43 -7 11 -51 8 -58 -4z m35 -49 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z" />
      <path d="M1720 175 c0 -36 3 -45 18 -45 14 0 17 7 14 45 -3 30 -9 45 -18 45
-10 0 -14 -13 -14 -45z" />
      <path d="M1775 174 c0 -33 3 -44 15 -44 10 0 15 10 15 35 0 19 5 35 10 35 6 0
10 -16 10 -35 0 -24 5 -35 15 -35 10 0 15 11 15 35 0 19 5 35 10 35 6 0 10
-16 10 -35 0 -27 4 -35 19 -35 12 0 16 5 12 16 -3 9 -6 28 -6 44 0 27 -1 27
-62 27 l-63 0 0 -43z" />
      <path d="M1925 175 c0 -37 3 -45 18 -45 15 0 18 7 15 42 -2 28 -8 44 -18 46
-12 2 -15 -8 -15 -43z" />
      <path d="M2039 199 c-22 -42 33 -98 57 -56 4 6 4 9 -1 5 -9 -9 -35 9 -35 23 0
5 11 9 25 9 23 0 24 2 15 20 -14 26 -46 26 -61 -1z m41 1 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z" />
    </g>
  </svg>
</template>


