<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 850903033">
      <rect
        id="Rectangle 8506"
        x="0.5"
        y="1"
        width="14"
        height="14"
        rx="2.5"
        stroke="currentColor"
      />
      <path
        fill="currentColor"
        id="Vector"
        d="M13 4.84116L5.45714 12L2 8.71887L2.88629 7.8777L5.45714 10.3117L12.1137 4L13 4.84116Z"
      />
    </g>
  </svg>
</template>
