import useBackend from "@/services/api2.service";
import { defineStore } from "pinia";
import { ref, shallowRef, computed, reactive } from "vue";
import { AaMeta, AccountLinkedResProto, ConsentResponse, Customer, UserInfo } from "@/store/v2.store";
import { AccountFilterParser } from "../conductor/aa-filters";
import { AaSdkAdapter } from "@/services/aasdk-adapter.service";
import Fuse from "fuse.js";
import { AaWebSdkController, ConsentAction, LinkedAccount } from "@finarkein/aasdk-core";
import useUtilService from "@/services/util.service";
import { ConductorFeatures } from "@/conductor/journey-constants";
export type Institution = {
  id: string;
  logo?: string;
  version: string;
  name: string;
  fiTypes: Array<string>;
  aa: Array<string>;
};
const { humanizeAccountType, indianizeMobileNumber } = useUtilService();

export class FinancialAccount {
  constructor(
    public maskedAccNumber: string,
    public accRefNumber: string,
    public FIType: string,
    public accType: string,
    public fipId: string,
    public fipName: string,
    public identifierSeq: number = 0,
    public linkRefNumber?: string,
    //added new key TODO: it should be here??
    public id?: string
  ) {}

  public isLinked(): boolean {
    return this.linkRefNumber !== undefined || this.linkRefNumber === "";
  }

  get type() {
    return humanizeAccountType(this.FIType, this.accType);
  }

  public shapedForLinking() {
    return {
      maskedAccNumber: this.maskedAccNumber,
      accRefNumber: this.accRefNumber,
      FIType: this.FIType,
      accType: this.accType,
      id: this.id, //make it according to the aahandle
    };
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type UserLinkedAccount = LinkedAccount & {
  identifierSeq: number;
};

export type DiscoveredAccount = Omit<UserLinkedAccount, "linkRefNumber" | "fipId" | "fipName">;
export enum DiscoveryStatus {
  "undefined",
  "SUCCESS",
  "FAILED",
  "NO_ACCOUNTS",
}
export type InstitutionDiscovery = {
  discovering: boolean; // is discovery underway for the institution at a given moment
  status: DiscoveryStatus;
  lastResult: FinancialAccount[] | undefined;
  auto: boolean;
};

export type InstitutionDiscoveryUpdate = Omit<InstitutionDiscovery, "lastResult">;

export class InstitutionAccounts {
  private discovery: InstitutionDiscovery;
  private _maskToAccRef = reactive(new Map<string, string>());
  private accounts: Map<string, FinancialAccount> = reactive(new Map());
  public selections: FinancialAccount[] = reactive([]);

  constructor(public readonly meta: Institution, public readonly stable: boolean = true, auto = false) {
    this.discovery = reactive({
      discovering: false,
      status: DiscoveryStatus.undefined,
      lastResult: undefined,
      auto: auto,
    });
  }

  private _doLinkedAccount(account: UserLinkedAccount, store: Map<string, FinancialAccount>, autoSelect: boolean) {
    const accRefNumber = account.accRefNumber;
    const mask = account.maskedAccNumber;
    this._maskToAccRef.set(mask, accRefNumber);

    let target = store.get(accRefNumber);
    // check, initialize & update if required
    if (target == null || target == undefined) {
      //target = new FinancialAccount(mask, accRefNumber, account.FIType, account.accType, this.meta.id, this.meta.name,0);
      target = new FinancialAccount(mask, accRefNumber, account.FIType, account.accType, this.meta.id, this.meta.name, 0, undefined, account.id);
      store.set(accRefNumber, target);
    }
    target.identifierSeq = account.identifierSeq; // Record identifie sequence number
    target.id = account.id; // update id if required
    if (account.linkRefNumber) {
      // this account is now linked
      target.linkRefNumber = account.linkRefNumber;
    }

    if (autoSelect) {
      this.selections.push(target);
      this._refreshSelections();
    }
  }

  public addOrUpdateLinkedAccount(account: UserLinkedAccount, autoSelect: boolean) {
    this._doLinkedAccount(account, this.accounts, autoSelect);
  }

  public linkPreviouslyDiscoveredAccounts(
    updates: {
      customerAddress?: string;
      linkRefNumber: string;
      accRefNumber: string;
      status: string | "LINKED";
    }[],
    autoSelect: boolean
  ) {
    // Collect using accRefNumber from the last discovered accounts
    updates.forEach((u) => {
      const found = this.discovery.lastResult?.find((a) => u.accRefNumber === a.accRefNumber);
      if (found) {
        // Set the linkRefNumber
        found.linkRefNumber = u.linkRefNumber;
        this.addOrUpdateLinkedAccount(found as UserLinkedAccount, autoSelect);
        this._refreshSelections();
      } else {
        console.error("An undiscovered account was tried to be marked linked " + u.accRefNumber);
      }
    });
  }

  private _refreshSelections() {
    const prevSelections = new Set(this.selections.map((data) => data.accRefNumber));
    const newSelections = [] as FinancialAccount[];
    this.allAccounts().value.forEach((data) => {
      if (prevSelections.has(data.accRefNumber)) {
        newSelections.push(data);
      }
    });
    this.selections = newSelections;
  }

  public setDiscoveredAccounts(accounts: DiscoveredAccount[], idSeq: number, autoSelect: boolean) {
    this.discovery.lastResult = accounts.reduce((result, current) => {
      result.push(new FinancialAccount(current.maskedAccNumber, current.accRefNumber, current.FIType, current.accType, this.meta.id, this.meta.name, idSeq, undefined, current.id));
      return result;
    }, [] as FinancialAccount[]);

    if (autoSelect) {
      // autoSelect mode on, reselect all linked accounts again - just in case
      for (const a of this.accounts.values()) {
        this.selections.push(a);
      }
      // update the selection list with all newly discovered accounts
      this.discovery.lastResult.forEach((a) => this.selections.push(a));
      // force dedup of selections array
      this._refreshSelections();
    }
  }

  public updateDiscoveryStatus(ds: Partial<InstitutionDiscoveryUpdate>) {
    this.discovery = {
      ...this.discovery,
      ...ds,
    };
  }

  public allAccounts(searchQuery?: string) {
    let result: FinancialAccount[] = [];
    // const linked: FinancialAccount[] = [];
    // const discovered: FinancialAccount[] = [];

    for (const value of this.accounts.values()) {
      result.push(value); // to render linked accounts only
    }

    if (this.discovery?.lastResult) {
      // if discovery result is available
      this.discovery.lastResult.forEach((value) => {
        if (this._maskToAccRef.has(value.maskedAccNumber)) {
          return;
        }
        result.push(value); //uncomment and show discovered acc also
      });
    }

    if (searchQuery) {
      const fuse = new Fuse(result, { keys: ["fipName", "maskedAccNumber", "type"] });
      result = fuse.search(searchQuery).map((m) => m.item);
    }

    return ref(result);
  }

  public loading() {
    return computed(() => this.discovery.discovering);
  }

  public noAccounts() {
    return computed(() => {
      if (this.loading().value) return true;

      // else check for existence of any accounts
      return this.allAccounts().value.length === 0;
    });
  }

  public getDiscoveredAccStatus() {
    return this.discovery.status;
  }

  public isAutoDiscovery() {
    return computed(() => this.discovery.auto);
  }
}

export type ConsentRequestInfo = ConsentResponse;

export const useV3Store = defineStore("storeV3", () => {
  const api = useBackend();

  const exitWorld = ref(false);
  const aaAuth = ref(false);

  // Root level error handling
  const troubleshootError = ref(); // undefined by default

  // Feature specific stuff
  const features = ref(new Map<string, any>());

  // Current used by Accounts page
  const isProcessing = ref(false);

  const requestId = ref("");
  const tenantId = ref("");
  const v3Title = ref("");
  const brand = ref({
    color: "",
    logo: "",
    name: "",
  });
  const availableAAs = ref(new Array<AaMeta>());
  const customer = ref<Customer>({ maskedMobile: "" });
  const rawCustomerMobile = computed(() => customer.value.mobile || customer.value.maskedMobile);
  const customerMobile = computed(() => indianizeMobileNumber(rawCustomerMobile.value));
  const additionalMobiles = ref<string[]>([]);
  const customerMobiles = computed(() => [rawCustomerMobile.value, ...additionalMobiles.value]);
  const currentMobile = ref("");

  //OTP related
  const otpForUser = ref(new Map<string, any>());
  // Journey stuff
  const institutionsLoading = ref(false);
  const institutions = ref(new Map<string, Institution>()); // all institutions
  const selectedInstitutions = ref<Set<string>>(); // default is undefined

  // accounts mangement stuff
  const discoveryQueue = ref<string[]>([]); // institutions to discover accounts from (fipIds)
  const filterParser = ref<AccountFilterParser>();
  const institutionWiseAccounts = ref(new Map<string, InstitutionAccounts>());
  //maintain the count for auto discovery
  const autoDiscoveryCount = ref(0);

  // Transient states
  const lastKnownStatus = ref<string>("await_init");
  const viewHandler = shallowRef();
  const aaSdkAdapter = ref(new AaSdkAdapter());
  const aaHandle = ref<string>("");
  const aaSdk = ref<AaWebSdkController>();
  const otpReference = ref<string>();
  const awaitNext = ref<Promise<void>>();

  // consent related stuff
  const consentHandle = ref<string>();
  const consentDetail = {} as any;
  const consentTemplate = ref<{ id: string; def: any }>();
  const encryptedParams = ref<{
    encryptedFiuId: string;
    encryptedRequest: string;
    requestDate: string;
  }>();
  const consentRequestInfo = ref<ConsentRequestInfo>();
  const financialInstruments = computed<string[]>(() => {
    return consentRequestInfo.value?.fiTypes ? consentRequestInfo.value.fiTypes : consentTemplate.value?.def.fiTypes;
  });
  const consentAction = ref<ConsentAction>();

  // Feature functions
  function getFeature<T>(featureName: string, defaultValue?: T): T {
    if (features.value.has(featureName)) {
      return features.value.get(featureName) as T;
    } else {
      return defaultValue as T;
    }
  }

  function setFeature<T>(featureaName: string, value: T): void {
    // Simply overwrite
    features.value.set(featureaName, value);
  }

  // Utility functions

  function updateFromRequestDetails(reqId: string, data: any) {
    requestId.value = reqId;
    tenantId.value = data.id;
    if (data.templates) {
      v3Title.value = data.templates["v3.title"];
    } else {
      v3Title.value = "Cash Loan";
    }
    const brandInfo = data.brandInfo;
    updateBrand(data.orgName, brandInfo.color, brandInfo.logo);
    setAaMeta(data.aa);
    updateCustomer({
      maskedMobile: data.customer.mobileNumber,
    });
    if (data.webviewConsentTemplate) {
      consentDetail.value = data.webviewConsentTemplate.def;
      updateConsenTemplate(data.webviewConsentTemplate);
    }

    // Configure Conductor Features, set some defaults if the values are missing
    setFeature(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, data?.features ? (data?.features["consent.auto.discovery.top_n"] ? data.features["consent.auto.discovery.top_n"] : 10) : 10);
    setFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, data?.features ? (data?.features["accounts.auto-select"] ? data.features["accounts.auto-select"] : true) : true);
    setFeature(ConductorFeatures.FIP_ALT_MOBILE, data?.features ? (data?.features["fip.alt-mobile.enable"] ? data.features["fip.alt-mobile.enable"] : false) : false);
    setFeature(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT, data?.features ? (data.features["accounts.discovery.auto.implicit-retries"] ? data.features["accounts.discovery.auto.implicit-retries"] : 1) : 1);
    setFeature(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY, data?.featureConfig ? (data.featureConfig["accounts.auto.discovery"] ? data.featureConfig["accounts.auto.discovery"] : true) : true);
  }

  function updateConsenTemplate(template: any) {
    consentTemplate.value = template;
  }

  function updateBrand(name: string, color: string, logo: string) {
    brand.value.name = name;
    brand.value.color = color;
    brand.value.logo = logo;
  }

  function setAaMeta(metas: Array<AaMeta>) {
    availableAAs.value.length = 0; // reset whatever is available
    metas.forEach((a) => availableAAs.value.push(a));
  }

  function updateCustomer(params: Partial<Customer>) {
    // use the spread operator to apply updates
    customer.value = {
      ...customer.value,
      ...params,
    };
  }

  // ==== institutions ==========
  function setSelectedInstitutions(institutions: Set<string>) {
    selectedInstitutions.value = institutions;
  }

  // Actions, called by conductor
  async function loadInstitutions() {
    institutionsLoading.value = true;
    return api
      .getBankList(requestId.value)
      .then((response) => {
        const data = response.data;
        institutions.value = data.content;
      })
      .finally(() => {
        // institutionsLoading.value = false;
      });
  }

  function getInstitutions() {
    return institutions;
  }

  function updateWebViewParams(data: any) {
    encryptedParams.value = data;
  }

  function updateConsentHandle(handle: string) {
    consentHandle.value = handle;
  }

  /**
   * This method requries all institutions to be already populated
   * @param accounts
   * @param identifierSeq
   */
  function updateLinkedAccounts(accounts: LinkedAccount[], identifierSeq: number = 0) {
    accounts.forEach((account) => {
      let institution = institutions.value.get(account.fipId);
      let stable = true;
      if (!institution) {
        // TODO: Probably cause the bank is down at the moment?
        stable = false;
        institution = {
          aa: [],
          fiTypes: [],
          id: account.fipId,
          name: account.fipName,
          version: "1.1.2",
        };
      }

      const ia = _createOrGetIA(account.fipId, institution, stable);
      ia.addOrUpdateLinkedAccount({ ...account, identifierSeq }, getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true));
    });
  }

  function _createOrGetIA(id: string, institutionHint: Institution, stable: boolean = true) {
    // assume stable always
    const map = institutionWiseAccounts.value;
    let ia = map.get(id);
    if (ia == undefined) {
      ia = new InstitutionAccounts(institutionHint, stable);
      map.set(id, ia);
    }
    return ia;
  }

  function linkDiscoveredAccounts(res: AccountLinkedResProto, institution: Institution) {
    // Ensure Institution exists
    const ia = institutionWiseAccounts.value.get(institution.id);
    if (!ia) {
      // something really really wrong happened!
      return;
    }
    ia.linkPreviouslyDiscoveredAccounts(res.AccLinkDetails, getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true));
  }

  function updateDiscoveryStatus(update: Partial<InstitutionDiscoveryUpdate>, institution: Institution) {
    const ia = _createOrGetIA(institution.id, institution);
    ia.updateDiscoveryStatus(update);
  }

  function updateDiscoveredAccounts(accounts: DiscoveredAccount[], institution: Institution, idSeq = 0) {
    const ia = _createOrGetIA(institution.id, institution);
    ia.setDiscoveredAccounts(accounts, idSeq, getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true));
  }

  function updateInstitutionDiscoveryQueue(ids: string[]) {
    discoveryQueue.value = ids;
  }

  function updateUserInformation(data: UserInfo) {
    customer.value.mobile = data.mobileNo;
  }

  function updateInstitutions(data: Institution[]) {
    institutions.value = data.reduce((result, current) => {
      result.set(current.id, current);
      return result;
    }, new Map<string, Institution>());
  }

  function updateConsentRequestInfo(data: ConsentRequestInfo) {
    consentRequestInfo.value = data;
  }

  function $reset() {
    institutionWiseAccounts.value = new Map<string, InstitutionAccounts>();
    aaAuth.value = false;
    otpReference.value = undefined;
  }
  const totalAccountsCount = computed(() => {
    let tempTotal = 0;
    for (const val of institutionWiseAccounts.value.values()) {
      if (val.stable) tempTotal = tempTotal + val.allAccounts().value.length;
    }
    return tempTotal;
  });

  const removeListener = computed(() => {
    console.log(consentAction.value);
    if (!consentAction.value) {
      return 0;
    } else {
      return 1;
    }
  });

  const workingInstitutions = computed(() => {
    const all = institutionWiseAccounts.value.values();
    return Array.from(all).reduce((result, curr) => {
      if (curr.stable && curr.allAccounts().value.length > 0) result.set(curr.meta.id, curr);
      return result;
    }, new Map<string, InstitutionAccounts>());
  });

  const anythingLoading = computed(() => {
    if (isProcessing.value) return true; // something is loading actually
    const working = workingInstitutions.value.values();
    for (const wi of working) {
      if (wi.loading().value) {
        return true;
      }
    }
    return false;
  });

  // ==== Consent Detail ==========
  // const route = useRoute();
  // const ide: any = route.params.identifier;
  // const consentRes = ref({} as any);
  // // console.log(consentRes.value);
  // function consentDetail() {
  //     api.getRequestDetails(ide)
  //     .then((response: any) => {
  //         console.log(response.webviewConsentTemplate.def);
  //         consentRes.value = response.webviewConsentTemplate.def;
  //     })
  // }
  return {
    brand,
    isProcessing,
    exitWorld,
    aaAuth,

    requestId,
    tenantId,
    v3Title,
    customer,
    customerMobile,
    filterParser,
    lastKnownStatus,
    viewHandler,
    aaSdkAdapter,
    aaHandle,
    aaSdk,
    availableAAs,

    encryptedParams,
    consentHandle,
    consentRequestInfo,
    consentDetail,
    financialInstruments,
    consentAction,
    otpReference,
    awaitNext,
    institutionWiseAccounts,
    additionalMobiles,
    totalAccountsCount,
    // some discovery metadata
    discoveryQueue,
    autoDiscoveryCount,
    removeListener,
    consentTemplate,

    updateFromRequestDetails,
    loadInstitutions,
    setSelectedInstitutions,
    getInstitutions,
    updateWebViewParams,
    updateConsentHandle,
    updateLinkedAccounts,
    updateInstitutionDiscoveryQueue,
    updateDiscoveredAccounts,
    linkDiscoveredAccounts,
    updateDiscoveryStatus,
    updateUserInformation,
    updateInstitutions,
    updateConsentRequestInfo,
    updateConsenTemplate,

    troubleshootError,
    $reset,
    otpForUser,
    currentMobile,

    // FEATURES specific
    getFeature,
    setFeature,

    workingInstitutions,
    anythingLoading,
  };
});
