import axios, { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import cryptoSecure from "./cryptoSecure.service";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
  withCredentials: false,
});

const https: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
  withCredentials: false,
});

https.interceptors.request.use(
  async (config) => {
    if (config.data) {
      config.data = await cryptoSecure().runFunctionOnPayload(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default function useBackend() {
  const router = useRouter();

  const getWebviewDetails = async (id: string, inputs = {}) => {
    const backRoute = router.resolve({
      name: "user.thank-you",
      params: { identifier: id },
    });
    const backUrl = new URL(backRoute.href, window.location.origin).href;
    console.debug("[api] resolved redirection callback url to: " + backUrl);

    const data = {
      redirectUrl: backUrl,
      ...inputs, // override with any inputs supplied
    };
    return await http.post(`/r/${id}/webview`, data);
  };

  const getMarkCompleteDetails = async (id: string, inputs = {}) => {
    const data = {
      ...inputs, // override with any inputs supplied
    };
    return await http.post(`/r/${id}/mark-complete`, data);
  };

  const getBankListRequest = async (id: string) => {
    return await http
      .get(`${process.env.VUE_APP_API_BASE}/r/${id}`)
      .then((response: any) => {
        const data = response.data;
        if (!data["anubhavConfig"]) {
          data["anubhavConfig"] = {
            fipSelectEnabled: false,
            fipSelectType: "single", //single/multiple
          };
        }
        return response;
      });
  };

  const getRequestDetails = async (id: string) => {
    return await http
      .get(`${process.env.VUE_APP_API_BASE}/r/${id}`)
      .then((response: any) => {
        const data = response.data;
        if (!data["aa"]) {
          data["aa"] = [
            {
              id: "finvu-id",
              handle: "@finvu",
              logo: "https://finvu.in/cdn/finvu_logo_svg.svg",
            },
          ];
        }
        return response;
      });
  };

  const getRequestFip = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/r/${id}/fip`);
  };

  const getItrRequestDetails = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/i/${id}`);
  };

  const getItrCertDetails = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/i/${id}/cert`);
  };
  const getItrPassword = async (id: string, inputs = {}) => {
    return await https.post(
      `${process.env.VUE_APP_API_BASE}/i/${id}/fetch`,
      inputs
    );
  };

  const getItrStatusDetails = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/i/${id}/status`);
  };

  const getItrOtpSubmit = async (id: string, inputs = {}) => {
    return await https.post(
      `${process.env.VUE_APP_API_BASE}/i/${id}/submit/otp`,
      inputs
    );
  };
  const getItrAadharOtpSubmit = async (id: string, inputs = {}) => {
    return await https.post(
      `${process.env.VUE_APP_API_BASE}/i/${id}/submit/aadhar/otp/login`,
      inputs
    );
  };

  const getOrgDetails = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/r/org-info/${id}`);
  };

  const getLongUrl = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/s/${id}`);
  };

  const getAbConsentInfo = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/ab/${id}`);
  };
  const getAbConsentDetail = async (id: string) => {
    return await http.get(
      `${process.env.VUE_APP_API_BASE}/ab/${id}/consent/details`
    );
  };
  const getAbDataDetail = async (id: string) => {
    return await http.get(
      `${process.env.VUE_APP_API_BASE}/ab/${id}/data/details`
    );
  };

  const completeXABHAflow = async (id: string, input: any) => {
    return await http.post(
      `${process.env.VUE_APP_API_BASE}/x/abha/${id}`,
      input
    );
  };

  return {
    getWebviewDetails,
    getBankListRequest,
    getRequestDetails,
    getRequestFip,
    getMarkCompleteDetails,

    getItrRequestDetails,
    getItrPassword,
    getItrStatusDetails,
    getItrOtpSubmit,
    getItrAadharOtpSubmit,

    getOrgDetails,
    getLongUrl,

    getAbConsentInfo,
    getAbConsentDetail,
    getAbDataDetail,
    completeXABHAflow,
    getItrCertDetails,
  };
}