import * as configCat from "configcat-js";

const sdkKey = process.env.VUE_APP_CONFIGCAT_SDK as any;

const configCatClient = configCat.getClient(
  sdkKey,
  configCat.PollingMode.LazyLoad
);

export default function useFeatures(tenantId:string) {

  const getFeature = async (name: string, defaultIfEmpty: any) => {
    console.log(`Resolving feature [${name}], workspace = ${tenantId}`);
    const user = new configCat.User(tenantId, undefined, undefined, { org: tenantId, workspace: tenantId });
    const value = await configCatClient.getValueAsync(name, defaultIfEmpty, user);
    return value;
  };

  return {
    getFeature,
  };
}