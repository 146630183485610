import { ConsentAction } from "@finarkein/aasdk-core";
import { ref } from "vue";
import {
  AccountLinkingResponseProto,
  FinancialAccount,
  InstitutionAccounts,
  JourneyConductorType,
  JourneyStoreType,
} from "@/conductor/models";
import {
  ConductorEvent,
  ConductorFeatures,
  ErrorMessages,
  InternalConductorEvent,
  LastStatus,
} from "@/conductor/journey-constants";
import useUtilService from "@/services/util.service";
import { storeToRefs } from "pinia";
import emitter from "../event.emitter";
import { GROUPING_MODE } from "@/constants/constants";
type LinkJob = {
  id: string;
  toLink: FinancialAccount[];
  skip: boolean;
  complete: boolean;
  raw: InstitutionAccounts;
  loading: boolean;
  temp?: AccountLinkingResponseProto;
  redo?: Function;
};
export default function useAccountLinking(
  store: JourneyStoreType,
  conductor: JourneyConductorType,
  views: any
) {
  const { validatePayloadForAutoReadOTP } = useUtilService();
  const {
    workingInstitutions,
    lastKnownStatus,
    selectedSet,
    troubleshootError,
    consentAction,
    isProcessing,
    triggerFipLinking,
  } = storeToRefs(store);
  const linkJobs = ref<LinkJob[]>([]);
  const currLinkJob = ref<LinkJob>();
  const currJobIdx = ref(-1);
  const eventEmitter = emitter;
  const bankOtpInp = ref<number>();
  const linkedBankAcc = ref(false);
  const proceeding = ref(false);
  const bankTimerStart = ref(false);
  const bankInpWrong = ref(false);
  const bankInpErrorText = ref("");
  const bankOtpDialog = ref(false);
  const bankInpSuccess = ref(false);
  const consented = ref<string>();
  const finalStep = ref(false);
  const resendAAOtpCount = ref(0);
  const isConsentError = ref(false);
  async function proceedToLinking() {
    // can't go back now 😰 - maybe if no accounts are selected, only then! find code below somewhere
    proceeding.value = true;
    currJobIdx.value = -1;
    console.log("Proceeding with consent state = " + ConsentAction.ACCEPT);

    // Collect all institutions for which user has selected accounts
    // and create linking job
    const linkingJobs = [] as LinkJob[];
    for (const [id, ia] of workingInstitutions.value.entries()) {
      const linkables = ia.selections.filter(
        (a: { isLinked: () => any }) => !a.isLinked()
      );
      if (linkables.length > 0) {
        linkingJobs.push({
          id,
          toLink: linkables,
          skip: false,
          complete: false,
          raw: ia,
          loading: false,
        });
      }
    }
    linkJobs.value = linkingJobs;
    lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNT_LINKING;
    // Initiate the account linking flow
    bankOtpModal(); // trigger the modals and check whether accounts are present or not
    await _moveToNextLinkJob();
  }

  async function _moveToNextLinkJob() {
    currJobIdx.value++;
    eventEmitter.off("OTP_DETECTED");

    if (currJobIdx.value >= linkJobs.value.length) {
      // ensure we still have some selected accounts to grant consent for
      // TODO:
      // Job done, jump to grant consent
      await finalizeConsent().catch((error) => {
        console.log(error);
      });
    } else {
      const current = (currLinkJob.value = linkJobs.value[
        currJobIdx.value
      ] as LinkJob);
      current.loading = true;
      const meta = current.raw.meta;
      const currentLinkingAcc = current.toLink;
      bankTimerStart.value = false;
      current.redo = async (retry = false) => {
        console.log(retry);
        bankOtpInp.value = undefined;
        return await conductor
          .initiateLinking(meta, currentLinkingAcc, retry)
          .then((res: any) => {
            bankInpWrong.value = false;
            bankInpErrorText.value = "";
            current.temp = res?.response;
            if (res) {
              bankTimerStart.value = true;
              autoReadBankOTP("LINK_BANK");
            }
          })
          .catch((error: any) => {
            if (error === ErrorMessages.SESSION_EXPIRED) {
              throw error;
            }
            bankTimerStart.value = false;
          })
          .finally(() => {
            current.loading = false;
          });
      };
      await current.redo(false); // to resend the linking OTP
    }
  }

  function autoReadBankOTP(type: string) {
    switch (type) {
      case "LINK_BANK":
        eventEmitter.on("OTP_DETECTED", async (e: any) => {
          if (validatePayloadForAutoReadOTP(e)) {
            bankOtpInp.value = e.value;
            await bankOtpSubmit();
          }
        });
        break;
    }
  }

  async function bankOtpSubmit() {
    bankInpSuccess.value = false;
    bankInpWrong.value = false;
    bankInpErrorText.value = "";
    const current = currLinkJob.value!; // this must be here!
    current.loading = true;
    await conductor
      .confirmLinking(
        bankOtpInp.value!,
        current.temp!.RefNumber,
        current.raw.meta
      )
      .then(() => {
        current.complete = true;
        bankInpSuccess.value = true;
        //triggerFipLinking.value = false;
        // Reset the bank OTP values
        setTimeout(function () {
          // show the current list of linked accounts
          // bankInpSuccess.value = false;
          // linkedBankAcc.value = true;
          bankOtpInp.value = undefined; // reset the value
          resendAAOtpCount.value = 0;
          otpStep(); // move to next step
        }, 500);
      })
      .catch((e: any) => {
        if (e?.message === ErrorMessages.OTP_VALIDATION_FAILED) {
          bankInpWrong.value = true;
          bankInpErrorText.value = "Please enter correct OTP or retry again";
        } else if (e === ErrorMessages.SESSION_EXPIRED) {
          triggerFipLinking.value = false;
          throw e;
        }
        //current.skip = true; // TODO: mark failing linking as skipped?
        console.error("Error during account link confirmation", e);
      })
      .finally(() => {
        current.loading = false;
        // Reset the bank OTP values
        //bankOtpInp.value = undefined; // again reset the current OTP input
        eventEmitter.off("OTP_DETECTED");
      });
  }

  async function otpStep() {
    bankInpWrong.value = false;
    bankInpSuccess.value = false;
    linkedBankAcc.value = false;
    bankOtpInp.value = undefined;
    resendAAOtpCount.value = 0; // reset the counter
    eventEmitter.off("OTP_DETECTED");
    await _moveToNextLinkJob(); // towards next now
  }

  function bankOtpModal() {
    triggerFipLinking.value = true;
  }

  async function skipCurrentFipVerification() {
    const current = (currLinkJob.value = linkJobs.value[
      currJobIdx.value
    ] as LinkJob);
    current.loading = true;
    const ia = current.raw;
    // remove all current accounts to link for this institution
    current.toLink.forEach((acc) => {
      const idx = ia.selections.indexOf(acc);
      if (idx === -1) {
        // shittt!
        // must not happen
        console.error(
          "Something bad happened, things are missing from this world, like this account = ",
          acc
        );
      } else {
        const newArray = [...ia.selections];
        newArray.splice(idx, 1);
        conductor.fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_SKIPPED);
        ia.selections = newArray;
        bankOtpInp.value = undefined;
        resendAAOtpCount.value = 0;
        bankInpErrorText.value = "";
        bankInpWrong.value = false;
        bankInpSuccess.value = false;
      }
    });

    // Now move to next step
    _moveToNextLinkJob();
  }

  async function finalizeConsent() {
    const autoGrant = store.getFeature(ConductorFeatures.CONSENT_AUTO_GRANT);
    // check if any account is available
    if (getCurrentAccountsSelected().length === 0) {
      lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_SELECTION;
      eventEmitter.emit(InternalConductorEvent.NO_ACCOUNTS_SELECTED_MODAL);
      // raise global error
      /*          if (store.getFeature(ConductorFeatures.NO_ACCOUNTS_SELECTED_MODAL)) {
                const payload = {} as any;
                payload.error = true;
                payload.title = 'Sorry, no accounts selected';
                payload.description = "We need at least one bank accounts to continue. Alternatively, you can try another methods to provide your bank statement";
                payload.useDefaultDesc = false;
                payload.primaryText = "Okay, got it"
                payload.disableOnPrimary = false; // allow the modal to close
                payload.onPrimary = () => {
                    troubleshootError.value = undefined; /// clean up
                    //proceeding.value = false;
                };
                payload.secondaryText = "Try other method"
                payload.onSecondary = () => conductor.triggerExitWorld(true, '', 'ALTERNATIVE_CHOSEN');
                // possibly close the open bank otp modal
                troubleshootError.value = payload;
            } */
      proceeding.value = false;
      triggerFipLinking.value = false;
      return;
    } else if (!autoGrant) {
      lastKnownStatus.value = LastStatus.REQUIRES_CONSENT_ACTION;
      finalStep.value = true;
      //triggerFipLinking.value = false;
      setTimeout(() => {
        triggerFipLinking.value = false;
        conductor.transitionToView(
          views.consentPage,
          store.getFeature(ConductorFeatures.LAYOUT)
        );
      }, 1200);
    } else {
      // 👇 this display success OTP modal! ensure everything is correct before setting true
      finalStep.value = true;
      bankInpSuccess.value = true;
      isProcessing.value = true;
      lastKnownStatus.value = LastStatus.REQUIRES_CONSENT_ACTION;
      await grantConsent();
    }
  }
  function getCurrentAccountsSelected() {
    // check if accounts are selected or not
    // const modeType = store.getFeature(
    //   ConductorFeatures.GROUPING_MODE,
    //   undefined
    // );
    // if (modeType == GROUPING_MODE.GROUPED) {
    //   console.log("from grouped");
    // } else {
    const currentSelectedAccounts = Array.from(
      selectedSet.value.values()
    ).reduce((r: any, c: any) => {
      c.selections.forEach((ca: any) => r.push(ca));
      return r;
    }, [] as FinancialAccount[]) as any;
    return currentSelectedAccounts;
    //  }
  }

  async function grantConsent(
    type?: ConsentAction,
    path?: string,
    accounts?: FinancialAccount[]
  ) {
    isProcessing.value = true;
    let finalConsentAction = ConsentAction.ACCEPT;
    if (type !== undefined) {
      finalConsentAction = type;
    }
    let finalAccounts = [] as FinancialAccount[];
    if (
      store.getFeature(ConductorFeatures.GROUPING_MODE) ===
        GROUPING_MODE.GROUPED &&
      accounts?.length
    ) {
      finalAccounts = accounts;
    } else {
      finalAccounts = getCurrentAccountsSelected();
    }
    return await conductor
      .handleConsentAction(finalConsentAction, finalAccounts)
      .then((status: any) => {
        isProcessing.value = false;
        //triggerFipLinking.value = false;
        if (status) {
          // consent granted successfully
          consented.value = "Consent processed successfully";

          console.log("Consent request processed with " + finalConsentAction);
          if (path != "v2u") {
            eventEmitter.emit(InternalConductorEvent.SHOW_THANK_YOU);
          }

          conductor.fireJourneyEvents(
            "app_success",
            {},
            {},
            { consentStatus: consentAction.value }
          );
          /* if (store.getFeature(ConductorFeatures.SHOW_THANK_YOU)) {
                        conductor.transitionToView(views.thankYou, store.getFeature(ConductorFeatures.LAYOUT));
                    } else {
                        conductor.fireJourneyEvents('app_success', {}, {}, { consentStatus: consentAction.value });
                    } */
        } else {
          // something went wrong probably!
          isConsentError.value = true;
          consented.value = "";
          console.warn("Encountered some issues while granting consent");
          //close the modal of OTP liking if opened
          triggerFipLinking.value = false;
        }
      })
      .catch((e: any) => {
        isProcessing.value = false;
        isConsentError.value = true;
        triggerFipLinking.value = false;
        console.error("Failed to grant the consent", e);
      });
  }

  async function increaseClickCount() {
    resendAAOtpCount.value = resendAAOtpCount.value + 1;
    if (resendAAOtpCount.value > 3) {
      bankInpErrorText.value = "OTP Limit Exceeded. Please try after 1 hour.";
    }
  }

  return {
    linkJobs,
    currLinkJob,
    currJobIdx,
    eventEmitter,
    bankOtpInp,
    linkedBankAcc,
    proceeding,
    bankTimerStart,
    bankInpWrong,
    bankInpErrorText,
    triggerFipLinking,
    bankInpSuccess,
    consented,
    finalStep,

    proceedToLinking,
    _moveToNextLinkJob,
    finalizeConsent,
    bankOtpModal,
    otpStep,
    skipCurrentFipVerification,
    bankOtpSubmit,
    grantConsent,
    increaseClickCount,
    resendAAOtpCount,
    isProcessing,
    isConsentError,
  };
}
