import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/loader.svg'


const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "w-10 h-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-[calc(100%+32px)] -ml-4 sticky bottom-0 left-0 bg-white px-4 bg-white z-20", _ctx.actionContainerStyle])
  }, [
    (_ctx.progressLine)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["w-[calc(100%+32px)] -ml-4 h-[2px] bg-neutral-100 relative before:content-[''] before:absolute before:left-0 before:top-0 before:h-full before:bg-[#14BC51]", _ctx.progressLine])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full grid gap-4 pt-4 pb-8", [_ctx.btnTxtPrimary && _ctx.btnTxtSecondary ? 'grid-cols-2' : 'grid-cols-1']])
    }, [
      (_ctx.btnTxtSecondary)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: _normalizeClass(["w-full h-12 flex items-center justify-center font-NaviBodyDemibold", [_ctx.btnSecondaryStyle, _ctx.btnBorderRadius, _ctx.btnSecondaryBg, _ctx.btnSecondaryText]]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btnSecondary'))),
            disabled: _ctx.btnSecondaryDisabled
          }, _toDisplayString(_ctx.btnTxtSecondary), 11, _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.btnTxtPrimary)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: _normalizeClass(["w-full h-12 flex items-center justify-center font-NaviBodyDemibold text-brandNavi-600 disabled:opacity-60 disabled:cursor-not-allowed", [_ctx.btnPrimaryStyle, _ctx.btnBorderRadius, _ctx.btnPrimaryBg, _ctx.btnPrimaryText]]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('btnPrimary'))),
            disabled: _ctx.btnPrimaryDisabled
          }, [
            (_ctx.btnPrimaryLoading)
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : _createCommentVNode("", true),
            _createTextVNode(_toDisplayString(_ctx.btnTxtPrimary), 1)
          ], 10, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}