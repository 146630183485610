
import { defineComponent/* , ref  */} from 'vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';
//import { useV3Store } from '@/views/navi/store/navi.store';

export default defineComponent({
    name: 'NaviDialogView',
    props: [ 'showModal', 'dialogPanelStyle','brandColor', 'themeTemplates' ],
    emits: [ 'modalClose' ],

    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
    },

    setup(props,emits) {
        //const brandColor = ref(useV3Store().brand.color);
        function handleClose(){
            emits.emit('modalClose');
        }
        return {
            //brandColor
            handleClose
        }
    }
})
