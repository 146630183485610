import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ImgSrc && _ctx.ImgSrc.startsWith('http'))
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.ImgSrc,
        alt: _ctx.AltName
      }, null, 8, _hoisted_1))
    : (!_ctx.ImgSrc)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: require(`@/assets/fip-icons/icon-bank-1.svg`),
          alt: _ctx.AltName
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: require(`@/assets/${_ctx.ImgSrc}`),
          alt: _ctx.AltName
        }, null, 8, _hoisted_3))
}