
import { computed, defineComponent, inject, onUnmounted, ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { SearchIcon, CheckIcon } from "@heroicons/vue/outline";
import moment from 'moment';

// import Image from '@/components/V2/Image2.vue';
import { IconNetworkError } from "@/assets/icons/icon-temp/index";
import LogoFinvu from "@/assets/logo-finvu.vue";
import LogoNADL from "@/assets/logo-nadl.vue";
import Title from "@/components/common/Title.vue";
import EnterOTP from "@/components/common/EnterOTP.vue";
import AddBank from "@/components/common/AddBank.vue";
import NaviDialogView from "@/components/navi/NaviDialogView.vue";
import NaviActionsView from "@/components/navi/NaviActionsView.vue";
import { NumberOnly } from "@/services/onlyNum";
import OTPField from "@/components/common/OTPField.vue";
//import useConductor from './conductor/conductor';
import ConsentDetailView from "@/components/common/ConsentDetailView.vue";
import useConsentDetailService from "@/services/consent-details.services";
//import emitter from './composables/event.emitter';
import useUtilService from "@/services/util.service";
import emitter from "@/composables/event.emitter";
import { JOURNEY_INIT_REQUIREMENTS, OTP_INPUT_TYPE, TERMS_AND_CONDITIONS_TYPE } from "@/constants/constants";
import { ConductorType, JourneyStoreType } from "../../conductor/models";
import InpOTP from "@/components/common/OTPInput.vue";
import { ConductorFeatures } from "@/conductor/journey-constants";
import {ConductorEvent} from "@/conductor/journey-constants";
import { eventPayload } from "@/conductor/conductor";
import { Listbox, ListboxOptions, ListboxOption } from "@headlessui/vue";

export default defineComponent({
    components: {
        CheckIcon,
        LogoFinvu,
        ConsentDetailView,
        NaviDialogView,
        NaviActionsView,
        Title,
        AddBank,
        EnterOTP,
        OTPField,
        LogoNADL,
        InpOTP,
        Listbox,
        ListboxOption,
        ListboxOptions
    },
    props: {
        pageSlotBody: Boolean,
        pageSlotFooter: Boolean,
    },

    setup() {
        // page level state

        const naviStore = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_STORE) as JourneyStoreType;
        const conductor = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_CONDUCTOR) as ConductorType;
        const naviViews = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_VIEWS) as any;

        const customerMobile = ref<string>("");
        const store = storeToRefs(naviStore);
        const sectionItems = ref([] as any);
        const selectedConsents = ref([] as any);
        //component setup

        // const conductor = useConductor();
        // const store = storeToRefs(useV3Store());
        const eventEmitter = emitter;
        const consentList = ref();
        const multiConsentList = ref([] as any);
        const periodHuminizeTxt = ref();
        //const { consentList, periodHuminizeTxt } = useConsentDetailService(useV3Store().consentDetail.value);
        //const { consentList, periodHuminizeTxt } = useConsentDetailService(store?.consentDetail);

        const utilService = useUtilService();
        const { aaHandle, brand, getAALogo, themeTemplates, features, isProcessing, discriminator } = storeToRefs(naviStore);
        const { setEnabledRequests } = naviStore;
        
        //const loginTemplates = ref(new Map<string, any>());
        //const templateValue = ref({} as JourneyTemplate);

        const otpInputType = ref(themeTemplates.value.otpInputType || OTP_INPUT_TYPE.STANDARD);
        const otpSplitCount = ref(themeTemplates.value.otpSplitCount || 6);
        const multiConsentSelection = naviStore.getFeature("ui.aa.consent.multi.allowSelection", false);
        console.log(`multiConsentSelection = ${multiConsentSelection}`);
        const templateValue = computed(() => {
            const loginTemplates = conductor.filterTemplates("login", store.templates.value);
            return utilService.splitTemplateString(loginTemplates, 1, undefined);
        })
        //const {aaHandle} = storeToRefs(naviStore);
        watch(() => store?.consentTemplate.value, (newValue) => {
            if (newValue) {
                consentList.value = useConsentDetailService(newValue.def, true, 'to').consentList;
                periodHuminizeTxt.value = useConsentDetailService(newValue.def).periodHuminizeTxt;
            }
        },
            { deep: true, immediate: true }
        );

        watch(() => selectedConsents.value, (newValue) => {
            const currentSelections = newValue as any[];
            const requests = [] as string[];
            for (let index = 0; index < currentSelections.length; index++) {
                const element = currentSelections[index];
                requests.push(element.mappedReqId);
            }

            setEnabledRequests(requests);
        }, { deep: true, immediate: true });
        
        watch(() => store?.consentTemplates.value, (newValue) => {
          
            //sectionItems.value = [];
            if (newValue) {
                multiConsentList.value = [];
                let availableConsent = [];
                for(let [key,value] of newValue.entries()){
                    availableConsent.push({
                        mappedReqId: key, 
                        ...value.def
                    });
                }
                multiConsentList.value = availableConsent.map((ac)=>{
                    return {
                        mappedReqId: ac.mappedReqId,
                        ...useConsentDetailService(ac, true, 'to')
                    }
                });

                multiConsentList.value.sort((a: { purposeCode: number; },b: { purposeCode: number; })=>a.purposeCode - b.purposeCode)
                if(multiConsentList.value.length) {
                    for(let data of multiConsentList.value) {
                        let section = {
                            mappedReqId: data.mappedReqId,
                            required: true, 
                            title: '', 
                            items: [] as any[], 
                            subTitle: '',
                            footerText: ''
                        };

                        selectedConsents.value.push(section);
                        
                        section.subTitle = data.consentList.filter((cl: { title: string; })=>cl.title==='Data Requests')[0].desc
                        // const currentDate = moment().format('DD MMMM YYYY')
                        if (multiConsentSelection) {
                            section.items.push({ label: 'Data type', value: utilService.convertToSentanceCase(data.consentList.filter((cl: { title: string; })=>cl.title==='Data Requests')[0].desc,'&') });
                        }
                        
                        section.items.push(
                            // { label: 'Data type', value: utilService.convertToSentanceCase(data.consentList.filter((cl: { title: string; })=>cl.title==='Data Requests')[0].desc,'&') },
                            { label: 'Frequency of access', value: data.consentList.filter((cl: { title: string; })=>cl.title==='Frequency')[0].desc === 'Periodic' ? data.consentList.filter((cl: { title: string; })=>cl.title==='Frequency')[0].disc1: 'One time' },
                            { label: 'Data access', value: data.purposeCode ===104 && multiConsentSelection ? '1 day' : data.consentList.filter((cl: { title: string; })=>cl.title==='Period')[0].desc },
                            { label: 'Data life', value: data.consentList.filter((cl: { title: string; })=>cl.title==='Data Life')[0].desc === 'Infinite' ? 'Infinite' :  data.consentList.filter((cl: { title: string; })=>cl.title==='Data Life')[0].desc},
                            // { label: 'Consent Validity', value: data.consentExpiryDuration }
                        );

                        if (multiConsentSelection) {
                            section.items.push({ label: 'Consent Validity', value: data.consentExpiryDuration });
                        }

                        if (data.purposeCode === 104) {
                            section.title = 'Loan monitoring'
                            section.required = false;
                            section.footerText = templateValue?.value?.login_consent_footerText_purpose_104 ? templateValue.value!.login_consent_footerText_purpose_104 : 'The consent will be used only during the tenure of the loan, if the EMI is overdue.'
                        } else if (data.purposeCode === 103) {
                            section.title = 'Loan application processing and underwriting'
                            section.required = true;
                        } else {
                            section.title = data.consentList.filter((cl: { title: string; }) => cl.title === 'Purpose')[0].desc
                        }
                        
                        sectionItems.value.push(section);
                    }
                }
            }
        },
            { deep: true, immediate: true }
        );

        function trimmedDate(txt: any) {
            const parts = txt.split('to') || txt.split('-');
            return parts[1].trim();
        }

        const dataLifeDate = computed(() => {
            const unitMap: any = {
                s: "second", m: "minute", h: "hour", d: "day", w: "week", M: "month", y: "year",
            };

            let unitsAddTxt = "";
            const dt = store?.consentTemplate.value?.def.dataLife;
            const unit = dt.charAt(dt.length - 1);
            const amount = parseInt(dt.replace(unit, ''));

            if (unit === 'i') {
                unitsAddTxt = "Indefinitely";
            } else if (!unitMap[unit]) {
                throw new Error(`Invalid unit: ${unit}`);
            } else if (unit === 's' || unit === 'm' || unit === 'h') {
                unitsAddTxt = moment().add(amount, unitMap[unit]).format('hh:mm:ss A');
            } else {
                unitsAddTxt = moment().add(amount, unitMap[unit]).format('DD MMM YYYY');
            }

            return unitsAddTxt;
        });

        const iframeURL = ref("https://finvu.in/terms");

        const isLoading = ref(true);
        conductor.ensureNext()
            .then(() => {
                customerMobile.value = store.customerMobile.value;
            })
            .finally(() => {
                isLoading.value = false;
            });

        const inpSuccess = ref(false);
        const inpWrong = ref(false);
        const inpErrorText = ref("");
        const aaInpOtp = ref("" as any);
        const termsLink = ref();
        const iframeAvailable = ref(false);
        const tandcDialog = ref(false);
        let tandcData = {};
        const unableOtpDialog = ref(false);
        const consentDialog = ref(false);
        const errorDialog = ref(false);
        const finvuTnCDialog = ref(false);

        const timerStart = ref();
        watch(() => store.otpReference.value, () => {
            timerStart.value = true;
        }
        );
        eventEmitter.on("OTP_DETECTED", async (e: any) => {
            if (utilService.validatePayloadForAutoReadOTP(e)) {
                aaInpOtp.value = e.value;
                conductor.flaggedEvents(ConductorEvent.AA_OTP_DETECTED, eventPayload(naviStore.requestId, discriminator.value, {
                aaHandle: aaHandle.value,
        }), { internal: true })
                await verifyAAOtp();
            }
        });

        function aaOtpModal() { }

        function unableOtpModal() {
            unableOtpDialog.value = true;
        }

        async function doResend() {
            inpSuccess.value = false;
            inpWrong.value = false;
            inpErrorText.value = "";
            //clear otp field on resend otp
            aaInpOtp.value = "";

            return await conductor.resendAaOTP();
        }

        const verifyDisabled = computed(
            () => !aaInpOtp.value || aaInpOtp.value.length < 4
        );

        async function verifyAAOtp() {
            conductor.flaggedEvents(
                ConductorEvent.AA_OTP_ENTERED, 
                eventPayload(naviStore.requestId, discriminator.value, {
                    aaHandle: aaHandle.value,
                }), 
                { internal: true }
            );
            
            inpSuccess.value = false;
            inpWrong.value = false;
            isLoading.value = true;
            isProcessing.value = true;
            await conductor.verifyAaOTPAndNext(aaInpOtp.value)
                .then(() => {
                    isLoading.value = false;
                    //isProcessing.value = false;
                    inpWrong.value = false;
                    inpSuccess.value = true;
                    conductor.transitionToView(naviViews.discovery);
                })
                .catch((error: any) => {
                    if (error === "RETRY") {
                        inpWrong.value = true;
                        inpErrorText.value = "Please enter correct OTP or retry again";
                    } else {
                        inpErrorText.value = "Some error occurred, please try again";
                    }
                    // In case of error, no processing is happening, update state!
                    isProcessing.value = false;
                })
                .finally(() => {
                    isLoading.value = false;
                    // isProcessing.value = false;
                });
        }

        function openConsentModal() {
            consentDialog.value = true;
        }
        function finvuTnCModal() {
            finvuTnCDialog.value = true;
        }

        function closeModal() {
            unableOtpDialog.value = false;
            consentDialog.value = false;
            finvuTnCDialog.value = false;
            tandcDialog.value = false;
        }

        function triggerExitWorld() {
            conductor.exitTheWorld();
        }

        function openTandCModal() {
            const termsValue =
                store.availableAAs.value
                    .filter(({ handle, termsCondition }) => {
                        if (aaHandle.value === handle && termsCondition) {
                            return termsCondition;
                        }
                    })
                    .map((data) => {
                        return data.termsCondition;
                    }) || undefined;

            if (features.value.get(ConductorFeatures.TERMS_AND_CONDITIONS) == TERMS_AND_CONDITIONS_TYPE.IFRAME && termsValue) {
                iframeAvailable.value = true;
                termsLink.value = termsValue;
            } else {
                iframeAvailable.value = false;
                termsLink.value = tandcData;
            }

            if (termsValue.length != 0 || termsLink.value) {
                tandcDialog.value = true;
            }
        }

        function handleBack() {
            conductor.cancelAndExit();
            conductor.removeEventListeners();
            store.exitWorld.value = true;
        }
       
        onMounted(() => {
            // filter out on the basis of the view
            getTAnC();
        });

        function getTAnC() {
            fetch("/data.json")
                .then((response) => response.json())
                .then((data) => {
                    tandcData = data[aaHandle.value];
                })
                .catch((error) => {
                    console.error("Error loading JSON data:", error);
                });
        }

        onUnmounted(() => {
            eventEmitter.off("OTP_DETECTED");
            const ua = navigator.userAgent;
            if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
            ) {
                document.documentElement.style.removeProperty("--vh");
            }
        });

        return {
            moment,
            consentList,
            periodHuminizeTxt,
            dataLifeDate,
            trimmedDate,

            customerMobile,
            isLoading,
            doResend,
            timerStart,
            SearchIcon,
            IconNetworkError,
            NumberOnly,
            aaInpOtp,
            verifyDisabled,
            inpSuccess,
            inpWrong,
            inpErrorText,

            multiConsentSelection,
            unableOtpDialog,
            consentDialog,
            errorDialog,
            finvuTnCDialog,

            verifyAAOtp,
            closeModal,
            aaOtpModal,
            unableOtpModal,
            openConsentModal,
            finvuTnCModal,
            triggerExitWorld,
            iframeURL,

            aaHandle,
            getAALogo,

            tandcDialog,
            termsLink,
            iframeAvailable,
            openTandCModal,
            brand,
            handleBack,
            //loginTemplates,
            templateValue,
            otpInputType,
            otpSplitCount,
            OTP_INPUT_TYPE,
            themeTemplates,

            tandcData,
            isProcessing,
            //sections,
            sectionItems,
            selectedConsents
        };
    },
});
