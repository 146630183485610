
import { defineComponent } from "vue";
import { PlusCircleIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    PlusCircleIcon,
  },
  name: "AddBank",
  emits: ["addBank"],
  props: {
    addBankStyle: {
      type: Array,
    },
    noBankStyle: {
      type: Array,
    },
    showAddBankSection: Boolean,
  },
});
