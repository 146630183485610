import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid", _ctx.consentContainerStyle])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consentList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex flex-row items-center justify-between", _ctx.consentItemStyle]),
        key: item.title
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex items-center justify-center", _ctx.consentLogoItemStyle])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
            class: _normalizeClass(_ctx.consentLogoStyle)
          }, null, 8, ["class"]))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.consentTextItemStyle)
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(["text-sm md:text-base mb-1", _ctx.consentTitleStyle])
          }, _toDisplayString(item.title), 3),
          _createElementVNode("p", {
            class: _normalizeClass(["text-xs md:text-sm", _ctx.consentDescStyle])
          }, [
            _createTextVNode(_toDisplayString(item.desc), 1),
            (item.disc1)
              ? (_openBlock(), _createElementBlock("br", _hoisted_1))
              : _createCommentVNode("", true),
            _createTextVNode(_toDisplayString(item.disc1), 1)
          ], 2)
        ], 2)
      ], 2))
    }), 128))
  ], 2))
}