import useBackend from "@/services/api.service";
import { defineStore } from "pinia";
import { ref } from "vue";
import WebFont from "webfontloader";

export const useV2ItrInfoStore = defineStore("itr-info-store", () => {
  const pageLoading = ref(true);

  const orgName = ref("");
  const requestId = ref("");
  const tenantId = ref("");
  const pan = ref("");
  const redirectUrl = ref("");
  const itrNote = ref("");
  const brand = ref({
    color: "",
    logo: "",
    // name: '',
  });
  const features = ref({} as any);
  const templatesTxt = ref({} as any);
  const extraConfig = ref({} as any);
  const certKey = ref({} as any);
  const certKeyError = ref("");
  const itrInput = ref({} as any);

  function updateItrInfo(reqId: string, data: any) {
    requestId.value = reqId;

    if (data.id) {
      pageLoading.value = false;
    }
    orgName.value = data.orgName;
    tenantId.value = data.id;
    pan.value = data.customer.pan;

    if (data.brandInfo) {
      brand.value.color = data.brandInfo.color;
      brand.value.logo = data.brandInfo.logo;
      // brand.value.name = data.orgName;
    }
    if (data.template) {
      itrNote.value = data.template.itr1;
    }
    if (data.redirectUrl) {
      redirectUrl.value = data.redirectUrl;
    }
    if (data.features) {
      features.value = data.features;
    }
    if (data.templates) {
      templatesTxt.value = data.templates;
    }

    if (data.itrInput) {
      itrInput.value = data.itrInput;
    }
    if (data.features["ui.itr.font.family"]) {
      WebFont.load({
        google: {
          families: [data.features["ui.itr.font.family"]], // Add your desired fonts here
        },
        // You can also add custom font providers or font events here if needed
      });
    }
    if (data.extraConfig) {
      extraConfig.value = data.extraConfig;
    }
  }

  return {
    updateItrInfo,
    pageLoading,

    orgName,
    requestId,
    tenantId,
    pan,
    redirectUrl,
    itrNote,
    brand,
    features,
    templatesTxt,
    extraConfig,
    certKey,
    certKeyError,
    itrInput,
  };
});

export const useV2ItrStatusStore = defineStore("itr-status-store", () => {
  const itrStatus: any = ref("");
  const itrErrorCode: any = ref("");
  const itrMessage: any = ref("");
  const itrTimeStamp: any = ref("");
  const itrSessionId: any = ref("");

  async function itrInfoStatus(reqId: any) {
    return await useBackend()
      .getItrStatusDetails(reqId)
      .then((response) => {
        const data = response.data;
        itrStatus.value = data.status;
        itrErrorCode.value = data.errorCode;
        itrTimeStamp.value = data.updatedOn;
        itrSessionId.value = data.sessionId;
        console.log(response.data.sessionId, itrSessionId.value);
        if (data.message) {
          itrMessage.value = data.message;
        }
      })
      .catch((error) => {
        console.error("Error: " + error);
      });
  }

  return {
    itrStatus,
    itrErrorCode,
    itrMessage,
    itrTimeStamp,
    itrInfoStatus,
    itrSessionId,
    // itrStatusGet,
    // updateItrInfoStatus
  };
});

export const useV2ItrFeatStore = defineStore("itr-feat-store", () => {
  const showDefault = ref(false);

  const headerHide = ref(true);
  const headerLogoHide = ref(true);
  const headerCloseBtnHide = ref(true);

  const ribbonHide = ref(true);
  const ribbonBrandColor = ref("");
  const bodyBgColor = ref("");
  const footerHide = ref(true);
  const footerLogoHide = ref(true);
  const hideForgotPassTxt = ref(true);
  const otpResetDisclaimer = ref(false);
  const otpResetDisclaimerBoxBg = ref("");
  const otpResetDisclaimerColorTxt = ref("");
  const otpResetDisclaimerColorHeading = ref("");
  const otpResetDisclaimerHeadingText = ref("");
  const otpResetDisclaimerHeadingHide = ref(true);
  const otpResetDisclaimerShadow = ref(true);
  const otpResetDisclaimerBoxBgHide = ref(true);

  const newPasswordEnabled = ref(false);
  const newPasswordGenerate = ref(false);

  const inpRounded = ref(false);
  const btnRounded = ref(false);
  const inpBorderColor = ref("");
  const inpBorderColorFocus = ref("");
  const inpBorderColorActive = ref("");
  const inpBorderColorDisabled = ref("");
  const fontBoxTitleColor = ref("");
  const fontFamilyCustom = ref("");

  const otpInpSplit = ref(false);
  const loginOtpInpSplit = ref(false);
  const fpOtpInpSplit = ref(false);
  const otpInpSplitBox = ref(0);
  const loginOtpInpSplitBox = ref(0);
  const fpOtpInpSplitBox = ref(0);
  const otpAutoTrigger = ref(false);
  const loginOtpAutoTrigger = ref(false);
  const fpOtpAutoTrigger = ref(false);

  const bgColorHeader = ref("");
  const bgColorBody = ref("");
  const bgColorFooter = ref("");
  const textColorBody = ref("");

  const warningBoxBg = ref("");
  const warningIconColor = ref("");
  const warningTextColor = ref("");

  const consentGranted = ref(false);
  const aadhaarLoginEnabled = ref(false);

  const redirectTime = ref(0);

  function getFeatures(feat: any) {
    if (feat["ui.itr.header.hide"] === true) {
      headerHide.value = false;
    }
    if (feat["ui.itr.header.logo.hide"] === true) {
      headerLogoHide.value = false;
    }
    if (feat["ui.itr.header.closeBtn.hide"] === true) {
      headerCloseBtnHide.value = false;
    }
    if (feat["ui.itr.body.ribbonBand.hide"] === true) {
      ribbonHide.value = false;
    }
    if (feat["ui.itr.body.ribbonBand.color"]) {
      ribbonBrandColor.value = feat["ui.itr.body.ribbonBand.color"];
    }
    if (feat["ui.itr.footer.hide"] === true) {
      footerHide.value = false;
    }
    if (feat["ui.itr.footer.logo.hide"] === true) {
      footerLogoHide.value = false;
    }
    if (feat["ui.itr.body.rounded.input"] === "rounded") {
      inpRounded.value = true;
    }
    if (feat["ui.itr.body.rounded.button"] === "rounded") {
      btnRounded.value = true;
    }
    if (feat["ui.itr.body.input.color"]) {
      inpBorderColor.value = feat["ui.itr.body.input.color"];
    }
    if (feat["ui.itr.body.input.color.focus"]) {
      inpBorderColorFocus.value = feat["ui.itr.body.input.color.focus"];
    }
    if (feat["ui.itr.body.input.color.active"]) {
      inpBorderColorActive.value = feat["ui.itr.body.input.color.active"];
    }
    if (feat["ui.itr.body.input.color.disabled"]) {
      inpBorderColorDisabled.value = feat["ui.itr.body.input.color.disabled"];
    }
    if (feat["ui.itr.font.color.boxTitle"]) {
      fontBoxTitleColor.value = feat["ui.itr.font.color.boxTitle"];
    }
    if (feat["ui.itr.font.family"]) {
      fontFamilyCustom.value = feat["ui.itr.font.family"];
    }

    if (feat["ui.itr.background.header"]) {
      bgColorHeader.value = feat["ui.itr.background.header"];
    }
    if (feat["ui.itr.background.body"]) {
      bgColorBody.value = feat["ui.itr.background.body"];
    }
    if (feat["ui.itr.background.footer"]) {
      bgColorFooter.value = feat["ui.itr.background.footer"];
    }
    if (feat["ui.itr.font.color"]) {
      textColorBody.value = feat["ui.itr.font.color"];
    }
    if (feat["ui.itr.fp.autoPassword"] === false) {
      newPasswordEnabled.value = true;
    }
    if (feat["ui.itr.fp.autoPassword.generate"] === true) {
      newPasswordGenerate.value = true;
    }
    if (feat["ui.itr.fp.otp.disclaimer"] === true) {
      otpResetDisclaimer.value = true;
    }

    if (feat["ui.itr.otp.boxType"] === "split") {
      otpInpSplit.value = true;
    }
    if (feat["ui.itr.login.otp.boxType"] === "split") {
      loginOtpInpSplit.value = true;
    }
    if (feat["ui.itr.fp.otp.boxType"] === "split") {
      fpOtpInpSplit.value = true;
    }

    if (feat["ui.itr.otp.input.split"]) {
      otpInpSplitBox.value = feat["ui.itr.otp.input.split"];
    }
    if (feat["ui.itr.login.otp.input.split"]) {
      loginOtpInpSplitBox.value = feat["ui.itr.login.otp.input.split"];
    }
    if (feat["ui.itr.fp.otp.input.split"]) {
      fpOtpInpSplitBox.value = feat["ui.itr.fp.otp.input.split"];
    }

    if (feat["ui.itr.otp.autoTrigger"] === true) {
      otpAutoTrigger.value = true;
    }
    if (feat["ui.itr.login.otp.autoTrigger"] === true) {
      loginOtpAutoTrigger.value = true;
    }
    if (feat["ui.itr.fp.otp.autoTrigger"] === true) {
      fpOtpAutoTrigger.value = true;
    }

    if (feat["ui.itr.fp.otp.disclaimer.background"]) {
      otpResetDisclaimerBoxBg.value =
        feat["ui.itr.fp.otp.disclaimer.background"];
    }
    if (feat["ui.itr.fp.otp.disclaimer.color"]) {
      otpResetDisclaimerColorTxt.value = feat["ui.itr.fp.otp.disclaimer.color"];
    }
    if (feat["ui.itr.fp.otp.disclaimer.color.heading"]) {
      otpResetDisclaimerColorHeading.value =
        feat["ui.itr.fp.otp.disclaimer.color.heading"];
    }
    if (feat["ui.itr.fp.otp.disclaimer.shadow"] === false) {
      otpResetDisclaimerShadow.value = false;
    }
    if (feat["ui.itr.fp.otp.disclaimer.heading.hide"] === true) {
      otpResetDisclaimerHeadingHide.value = false;
    }
    if (feat["ui.itr.fp.otp.disclaimer.background.hide"] === true) {
      otpResetDisclaimerBoxBgHide.value = false;
    }
    if (feat["ui.itr.warning.box.background"]) {
      warningBoxBg.value = feat["ui.itr.warning.box.background"];
    }
    if (feat["ui.itr.warning.icon.color"]) {
      warningIconColor.value = feat["ui.itr.warning.icon.color"];
    }
    if (feat["ui.itr.warning.text.color"]) {
      warningTextColor.value = feat["ui.itr.warning.text.color"];
    }

    if (feat["ui.itr.fp.enabled"] === false) {
      hideForgotPassTxt.value = false;
      showDefault.value = true;
    } else {
      showDefault.value = true;
    }

    if (feat["ui.itr.ty.redirectTime"]) {
      redirectTime.value = feat["ui.itr.ty.redirectTime"];
    } else {
      redirectTime.value = 5000;
    }

    if (feat["ui.itr.consent"] === true) {
      consentGranted.value = true;
    } else {
      consentGranted.value = false;
    }

    if (feat["ui.itr.aadhaar.enabled"] === true) {
      aadhaarLoginEnabled.value = true;
    }
  }

  return {
    getFeatures,

    showDefault,
    hideForgotPassTxt,
    newPasswordEnabled,
    newPasswordGenerate,
    otpResetDisclaimer,
    otpResetDisclaimerBoxBg,
    otpResetDisclaimerColorTxt,
    otpResetDisclaimerColorHeading,

    headerHide,
    headerLogoHide,
    headerCloseBtnHide,

    ribbonHide,
    ribbonBrandColor,
    bodyBgColor,
    footerHide,
    footerLogoHide,

    inpRounded,
    btnRounded,
    inpBorderColor,
    inpBorderColorFocus,
    inpBorderColorActive,
    inpBorderColorDisabled,
    otpResetDisclaimerShadow,
    otpResetDisclaimerHeadingHide,
    otpResetDisclaimerHeadingText,
    otpResetDisclaimerBoxBgHide,

    otpInpSplit,
    loginOtpInpSplit,
    fpOtpInpSplit,
    otpInpSplitBox,
    loginOtpInpSplitBox,
    fpOtpInpSplitBox,
    otpAutoTrigger,
    loginOtpAutoTrigger,
    fpOtpAutoTrigger,

    warningBoxBg,
    warningIconColor,
    warningTextColor,

    fontBoxTitleColor,
    fontFamilyCustom,

    bgColorHeader,
    bgColorBody,
    bgColorFooter,
    textColorBody,

    redirectTime,
    consentGranted,
    aadhaarLoginEnabled
  };
});
