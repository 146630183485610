import { ref, computed, onMounted } from 'vue';
import { IconPeriod, IconDataLife, IconFrequency, IconPurpose, IconDataRequests } from '@/assets/icons/icon-temp/index';
import moment from 'moment';

// const consentLastDate = process.env.VUE_APP_API_EVENTS_CONSENT_NOTE_LAST_DATE;
const consentNextDate = process.env.VUE_APP_API_EVENTS_CONSENT_NOTE_NEXT_DATE;
const unitMap: any = {
  s: "second", m: "minute", h: "hour", d: "day", w: "week", M: "month", y: "year",
};

//commonplace for the utility or repetative methods
export default function useConsentDetailService(storevalues: any, day = false, betweenTxt: any = "-", providedDateFormat = 'DD MMM YYYY') {
  const dateFormat = providedDateFormat

  const dataRequests = storevalues.consentTypes.join(', ').toLowerCase().replace(/\b\w/g, function (txt: any) { return txt.toUpperCase() });
  /* const fiTypeRequests = storevalues.fiTypes.join(', ').toLowerCase().replace(/\b\w/g, function (txt: any) { return txt.toUpperCase() }); */
  const fiTypeRequests = storevalues.fiTypes.map((item: string) => item.replace(/_/g, ' '))
  .join(', ')
  .toLowerCase()
  .replace(/\b\w/g, function (txt: any) { 
    return txt.toUpperCase(); 
  });


  const fetchTypeTxt = ref('');
  const dataFrequency = computed(() => {
    if (storevalues.fetchType === "ONETIME") {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      fetchTypeTxt.value = "One Time";
    } else if (storevalues.fetchType === "PERIODIC") {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      fetchTypeTxt.value = "Periodic";
    }
    return fetchTypeTxt.value
  })

  // Purpose Units
  const txtPurpose = ref('');
  const purposeDescriptions: any = {
    "101": "Wealth management service",
    "102": "Customer spending patterns, budget or other reportings",
    "103": "Aggregated statement",
    "104": "Explicit consent for monitoring of the accounts",
    "105": "Explicit one-time consent for the accounts"
  };
  
  function dataPurpose(value: any) {
    return txtPurpose.value = purposeDescriptions[value] || '';
  }

  function supportUnits(values: any): any {
    const lastChar = values.charAt(values.length - 1);
    if (lastChar === "i") {
      return "Infinite";
    }
    const unit = unitMap[lastChar];
    if (!unit) {
      throw new Error(`Unsupported unit: ${lastChar}`);
    }
    const num = parseInt(values.substring(0, values.length - 1));
    if (isNaN(num)) {
      throw new Error(`Invalid value: ${values}`);
    }
    return `${num} ${unit}(s)`;
  }

  function unitSubtract(values: string, format: string): any {
    const unit = values.charAt(values.length - 1);
    const amount = parseInt(values.slice(1, -1), 10);
    if (!unitMap[unit]) {
      throw new Error(`Invalid unit: ${unit}`);
    }
    const unitsSubtractTxt = moment().subtract(amount, unitMap[unit]).format(format);
    return unitsSubtractTxt;
  }

  function unitAdd(values: string, format: string): any { 
    const unit = values.charAt(values.length - 1);
    const amount = parseInt(values.slice(0, -1), 10);
    if (!unitMap[unit]) {
      throw new Error(`Invalid unit: ${unit}`);
    }
    const unitsSubtractTxt = moment().add(amount, unitMap[unit]).format(format);
    return unitsSubtractTxt;
  }

  function humanDuration(startValue: any, endValue: any, day = false) {
    const seconds = endValue.charAt(endValue.length - 1) === "s";
    const minutes = endValue.charAt(endValue.length - 1) === "m";
    const hours = endValue.charAt(endValue.length - 1) === "h";
    const days = endValue.charAt(endValue.length - 1) === "d";
    const weeks = endValue.charAt(endValue.length - 1) === "w";
    const months = endValue.charAt(endValue.length - 1) === "M";
    const years = endValue.charAt(endValue.length - 1) === "y";

    if (seconds || minutes || hours) {
      if(endValue.charAt(0) === "-") {
        const dateFrom = unitSubtract(endValue, 'hh:mm:ss A');
        const dateTo = unitAdd(startValue, 'hh:mm:ss A');
        return `${dateFrom} ${betweenTxt} ${dateTo}`;
      } else {
        const dateFrom = unitAdd(startValue, 'hh:mm:ss A');
        const dateTo = unitAdd(endValue, 'hh:mm:ss A');
        return `${dateTo} ${betweenTxt} ${dateFrom}`;
      }

    } else if (days) {
      if(endValue.charAt(0) === "-") {
        const dateFrom = unitSubtract(endValue, 'DD MMMM YYYY');
        const dateTo = unitAdd(startValue, 'DD MMMM YYYY');
        return `${dateFrom} ${betweenTxt} ${dateTo}`;
      } else {
        const dateFrom = unitAdd(startValue, 'DD MMMM YYYY');
        const dateTo = unitAdd(endValue, 'DD MMMM YYYY');
        return `${dateFrom} ${betweenTxt} ${dateTo}`;
      }

    } else if (weeks || months || years) {
      if(endValue.charAt(0) === "-") {
        // const dateFrom = unitSubtract(endValue, 'MMMM YYYY');
        // const dateTo = unitAdd(startValue, 'MMMM YYYY');
        const dateFrom = unitSubtract(endValue, day ? dateFormat : 'MMMM YYYY');
        const dateTo = unitAdd(startValue, day ? dateFormat : 'MMMM YYYY');
        return `${dateFrom} ${betweenTxt} ${dateTo}`;
      } else {
        const dateFrom = unitAdd(startValue, day ? dateFormat : 'MMMM YYYY');
        const dateTo = unitAdd(endValue, day ? dateFormat : 'MMMM YYYY');
        return `${dateFrom} ${betweenTxt} ${dateTo}`;
      }
    }
  }

  const humanTimeTxt = ref('');
  function humanTime(values: any) {
    const value = values.replace("-", "");
    if(values.charAt(values.length - 1) === "s") {
      const duration = moment.duration(value.replace("s", ""), 'seconds');
      humanTimeTxt.value = duration.asSeconds() <= 60 ? `${duration.asSeconds()} second(s)` : (duration.seconds() !== 0 ? `${duration.minutes()} minute(s) ${duration.seconds()} second(s)` : `${duration.minutes()} minute(s)`);

    } else if(values.charAt(values.length - 1) === "m") {
      const duration = moment.duration(value.replace("m", ""), 'minutes');
      humanTimeTxt.value = duration.asMinutes() <= 60 ? `${duration.asMinutes()} minute(s)` : (duration.minutes() !== 0 ? `${duration.hours()} hour(s) ${duration.minutes()} minute(s)` : `${duration.hours()} hour(s)`);

    } else if(values.charAt(values.length - 1) === "h") {
      const duration = moment.duration(value.replace("h", ""), 'hours');
      humanTimeTxt.value = duration.asHours() <= 24 ? `${duration.asHours()} hour(s)` : (duration.hours() !== 0 ? `${duration.days()} day(s) ${duration.hours()} hour(s)` : `${duration.days()} day(s)`);

    } else if(values.charAt(values.length - 1) === "d") {
      const duration = moment.duration(value.replace("d", ""), 'days');
      humanTimeTxt.value = duration.asMonths() < 1 ? `${duration.days()} day(s)` : (duration.days() !== 0 ? `${duration.months()} month(s) ${duration.days()} day(s)` : `${duration.months()} month(s)`)

    } else if(values.charAt(values.length - 1) === "M") {
      const duration = moment.duration(value.replace("M", ""), 'months');
      humanTimeTxt.value = duration.asMonths() <= 12 ? `${duration.asMonths()} month(s)` : (duration.months() !== 0 ? `${duration.years()} year(s) ${duration.months()} month(s)` : `${duration.years()} year(s)`);

    } else if(values.charAt(values.length - 1) === "y") {
      const duration = moment.duration(value.replace("y", ""), 'years');
      humanTimeTxt.value = `${duration.years()} year(s)`
    }
    return humanTimeTxt.value
  }

  const periodTxt = ref('' as any);
  const periodTest = computed(() => {
    if (storevalues.consentTemplateDataRange.type === "FINANCIAL_YEAR") {
      // const startYear = parseInt(moment().format('YYYY')) - 1;
      // const endYear = startYear + 1;
      // // Define the start and end dates
      // const dateStart = moment([startYear, 3, 1]);
      // const dateEnd = moment([endYear, 2, 31]); 
      const yearsValue = storevalues.consentTemplateDataRange.year;

      const dateStart = moment();
      const dateEnd = moment().add(yearsValue, 'year').subtract(1, 'day');

      // const dateStart = today.format('YYYY-MM-DD');
      // const dateEnd = nextYear.format('YYYY-MM-DD');

      periodTxt.value = day ? `${dateStart.format(dateFormat)} ${betweenTxt} ${dateEnd.format(dateFormat)}` : `${dateStart.format('MMMM YYYY')} ${betweenTxt} ${dateEnd.format('MMMM YYYY')}`;

    } else if (storevalues.consentTemplateDataRange.type === "FIXED") {
      const dateStart = moment(storevalues.consentTemplateDataRange.from);
      const dateEnd = moment(storevalues.consentTemplateDataRange.to);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      periodTxt.value = day ? `${dateStart.format(dateFormat)} ${betweenTxt} ${dateEnd.format(dateFormat)}` : `${dateStart.format('MMMM YYYY')} ${betweenTxt} ${dateEnd.format('MMMM YYYY')}`;

    } else if (storevalues.consentTemplateDataRange.type === "SAME_AS_CONSENT") {
      const expireDuration = storevalues.consentTemplateDataRange.from;
      const startDuration = storevalues.consentTemplateDataRange.to;
      return humanDuration(startDuration, expireDuration, day);

    } else if (storevalues.consentTemplateDataRange.type === "CONSENT_START_RELATIVE" || storevalues.consentTemplateDataRange.type === "CONSENT_START_RELATIVE_MONTH_INCLUSIVE") {
      const expireDuration = storevalues.consentTemplateDataRange.from;
      const startDuration = storevalues.consentTemplateDataRange.to;
      return humanDuration(startDuration, expireDuration, day);

    } else if (storevalues.consentTemplateDataRange.type === "LAST_N_MONTHS") {
      const dateStart = day ? moment().subtract(0, 'month').format(dateFormat) : moment().subtract(0, 'month').format('MMMM YYYY');
      const dateEnd = day ? moment().subtract(storevalues.consentTemplateDataRange.value, 'month').format(dateFormat) : moment().subtract(storevalues.consentTemplateDataRange.value, 'month').format('MMMM YYYY');
      return `${dateEnd} ${betweenTxt} ${dateStart}`;

    }
    return periodTxt.value
  })

  const periodHuminizeTxt = ref('' as any);
  const periodHuminizeTest = computed(() => {
    if (storevalues.consentTemplateDataRange.type === "FINANCIAL_YEAR") {
      const years = storevalues.consentTemplateDataRange.year;

      periodHuminizeTxt.value = storevalues.consentTemplateDataRange.year === "0" ? `current financial year` : `last ${years} year(s)`;

    } else if (storevalues.consentTemplateDataRange.type === "FIXED") {
      const dateStart = moment(storevalues.consentTemplateDataRange.from);
      const dateEnd = moment(storevalues.consentTemplateDataRange.to).add(1, 'days');
      const duration = moment.duration(dateEnd.diff(dateStart));

      const seconds = duration.seconds();
      const minutes = duration.minutes();
      const hours = duration.hours();
      const days = duration.days();
      const months = duration.months();
      const years = duration.years();

      const timeUnits = ['year(s)', 'month(s)', 'day(s)', 'hour(s)', 'minute(s)', 'second(s)'];
      const timeValues = [years, months, days, hours, minutes, seconds];

      let formattedTime = '';
      for (let i = 0; i < timeUnits.length; i++) {
        if (timeValues[i] > 0) {
          formattedTime += `${timeValues[i]} ${timeUnits[i]} `;
        }
      }
      return periodHuminizeTxt.value = formattedTime.trim();

    } else if (storevalues.consentTemplateDataRange.type === "SAME_AS_CONSENT") {
      const dateFrom = storevalues.consentExpiryDuration;
      const dateTo = storevalues.consentStartOffset;

      if(dateTo.charAt(0) === "0") {
        return periodHuminizeTxt.value = `next ${humanTime(dateFrom)}`;
      }

    } else if (storevalues.consentTemplateDataRange.type === "CONSENT_START_RELATIVE" || storevalues.consentTemplateDataRange.type === "CONSENT_START_RELATIVE_MONTH_INCLUSIVE") {
      const dateFrom = storevalues.consentTemplateDataRange.from;
      const dateTo = storevalues.consentTemplateDataRange.to;

      if(dateFrom.charAt(0) !== "-" && dateFrom.charAt(0) === "0") {
        return periodHuminizeTxt.value = `next ${humanTime(dateTo)}`;
      } else if(dateFrom.charAt(0) !== "-" && dateFrom.charAt(0) !== "0") {
        return periodHuminizeTxt.value = `next ${humanTime(dateFrom)} to ${humanTime(dateTo)}`;
      } else if(dateFrom.charAt(0) === "-" && consentNextDate === "true") {
        return periodHuminizeTxt.value = `last ${humanTime(dateFrom)} to next ${humanTime(dateTo)}`;
      } else {
        return periodHuminizeTxt.value = `last ${humanTime(dateFrom)}`;
      }

    } else if (storevalues.consentTemplateDataRange.type === "LAST_N_MONTHS") {
      const months = storevalues.consentTemplateDataRange.value;
      return periodHuminizeTxt.value = `last ${months} month(s)`;
    }
    return periodHuminizeTxt.value
  })

  const fetchFrequency = storevalues.frequency;
  const fetchFrequencyTxt: any = supportUnits(fetchFrequency);

  const dateOfDataLife = computed(()=>{
    const givenDataLife = storevalues.dataLife;
    const lifeOfData = givenDataLife.charAt(givenDataLife.length - 1); // it can be years/months/weeks/days etc;
    const totalLifeUnits = parseInt(givenDataLife.slice(0, -1));// it would be a number 
    if(lifeOfData!=='' && lifeOfData!=='i' && totalLifeUnits){
      return moment().add(totalLifeUnits, unitMap[lifeOfData]).format('DD MMMM YYYY');
    }else if(lifeOfData==='i'){
      return 'Infinite'
    }else{
      return '';
    }
  });

  const purposeCode = computed(()=>{
    return parseInt(storevalues.purposeCode);
  });

  const consentExpiryDuration = computed(()=>{
    const dateFrom = storevalues.consentStartOffset;
    const dateTo = storevalues.consentExpiryDuration;
    //return humanDuration(dateFrom,dateTo, day);
    if(dateTo.charAt(0) === "0") {
      return humanDuration(dateFrom,dateTo, day);
    }else{
      return humanDuration(dateFrom,dateTo, day);
    }
  });

  const consentList = [
    { title: 'Period', desc: periodTest.value, desc1: periodHuminizeTest.value, icon: IconPeriod },
    { title: 'Data Life', desc: supportUnits(storevalues.dataLife), icon: IconDataLife },
    { title: 'Frequency', desc: dataFrequency.value, disc1: dataFrequency.value === "Periodic" ? `${fetchFrequency.replace(fetchFrequency.substring(fetchFrequency.length - 1), "")} time(s) per ${fetchFrequencyTxt.slice(fetchFrequency.length, -3)}` : "", icon: IconFrequency },
    { title: 'Purpose', desc: dataPurpose(storevalues.purposeCode), icon: IconPurpose },
    { title: 'Data Requests', desc: dataRequests, icon: IconDataRequests },
    { title: 'Fitype Requests', desc: fiTypeRequests },
  ];

  return {
    consentList,
    periodHuminizeTest,
    periodHuminizeTxt,
    dateOfDataLife,
    purposeCode,
    periodTest,
    consentExpiryDuration
  }
}
