
import { defineComponent } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/outline";
import Image from "@/components/V2/Image2.vue";

export default defineComponent({
  name: "BankAccount",
  components: {
    ExclamationCircleIcon,
    Image,
  },
  props: [
    "BankAccContainerStyle",
    "BankAccStyle",
    "BankIconBoxStyle",
    "BankIconStyle",
    "BankLogo",
    "BankName",
    "DiscoveredMobileStyle",
    "DiscoveredMobile",
    "BankNameStyle",
    "BankAccLength",
    "BankAccLengthStyle",
    "BankListStyle",
    "BankCheckBox",
    "BankCheckBoxStyle",
    "BankCheckBoxImage",
  ],
  emits: ["selectAllBank"],
});
