
import { defineComponent } from "vue";

export default defineComponent({
  name: "EnterOTP",
  props: {
    enableAddButton: {
      type: Boolean
    },
    mobileNumber: {
      type: String,
    },
    secondMobileNumber: {
      type: String,
    },
    otpHeading: {},
    otpSubheading: {},
    mobileNumberStyle: {
      type: Array,
    },
    otpHeadingStyle: {
      type: Array,
    },
    otpSubHeadingStyle: {
      type: Array,
    },
    showIcon: Boolean,
    changeButton: {
      type: Boolean,
    },
    changeButtonStyle: {
      type: Array,
    },
    imgSrc: {
      type: String,
    },
    addNumberStyle: {
      type: Array,
    }

  },

  emits: ["addNumber"],
});
