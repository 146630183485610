// TYPE DEFINITIONS
export type AaFilter = {
    fipId: string;
    fiType: string;
    accountNumber: string;
    required: boolean;
    hide: boolean;
};

type AccountPredicate = (data: any) => boolean;

export type ParserOptions = {
    fieldMappings: Record<string, string[]>;
    maskCharacters: string;
    ignoreMaskCase: boolean;
    ignoreFipCase: boolean;
    ignoreFiTypeCase: boolean;
};

// UTILITIES
function propResolver(
    target: any,
    prop: string,
    mappings: Record<string, string[]> = {}
) {
    if (!mappings) {
        // if explicitly set to null/undefined
        mappings = {};
    }

    // Handle if mappings doesn't contain the prop
    if (!(prop in mappings)) {
        return target[prop]; // try resolving from target (if available, will be returned)
    }

    const path: string[] = mappings[prop]; // get array of prop path values
    let current = target; // and loop till we get before the mapped field
    for (let idx = 0; idx < path.length - 1; idx++) {
        current = current[path[idx]];
    }

    return current[path[path.length - 1]];
}

function matchAccount(
    toMatch: string,
    seed: string,
    maskCharacters: string = "x",
    ignoreMaskCase = true
) {
    const sanitizedToMatch = String(toMatch);
    const sanitizedSeed = String(seed);
    const toMatchLen = sanitizedToMatch.length;
    const seedLen = sanitizedSeed.length;

    let chars = maskCharacters.split("");
    if (ignoreMaskCase) {
        // We need to ignore mask casing
        chars = chars.map((c) => c.toLowerCase());
    }
    const knownMasks = new Set(chars);
    const isMask = (input: string) => {
        if (ignoreMaskCase) {
            input = input.toLowerCase();
        }
        return knownMasks.has(input);
    };

    let minLen = Math.min(toMatchLen, seedLen) - 1;
    for (
        let i = seedLen - 1, j = toMatchLen - 1;
        minLen >= 0;
        i--, minLen--, j--
    ) {
        if (isMask(sanitizedToMatch[j])) continue; //
        if (sanitizedSeed[i] !== sanitizedToMatch[j]) return false; // NOT a MATCH
    }

    return true; // It's a MATCH
}

function areEquals(a: string, b: string, ignoreCase = true) {
    if (ignoreCase) {
        a = a.toLowerCase();
        b = b.toLowerCase();
    }

    return a === b;
}

// XXX: exported for testing
export function parseAaFilters(
    filters: Array<Partial<AaFilter>>,
    options: ParserOptions = {
        fieldMappings: {} as Record<string, string[]>,
        maskCharacters: "x",
        ignoreMaskCase: true,
        ignoreFipCase: true,
        ignoreFiTypeCase: true,
    }
): ParsedFilters {
    let requireMinOneAccount = false;
    const hardInclusions: AccountPredicate[] = []; // selected: true, disabled: true
    const softInlcusions: AccountPredicate[] = []; // selected: true, disabled: false

    const hardExclusions: AccountPredicate[] = []; // hidden: true, option to unhide: false
    const softExclusions: AccountPredicate[] = []; // hidden: true, option to unhide: true
    const fipIds = new Set<String>();

    // transient states
    let filteredAccountsCount = 0;
    let requiredAccountsCount = 0;
    let filteredFipCount = 0;

    // scan all filters and fill out known inclusions and exclusions
    filters.forEach((filter) => {
        const keys = Object.keys(filter);
        const keyset = new Set(Object.keys(filter));
        // Validation: `hide` field should be present with another field (except `required`)
        if (keys.length === 2 && keyset.has("hide") && keyset.has("required")) {
            console.error(
                "Invalid account filter supplied, suppressing: ",
                JSON.stringify(filter)
            );
            return; // DESCISION: Error out or continue? Continue for now, supplied filter won't take effect
        } else if (keys.length === 1 && keyset.has("required")) {
            // Atleast one account of any `type` from any `FIP` needs to be present to complete the journey
            // Other option is to CANCEL the journey!
            // ACTION: Show a hint to the user why both GRANT/DENY options are disabled?
            requireMinOneAccount ||= filter.required!;
        }

        const resolvedFilters: AccountPredicate[] = [];
        if (filter.accountNumber) {
            filteredAccountsCount++;
            if (filter.required)
                requiredAccountsCount++;

            // Construct the filter
            const numberFilter = (data: any) => {
                const resolvedAccount = propResolver(
                    data,
                    "accountNumber",
                    options.fieldMappings
                );
                return matchAccount(
                    resolvedAccount,
                    filter.accountNumber!,
                    options.maskCharacters
                );
            };

            resolvedFilters.push(numberFilter);
        }

        if (filter.fipId) {
            filteredFipCount++;
            fipIds.add(filter.fipId);

            // construct the filter
            // fipId needs to be a exact match
            const fipFilter = (data: any) => {
                const resolvedFip = propResolver(data, "fipId", options.fieldMappings);
                return areEquals(resolvedFip, filter.fipId!, options.ignoreFipCase);
            };

            resolvedFilters.push(fipFilter);
        }

        if (filter.fiType) {
            const fiTypeFilter = (data: any) => {
                const resolvedFiType = propResolver(
                    data,
                    "fiType",
                    options?.fieldMappings
                );
                return areEquals(
                    resolvedFiType,
                    filter.fiType!,
                    options.ignoreFiTypeCase
                );
            };

            resolvedFilters.push(fiTypeFilter);
        }

        if (resolvedFilters.length === 0) {
            return; // no filters present, nothing to add
        }

        function processAccountPredicates(
            data: any,
            predicates: AccountPredicate[]
        ): boolean {
            let result = true;
            for (let index = 0; index < predicates.length; index++) {
                const element = predicates[index];
                result &&= element(data);

                if (!result) {
                    break; // short circuit, no need to evaluate further
                }
            }
            return result;
        }

        let listToAdd: AccountPredicate[];
        if (filter.hide) {
            if (filter.required) {
                listToAdd = hardExclusions;
            } else {
                listToAdd = softExclusions;
            }
        } else {
            if (filter.required) {
                listToAdd = hardInclusions;
            } else {
                listToAdd = softInlcusions;
            }
        }

        listToAdd.push((data: any) =>
            processAccountPredicates(data, resolvedFilters)
        );
    });

    return {
        filteredFipCount,
        filteredAccountsCount,
        requiredAccountsCount,
        requireMinOneAccount,
        hardExclusions,
        softExclusions,
        hardInclusions,
        softInlcusions,
        fipIds
    };
}

type ParsedFilters = {
    filteredFipCount: number;
    filteredAccountsCount: number;
    requiredAccountsCount: number;
    requireMinOneAccount: boolean;
    hardExclusions: Array<AccountPredicate>;
    softExclusions: Array<AccountPredicate>;
    hardInclusions: Array<AccountPredicate>;
    softInlcusions: Array<AccountPredicate>;
    fipIds: Set<String>;
};

export class AccountFilterParser {
    private _result: ParsedFilters;

    constructor(filters: Array<Partial<AaFilter>>, options?: ParserOptions) {
        this._result = parseAaFilters(filters, options);
    }

    getInstitutions(): Set<String> {
        return this._result.fipIds;
    }

    requiredAccountsCount(): number {
        return this._result.requiredAccountsCount;
    }

    soloFip(): boolean {
        return this._result.filteredFipCount == 1;
    }

    soloAccount(): boolean {
        return this._result.filteredAccountsCount == 1;
    }
}