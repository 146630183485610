
import { defineComponent, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrgInfosTemplate } from '@/store';

const msgMappings = new Map<string, string>();
msgMappings.set('404', 'Page not found');
msgMappings.set('403', 'Forbidden');
msgMappings.set('401', 'Unauthorized');
msgMappings.set('500', 'Server encountered an error');

export default defineComponent({
  name: 'ErrorComponent',
  props: {
    code: {
      type: String,
      default: 'x_x'
    },
    message: {
      type: String,
      required: false
    }
  },
  computed: {
    errorMessage() {
      // [1] Check if a custom error message is passed
      //     If yes, then render the error message
      if (this.message) {
        return this.message;
      }
      // [2] Check if error code is known
      else if (msgMappings.has(this.code)) {
        return msgMappings.get(this.code);
      }

      // [3] Unresloved error code & no message, return a default message
      return 'Unknown error';
    }
  },
  setup() {
    const { orgName } = storeToRefs(useOrgInfosTemplate());

    const supportContactDetail = computed(() => {
      if (orgName.value) {
        return orgName.value;
      }

      return 'your service provider/agent';
    })

    return {
      supportContactDetail
    }
  }
});
