import useBackend from "@/services/api.service";
import { defineStore } from "pinia";
import { ref, shallowRef } from "vue";

const api = useBackend();

export const useOrgConsentInfosTemplate = defineStore("orgConsentInfosTemplate", {
    state: () => ({
        tenantId: "",
        brandName: "",
        brandLogo: "",
        brandColor: "",

        statuses: {},
        customer: {} as any,
        dataDownloadBundle: [] as any,

        loading: false,
        redirectUrl: "",
        error: {
            status: false,
            code: "500",
            message: "",
        },
    }),

    actions: {
        async abConsentInfo(requestId: any) {
            this.loading = true;

            return api.getAbConsentInfo(requestId)
                .then((response: any) => {
                    const data = response.data;
                    this.tenantId = data.id; // tenant identifier
                    this.brandName = data.orgName;
                    if (data.brandInfo) {
                        this.brandLogo = data.brandInfo.logo;
                        this.brandColor = data.brandInfo.color;
                    }
                    if (data.redirectUrl && data.redirectUrl !== null) {
                        this.redirectUrl = data.redirectUrl
                    }
                    if (data.customer) {
                        this.customer = data.customer
                    }
                })
                .catch((error: any) => {
                    if (error.response) {
                        // Request made and server responded
                        const errResponse = error.response;
                        this.error = {
                            status: true,
                            code: errResponse.status,
                            message: error.response.data.errorMessage,
                        };
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = {
                            status: true,
                            code: "500",
                            message: "",
                        };
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                        this.error = {
                            status: true,
                            code: "500",
                            message: "",
                        };
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async abConsentDetail(requestId: any) {
            this.loading = true;

            return api.getAbConsentDetail(requestId)
                .then((response: any) => {
                    // const data = response.data;
                    this.statuses = response.data.statuses;
                })
                .catch((error: any) => {
                    if (error.response) {
                        // Request made and server responded
                        const errResponse = error.response;
                        this.error = {
                            status: true,
                            code: errResponse.status,
                            message: error.response.data.errorMessage,
                        };
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = {
                            status: true,
                            code: "500",
                            message: "",
                        };
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                        this.error = {
                            status: true,
                            code: "500",
                            message: "",
                        };
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },
    },
    getters: {},
});

export const useAbdmStore = defineStore("abdm-store", () => {
    const requestId = ref('')
    const tenantId = ref('');
    const redirectUrl = ref('');
    const viewHandler = shallowRef();
    const inpErrMsg = ref('');
    const brand = ref({
        color: '',
        logo: '',
        name: '',
    });
    const statuses = ref({} as any);
    const customer = ref({} as any);
    const abhaSelected = ref([] as any);
    const dataDownloadStatus = ref('');
    const dataDownloadBundle = ref([] as any);

    function updateFromRequestDetails(reqId: string, data: any) {
        requestId.value = reqId;
        tenantId.value = data.id;

        if (data.brandInfo) {
            const brandInfo = data.brandInfo;
            updateBrand(data.orgName, brandInfo.color, brandInfo.logo);
        }
        if (data.redirectUrl) {
            redirectUrl.value = data.redirectUrl;
        }
        if (data.customer) {
            customer.value = data.customer;
        }
    }
    
    function updateBrand(name: string, color: string, logo: string) {
        brand.value.name = name;
        brand.value.color = color;
        brand.value.logo = logo;
    }

    return {
        requestId,
        tenantId,
        brand,
        redirectUrl,
        statuses,
        customer,
        abhaSelected,
        viewHandler,
        inpErrMsg,
        dataDownloadStatus,
        dataDownloadBundle,
        
        updateFromRequestDetails,
        updateBrand,
    }

});