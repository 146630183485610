<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#003A5D" fill-opacity="0.05" />
        <path
            d="M12.6647 20.3245C10.3353 19.4998 8.66667 17.2785 8.66667 14.6665C8.66667 11.3525 11.3527 8.6665 14.6667 8.6665C17.28 8.6665 19.5027 10.3372 20.326 12.6685"
            stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
        <path
            d="M12.6687 17.335C11.858 16.727 11.3333 15.7577 11.3333 14.6663C11.3333 12.8257 12.826 11.333 14.6667 11.333C15.7573 11.333 16.7253 11.8563 17.3333 12.6663"
            stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
        <path
            d="M14.6667 14.6665L15.3333 21.9998L17.3333 19.9998L20 22.6665L22.6667 19.9998L20 17.3332L22 15.3332L14.6667 14.6665Z"
            stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
    </svg>
</template>