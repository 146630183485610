
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ImageView',
    props: {
        ImgSrc: String,
        AltName: String
    }
})
