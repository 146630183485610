<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1.66669V4.33335" stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M16.3334 9H13.6667" stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M9 16.3334V13.6667" stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M1.66675 9H4.33341" stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" />
        <path
            d="M9.00008 16.3334C13.0502 16.3334 16.3334 13.0501 16.3334 9.00002C16.3334 4.94993 13.0502 1.66669 9.00008 1.66669C4.94999 1.66669 1.66675 4.94993 1.66675 9.00002C1.66675 13.0501 4.94999 16.3334 9.00008 16.3334Z"
            stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" />
        <path d="M6.3335 5L9.00016 9H11.6668" stroke="#00C7B2" stroke-width="1.5" stroke-miterlimit="10"
            stroke-linecap="square" />
    </svg>
</template>