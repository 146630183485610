
import PoweredBy from './PoweredBy.vue';

export default {
  name: 'FooterView',
  props: [ 'footerStyle', 'footerHide', 'footerLogo'],
  components: {
    PoweredBy,
  }
}
