
import { defineComponent } from "vue";

export default defineComponent({
  name: "TitleText",
  props: {
    headingStyle: {
      type: [String, Array]
    },
    subHeadingStyle: {
      type: [String, Array]
    },
    heading: {},
    subheading: {},
  },
  emits: [],
});
