import useBackend from "@/services/api.service";
import { defineStore } from "pinia";

export const useOrganizationInfoTemplate = defineStore("organizationInfoTemplate", {
  state: () => ({
    orgBrandName: "",
    orgBrandLogo: "",
    orgBrandColor: "",
    orgBrandMsg: "",
    defaultMsg:"",
    loading: false,
    error: {
      status: false,
      code: "500",
      message: "",
    },
  }),

  actions: {
    async organizationInfo(orgId: any) {
      const { getOrgDetails } = useBackend();
      this.loading = true;
      getOrgDetails(orgId)
        .then((response) => {
          const data = response.data;
          this.orgBrandName = data.brandName;
          if (data.brand) {
            this.orgBrandLogo = data.brand.logo;
            this.orgBrandColor = data.brand.color;
          }
          if (data.orgId === 'sbilife') {
            this.orgBrandMsg = 'Please ask your Life Mitra to complete your proposal journey.';
          }
          if(data.orgId ==='dspam' || data.orgId==='dsp') {
            this.defaultMsg = 'Your account information has been successfully shared to'
            this.orgBrandMsg = ''
          }
        })
        .catch((error: any) => {
          // TEMP CODE // REMOVE XXX
          if (orgId === 'sbilife') {
            this.orgBrandName = "SBI Life";
            this.orgBrandLogo = "https://www.sbilife.co.in/sites/SBILife/images/SBI_Logo.png";
            this.orgBrandColor = "#2A2076";
          }

          if (error.response) {
            // Request made and server responded
            const errResponse = error.response;
            this.error = {
              status: true,
              code: errResponse.status,
              message: error.response.data.errorMessage,
            };
          } else if (error.request) {
            // The request was made but no response was received
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  getters: {},
});