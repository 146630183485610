import { useItrInfoStore, useOrgInfosTemplate, useV2ItrInfoStore } from "@/store";
import { usev2Store } from "@/store/v2.store";
//import emitter from "@/views/navi/composables/event.emitter";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { computed } from "vue";
import emitter from '@/composables/event.emitter';
import { useAAJourneyStore } from "@/store/aa-journey.store";

const eventEmitter  = emitter;
declare global {
  interface Window {
    AnubhavFlutter?: any;
    Android?: any; // for Android support
    webkit?: any; // For iOS support
    anubhavExchange?: Function
  }
}

window.anubhavExchange = (type: string, payload?: string) => {
  console.log("[exchange] type: '" + type + "', payload: " + payload);
  try {
    if (payload && typeof payload === 'string') {
      eventEmitter.emit(type, JSON.parse(payload || ''));
    } else {
      throw 'Payload invalid. please check!!'
    }
  } catch (error) {
    console.log('some error occured on event emiiter', error);
  }
}

type Listener = (type: string, payload: Record<string, any>) => void;

const listeners: Listener[] = [];
let device = 'web';
// Check if android listener is available
if (window.AnubhavFlutter) {
  device = 'flutter';
  listeners.push((t, p) => {
    try {
      const message = JSON.stringify({
        type: t, 
        payload: p
      });
      window.AnubhavFlutter.postMessage(message);
    } catch(e) {
      console.error("[events/error] Flutter events listener is not configured properly", e);
    }
  });
} else if (window.Android) {
  device = 'android';
  listeners.push((t, p) => {
    try {
      window.Android.on(t, JSON.stringify(p));
    } catch(e) {
      console.debug("[events/error] Android events listener is not configured properly", e);
    }
  });
} else if (
  window["webkit"] &&
  window["webkit"].messageHandlers &&
  window["webkit"].messageHandlers.nativeApp
) {
  device = 'ios';
  // Check if iOS listener is available
  listeners.push((t, p) => {
    try {
      window["webkit"].messageHandlers.nativeApp.postMessage({
        type: t,
        payload: p
      });
    } catch(e) {
      console.error("[events/error] iOS events listener is not configured properly", e);
    }
  })
} else if (window["self"] !== window["top"]) { // Check if in an iframe
  listeners.push((t, p) => {
    try {
      const msg = {
        event: t,
        metadata: p
      };
      // Publish
      window.parent.postMessage(msg, "*");
    } catch (e) {
      console.debug("[events/error] iFrame events publisher is not configure properly", e);
    }
  });
  console.debug("[events.config] iFrame events publisher attached")
}

// Attach a console listener to check if events are getting generated properly
if (listeners.length === 0 && process.env.NODE_ENV !== 'production') {
    listeners.push((t, p) => {
        console.log("[events] type: '" + t + "', payload: " + JSON.stringify(p));
    })
    console.debug("[events.config] Using default, console logged attached")
}

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_EVENTS_BASE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
  withCredentials: false,
});

export default function useEventService() {
  const store1 = useOrgInfosTemplate();
  const store2 = usev2Store();
  const commonJourneyStore = useAAJourneyStore();
  // const itrStore = useItrInfoStore();
  const itrStore = useV2ItrInfoStore();
  const tenant = computed(() => {
    return store2.tenantId ? store2.tenantId : commonJourneyStore.tenantId ? commonJourneyStore.tenantId : store1.tenantId ? store1.tenantId : itrStore.tenantId;
  });

  const _hubQueue = [] as any[];

  function fireInternal(type: string, payload: Record<string, any>) {
    fire(type, payload, { internal: true });
  }
  
  function fire(type: string, payload: Record<string, any>, labels?: Record<string, any>) {
    try {
      const internal = labels?.internal ? labels.internal : false;
      if (!internal) {
        listeners.forEach(l => l(type, payload)); 
      }
    } catch(e) {
      console.warn("[events] observed an error while eventing to listeners", e);
    } finally {
      // finally, deliver to events hub
      processHubEvent(type, payload);
    }
  }

  function _prepHubData(tenant: string, type: string, payload: Record<string, any>) {
    const { requestId, timestamp, ...otherPayload } = payload;
    return {
      tenantId: tenant,
      workspaceId: tenant,
      requestId: requestId,
      event: type,
      eventTime: timestamp,
      payload: { ...otherPayload, device }
    };
  }

  async function _deliverToHub(data: any) {
    await http.post('/e/event', data)
      .then((response: AxiosResponse) => {
        const status = response.status;
        if (status === 200) {
          // Success, yay! 
          console.log(`[events] ${data.event} delivered to hub (id: ${response.data.id})`);
        } else {
          console.warn(`[events] ${data.event} delivery to hub failed (status: ${status})`);
        }
      })
      .catch(e => {
        console.warn(`[events] ${data.event} delivery to hub failed (message: ${e?.message ? e.message : 'na'})`);
      })
      .finally(() => {
        // do something here?
      });
  }

  function processHubEvent(type: string, payload: Record<string, any>) {
    // 1. check if tenant is resolved yet, if yes then start clearing the hubQueue
    if (!tenant.value) {
      _hubQueue.push({ type, payload });
      return;
    }

    // Now that tenant value is available, clear any pending items
    if (_hubQueue.length > 0) {
      while (_hubQueue.length !== 0) {
        // loop on pending items to deliver to hub
        const e = _hubQueue.splice(0, 1);
        if (e.length > 0) {
          _deliverToHub(_prepHubData(tenant.value, e[0].type, e[0].payload));
        }
      }
    }
    
    // deliver to hub directly
    _deliverToHub(_prepHubData(tenant.value, type, payload));
  }

  return {
    fire,
    fireInternal
  };
}
