
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { CheckIcon, SearchIcon, ExclamationCircleIcon, XIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { Listbox, ListboxOption } from "@headlessui/vue";

import { IconDuplicate, IconPhone } from "@/assets/icons/icon-temp/index";
import LogoFinvu from "@/assets/logo-finvu.vue";
import LogoNADL from "@/assets/logo-nadl.vue";

import AddBank from "@/components/common/AddBank.vue";
import EnterOTP from "@/components/common/EnterOTP.vue";
import Title from "@/components/common/Title.vue";
import NaviDialogView from "@/components/navi/NaviDialogView.vue";
import NaviTerminalDialog from "@/components/navi/NaviTerminalDialog.vue";
import NaviActionsView from "@/components/navi/NaviActionsView.vue";
import NaviLoader from "@/components/navi/NaviLoader.vue";
import { IconAddBank } from "@/assets/icons/icon-temp/index";
// import Image from '@/components/V2/Image2.vue';
import Image from "@/components/navi/NaviImageView.vue";
import InputField from "@/components/common/InputField.vue";
import OTPField from "@/components/common/OTPField.vue";
import BankAccount from "@/components/common/BankAccount.vue";
import { NumberOnly } from "@/services/onlyNum";
import { storeToRefs } from "pinia";
import useUtilService from "@/services/util.service";
import { JOURNEY_INIT_REQUIREMENTS, JOURNEY_LAYOUTS, OTP_INPUT_TYPE } from "@/constants/constants";
import { JourneyConductorType, FinancialAccount, InstitutionAccounts, JourneyStoreType, JourneyTemplate } from "../../conductor/models";
import { ConductorFeatures, } from "@/conductor/journey-constants";
import InpOTP from '@/components/common/OTPInput.vue';
import useAltMobile from '@/composables/journey/alt-mobile';
import useAccountLinking from '@/composables/journey/account-linking';

export default defineComponent({
    components: {
        LogoFinvu,
        CheckIcon,
        Listbox,
        ListboxOption,
        IconAddBank,
        IconDuplicate,
        IconPhone,
        ExclamationCircleIcon,
        XIcon,
        CheckCircleIcon,
        PlusCircleIcon,

        AddBank,
        EnterOTP,
        Title,
        NaviTerminalDialog,
        NaviDialogView,
        Image,
        OTPField,
        InputField,
        NaviActionsView,
        BankAccount,
        NaviLoader,
        LogoNADL,
        InpOTP
    },

    props: {
        pageSlotBody: Boolean,
        pageSlotFooter: Boolean,
    },

    setup() {
        //institutions list in form of map
        const store = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_STORE) as JourneyStoreType;
        const conductor = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_CONDUCTOR) as JourneyConductorType;
        const naviViews = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_VIEWS) as any;
        const institutions = store.getInstitutions();
        const { isProcessing, customerMobile, institutionWiseAccounts, troubleshootError, aaHandle, currentMobile, autoDiscoveryCount, filterParser/* , lastKnownStatus, consentAction */, brand, getAALogo, bankFound, totalAccounts, selectedAccountsCount, noAccountSelected, anythingLoading, selectedSet, isAutoDiscoveryInProgress, triggerAltMobile, triggerFipLinking, themeTemplates, additionalMobiles, rawCustomerMobile ,customer } = storeToRefs(store);
        const searchText = ref("");
        const discoveryTemplates = ref(new Map<string, any>());
        const modalTemplates = ref(new Map<string, any>());
        const templateValue = ref({} as JourneyTemplate);
        const defaultTheme = computed(() => store.v3Title == 'Cash Loan' ? true : false);
        const allSelected = computed(() => {
            if (totalAccounts.value !== selectedAccountsCount.value) {
                return false;
            }
            return true;
        });
        const fipAltMobileEnable = store.getFeature(ConductorFeatures.FIP_ALT_MOBILE) as boolean;
        const { inpMobileNo, inpMobileOtp, mobileOtpView, isLoading, timerStart, inpWrong, inpErrorText, inpSuccess, tryAlternateMobile, confirmMobileAuthentication, mobileOtpModal, tryAgain, tryDiscovery, triggerAltMobileDiscovery } = useAltMobile(store, conductor);
        const { linkJobs, currLinkJob, currJobIdx, bankOtpInp, linkedBankAcc, proceeding, bankTimerStart, bankInpWrong, bankInpErrorText, bankInpSuccess, consented, finalStep, proceedToLinking, bankOtpModal, otpStep, skipCurrentFipVerification, bankOtpSubmit } = useAccountLinking(store, conductor, naviViews);
        const { indianizeMobileNumber } = useUtilService();

        const newAddClass = ref(false);
        const workingInstitutions = computed(() => {
            const all = institutionWiseAccounts.value.values();
            const accountFiltersFips = Array.from(filterParser.value?.getInstitutions() || new Set());
            return Array.from(all).reduce((result: any, curr: any) => {
                if (curr.isAutoDiscovery().value && !curr.noAccounts().value) {
                    result.set(curr.meta.id, curr);
                }
                else if (!curr.isAutoDiscovery().value && curr.stable && accountFiltersFips.includes(curr.meta.id)) {
                    result.set(curr.meta.id, curr);
                }
                else if (!curr.isAutoDiscovery().value && curr.stable && !accountFiltersFips.includes(curr.meta.id))
                    result.set(curr.meta.id, curr);
                return result;
            }, new Map<string, InstitutionAccounts>());
        }) as any;

        const currentSelectedAccounts = ref<FinancialAccount[]>([]);

        const unableOtpDialog = ref(false);
        const errorDialog = ref(false);

        const aaInpOtp = ref('');
        function unableOtpModal() {
            unableOtpDialog.value = true;
        }

        function faceIssueModal() {
            errorDialog.value = true;
        }

        // ========== COMMON Modal Management

        function closeModal() {
            if (!finalStep.value) {
                triggerFipLinking.value = false;
                unableOtpDialog.value = false;
                triggerAltMobile.value = false;
                errorDialog.value = false;

                //reset input values
                inpMobileNo.value = '',
                    inpMobileOtp.value = '',
                    aaInpOtp.value = ''
                if (autoDiscoveryCount.value > 0) {
                    autoDiscoveryCount.value -= 1;
                    triggerAltMobile.value = false;
                }
            }
        }

        // ===== TRY WITH NEW MOBILE STUFF

        async function newMobileOtpModal() {
            inpSuccess.value = false;
            inpWrong.value = false;
            isLoading.value = true;
            //await confirmMobileAuthentication(conductor);
            await confirmMobileAuthentication(conductor, true,"", JOURNEY_LAYOUTS.V3);
        }
        function addMoreAccounts() {
            conductor.addMoreBankAccounts();
        }

        function selectAllAccounts() {
            const wereAllSelected = allSelected.value;
            for (let val of workingInstitutions.value.values()) {
                if (!wereAllSelected) {
                    let tempArray = [];
                    tempArray = val.allAccounts().value;
                    val.selections = tempArray;
                } else if (wereAllSelected) {
                    val.selections = [];
                }
            }
        }


        onMounted(() => {
            // filter out on the basis of the view
            discoveryTemplates.value = conductor.filterTemplates("accounts", store.templates);
            modalTemplates.value = conductor.filterTemplates("modal", store.templates);
            templateValue.value = useUtilService().splitTemplateString(discoveryTemplates.value, 1, undefined);
            // for all the modals
            const modalValue = useUtilService().splitTemplateString(modalTemplates.value, 2, undefined);
            templateValue.value = { ...templateValue.value, ...modalValue };
        });

        return {
            customerMobile,
            workingInstitutions,
            noAccountSelected,
            selectedSet,
            currentSelectedAccounts,
            //proceed,
            proceedToLinking,
            proceeding,
            selectAllAccounts,
            allSelected,
            // accountChange,
            totalAccounts,
            selectedAccountsCount,

            linkJobs,
            currLinkJob,
            currJobIdx,

            // Search related stuff
            XIcon, // for reseting
            SearchIcon,
            searchText,

            // OTP related
            NumberOnly,
            timerStart,

            inpSuccess,
            inpWrong,
            inpErrorText,
            aaInpOtp,
            bankOtpInp,
            inpMobileNo,
            inpMobileOtp,
            mobileOtpView,

            linkedBankAcc,
            otpStep,
            skipCurrentFipVerification,
            triggerFipLinking,
            unableOtpDialog,
            triggerAltMobile,
            errorDialog,

            bankOtpModal,
            bankOtpSubmit,
            //addMobileModal,
            mobileOtpModal,
            newMobileOtpModal,
            unableOtpModal,
            faceIssueModal,

            closeModal,
            tryDiscovery,
            tryAlternateMobile,
            confirmMobileAuthentication,
            addMoreAccounts,
            tryAgain,

            institutions,

            consented,
            finalStep,
            //conductor.triggerExitWorld,

            ExclamationCircleIcon,
            anythingLoading,
            //noWorkingInstitutionsError,
            isProcessing,
            isLoading,
            //autoReadOTP,

            newAddClass,
            aaHandle,
            getAALogo,
            autoDiscoveryCount,
            currentMobile,
            //autoDiscoveryTrigger,
            //triggerAltMobileAutoDiscovery,
            triggerAltMobileDiscovery,
            additionalMobiles,
            bankFound,
            isAutoDiscoveryInProgress,
            fipAltMobileEnable,
            troubleshootError,
            brand,
            conductor,

            //linking related 
            bankTimerStart,
            bankInpErrorText,
            bankInpWrong,
            bankInpSuccess,
            defaultTheme,
            discoveryTemplates,
            templateValue,
            themeTemplates,
            OTP_INPUT_TYPE,
            modalTemplates,
            rawCustomerMobile,
            customer,
            indianizeMobileNumber
        }
    },
});
