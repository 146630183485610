import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", {
      class: _normalizeClass(["text-lg mb-1", _ctx.headingStyle])
    }, _toDisplayString(_ctx.heading), 3),
    _createElementVNode("p", {
      class: _normalizeClass(["text-sm", _ctx.subHeadingStyle])
    }, _toDisplayString(_ctx.subheading), 3)
  ], 64))
}