
import { ref, reactive, computed, watch, nextTick, defineComponent } from "vue";
import { useTimer } from "vue-timer-hook";
import { NumberOnly } from "@/services/onlyNum";
import { ClockIcon } from '@heroicons/vue/outline';
export default defineComponent({
  name: "OTPInput",
  components: {
    ClockIcon
  },
  props: {
    default: String,
    inpStyle: [String, Array],
    inpContainerStyle: String,
    increaseClickCount: [Function],
    digitCount: {
      type: Number,
      required: true,
    },

    // inputContainerStyle: [String],
    // modelValue: [Number, String],
    // inputStyle: [Array],
    disabled: Boolean,
    // maxlength: [String, Number],
    // otpInfoStyle: [String, Array],
    errorStyle: [Array, String],
    errorText: [String],
    expireTextStyle: [String, Array],
    resendTextStyle: [String, Array],
    expiryTimeInSeconds: [Number],
    doOnResend: [Function],
    startTimer: {
      type: Boolean,
      default: false,
    },
    id: String,
    isProcessing: Boolean,
    isVerified: Boolean,
    applyUpdateStyle: [Boolean],
    hideResendText: Boolean,
    updatedStyle: [String, Array],
    otpContainerStyle: [String, Array],
    disableResendCount: {
      default: Infinity
    },
    extraTextStyle: [String, Array],
    afterExpiryTextStyle: [String, Array],
    resendClickCount: {
      default: 0
    },
    preferredAutoComplete: {
      default: "one-time-code",
      type: [String]
    },
    resendText: [String],
    otpTextContainerStyle: [String, Array],
    placeholder: {
      default: "*",
      type: [String]
    },
    showTimer:Boolean, // even after expiry show the timer value,
    showIcon:Boolean,
    resendTextContainerStyle:[String,Array]
  },
  setup(props, context) {
    const digits = reactive([] as any);
    const inputStyleChange = reactive([] as any);
    //const resendClickCount = ref(0);
    watch(
      () => props.default,
      (newValue) => {
        if (newValue?.length === props.digitCount) {
          for (let i = 0; i < props.digitCount; i++) {
            digits[i] = newValue.charAt(i);
          }
        }
        else if (props.default === undefined || newValue === undefined) {
          // when inputs are undefined instead of empty string
          for (let i = 0; i < props.digitCount; i++) {
            digits[i] = null;
          }
        }
      }
    );
    if (props.default && props.default.length === props.digitCount) {
      for (let i = 0; i < props.digitCount; i++) {
        digits[i] = props.default.charAt(i);
      }
    } else {
      for (let i = 0; i < props.digitCount; i++) {
        digits[i] = null;
      }
    }

    const otpCont = ref();
    const gridCol = computed(() => {
      if (props.digitCount === 4) {
        return "gap-4 grid-cols-4 max-w-[245px] text-2xl";
      } else if (props.digitCount === 6) {
        return "gap-3 grid-cols-6 max-w-[325px] text-2xl";
      } else if (props.digitCount === 8) {
        return "gap-2 grid-cols-8 max-w-[325px] text-xl";
      } else {
        return "gap-3";
      }
    });

    //const emit = defineEmits(['update:otp']);

    const isDigitsFull = function () {
      for (const elem of digits) {
        if (elem == null || elem == undefined) {
          return false;
        }
      }

      return true;
    };

    const handleKeyDown = function (
      event: { key: string; preventDefault: () => void },
      index: number
    ) {
      if (
        event.key !== "Tab" &&
        event.key !== "ArrowRight" &&
        event.key !== "ArrowLeft"
      ) {
        event.preventDefault();
      }
      if (event.key === "Backspace") {
        digits[index] = null;
        inputStyleChange[index] = '';


        if (index != 0) {
          otpCont.value.children[index - 1].focus();
        }
        context.emit("update:otp", digits.join(""));
        return;
      }

      if (new RegExp("^([0-9])$").test(event.key)) {
        digits[index] = event.key;
        inputStyleChange[index] = updatedInputStyle.value;
        if (index != props.digitCount - 1) {
          otpCont.value.children[index + 1].focus();
        }
        if (isDigitsFull()) {
          context.emit("update:otp", digits.join(""));
        }
      }
    };
    const expiryInSeconds = props.expiryTimeInSeconds || 30;
    const enableResend =
      props.doOnResend !== undefined && props.doOnResend instanceof Function;
    const timer = ref({} as any);
    //const inputStyleChange = ref('');
    watch(
      () => props.startTimer,
      () => {
        if (props.startTimer) {
          startTimerNow();
        }
      },
      { deep: true }
    );
    function startTimerNow() {
      const time = new Date();
      time.setSeconds(time.getSeconds() + expiryInSeconds); // 10 minutes timer
      timer.value = useTimer(time.getTime(), enableResend);
    }

    const handleResend = async () => {
      // context.emit('resend');
      //resendClickCount.value += 1;
      if (props.increaseClickCount)
        props.increaseClickCount();

      if (props.doOnResend && props.resendClickCount + 1 <= props.disableResendCount) {
        await props.doOnResend();
        const time = new Date();
        if (timer.value) {
          for (let i = 0; i < props.digitCount; i++) {
            digits[i] = null;
          }
          otpCont.value.children[0].focus();
          time.setSeconds(time.getSeconds() + expiryInSeconds);
          timer.value?.restart(time.getTime());
        }
      }
    };

    const updateValue = (event: any) => {
      if (event.target.value.replace(/\D/g, "").length > 0) {
        context.emit("update:otp", event.target.value.replace(/\D/g, ""));
      }
    };

    nextTick(() => {
      if (props.startTimer) {
        startTimerNow();
      }
    });

    const showCheckbox = () => {
      return props.isVerified;
    };

    const updatedInputStyle = computed(() => {
      if (props.applyUpdateStyle) {
        return props.updatedStyle
      } else {
        return ''
      }
    });

    async function onPasteData(event: any, index: number) {
      const clipboardData = event.clipboardData
        ? event.clipboardData.getData('text/plain')
        : event.target.value;
      const pastedData = clipboardData.slice(0, props.digitCount);
      const parsedPastedText = pastedData.replace(/\D/g, '');

      if (parsedPastedText.length === 0) {

        // Clear all inputs if nothing valid is pasted
        for (let i = 0; i < props.digitCount; i++) {
          digits[i] = null;
        }
        return;
      }

      if (props.digitCount < parsedPastedText?.length) {
        return;
      }

      for (let i = 0; i < parsedPastedText.length; i++) {
        if (index + i < props.digitCount) {
          digits[index + i] = parsedPastedText[i];
        }
      }

      if (digits) {
        context.emit('update:otp', digits.join(''));
      }
    }

    const handleChange = (e: any, index: number) => {
      const val = e.target.value;
      if (!val) {
        e.preventDefault();
        return;
      }
      else if (val.trim().replace(/\D/g, "").length > 1) {
        e.clipboardData = {
          getData: () => val.trim().replace(/\D/g, ""),
        };
        onPasteData(e, index);
      }
      else {
        //clear whatever was entered(apart from digits)
        for (let i = 0; i < props.digitCount; i++) {
          digits[i] = null;
        }
        return;
      }
    };
    return {
      updateValue,
      gridCol,
      handleResend,
      digits,
      NumberOnly,
      timer,
      handleKeyDown,
      otpCont,
      showCheckbox,
      updatedInputStyle,
      inputStyleChange,
      onPasteData,
      handleChange
    };
  },
});
