
import { defineComponent, ref, computed, watch, inject, onMounted } from "vue";
import { SearchIcon, XIcon, ExclamationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/vue/outline";
// import QuestionMarkCircleIcon from "@heroicons/vue/solid/QuestionMarkCircleIcon";

import NaviDialogView from "@/components/navi/NaviDialogView.vue";
import NaviActionsView from "@/components/navi/NaviActionsView.vue";

import InputField from "@/components/common/InputField.vue";
import BankList from "@/components/common/BankList.vue";
import { NumberOnly } from "@/services/onlyNum";
import ConsentDetailView from "@/components/common/ConsentDetailView.vue";
import BankSearch from "@/views/navi/BankSearch.vue";
import Fuse from "fuse.js";
import useConsentDetailService from "@/services/consent-details.services";
import { storeToRefs } from "pinia";
import useUtilService from "@/services/util.service";
import useEventService from "@/services/events.service";
import { JOURNEY_INIT_REQUIREMENTS, JOURNEY_LAYOUTS, COMPONENT_VIEW_TYPE } from "@/constants/constants";
import { Institution, JourneyConductorType, JourneyTemplate, JourneyStoreType } from "@/conductor/models";
import { debounce } from "lodash";
import { eventPayload } from "@/conductor/conductor";
import { ConductorEvent, ConductorFeatures } from "@/conductor/journey-constants";
//import Title from "@/components/common/Title.vue";
import NaviTerminalDialog from "@/components/navi/NaviTerminalDialog.vue";
import Image from '@/components/common/Image.vue';
import NaviLoader from "@/components/navi/NaviLoader.vue";

export default defineComponent({
    components: {
        NaviDialogView,
        NaviActionsView,
        InputField,
        BankList,
        // OTPField,
        ConsentDetailView,
        BankSearch,
        QuestionMarkCircleIcon,
        //Title,
        NaviTerminalDialog,
        Image,
        NaviLoader
    },

    props: {
        pageSlotBody: Boolean,
        pageSlotFooter: Boolean,
    },

    setup() {
        //const store = useV3Store();
        const store = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_STORE) as JourneyStoreType;
        const conductor = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_CONDUCTOR) as JourneyConductorType;
        const naviViews = inject(JOURNEY_INIT_REQUIREMENTS.AVAILABLE_VIEWS) as any;
        //const conductor = useConductor(store, naviViews);
        const { isProcessing, aaHandle, institutionWiseAccounts, requestId, brand, themeTemplates, workingInstitutions, discriminator, listOfFinancialInstruments } = storeToRefs(store);
        //const { consentList, periodHuminizeTxt } = useConsentDetailService(useV3Store().consentDetail.value);
        const { loadFipsBasedOnAA, splitTemplateString, humanizeAccountType } = useUtilService();
        // Search related stuff
        const bankSearchModal = ref(false);
        const searchMode = ref(false);
        const toggleSearchMode = () => { searchMode.value = !searchMode.value; };
        const fipSearchQuery = ref("");
        const events = useEventService();
        const consentList = ref();
        const periodHuminizeTxt = ref();
        const selectFipTemplates = ref(new Map<string, any>());
        const templateValue = ref({} as JourneyTemplate);
        const bankListStyle = ref({}) as any;

        const anotherMethod = ref(false);
        const anotherMethodModal = ref({} as any);
        const existingFips = ref([] as any);
        const defaultTheme = computed(() => store.v3Title == 'Cash Loan' ? true : false);
        watch(() => store?.consentTemplate, (newValue) => {
            if (newValue) {
                consentList.value = useConsentDetailService(newValue.def).consentList;
                periodHuminizeTxt.value = useConsentDetailService(
                    newValue.def
                ).periodHuminizeTxt;
            }
        },
            { deep: true, immediate: true }
        );

        // Institution list to render
        const _all = computed(() =>
            Array.from(store.getInstitutions().value.values())
        );
        const fuse = new Fuse(_all.value, {
            keys: ["name", "id"],
            threshold: 0.1,
            distance: 50,
        });
        const fipList = computed(() => {
            //let result = _all.value;
            let fiBankList =
                loadFipsBasedOnAA(
                    listOfFinancialInstruments.value,
                    _all.value,
                    aaHandle.value
                ) || [];
            fiBankList.forEach((data) => {
                //data['isLoading'] = false;
                const fip = institutionWiseAccounts.value.get(data.id);
                if (fip) {
                    data["isLoading"] = fip.loading().value;
                } else {
                    data["isLoading"] = false;
                }
            });
            if (fipSearchQuery.value) {
                fuse.setCollection(fiBankList);
                fiBankList = fuse.search(fipSearchQuery.value).map((m) => m.item);
            }
            return fiBankList;
        });

        const fireEvents = debounce((eventName, payLoad) => {
            events.fire(eventName, payLoad);
        }, 1000);

        watch(fipSearchQuery, (newValue: string) => {
            if (newValue != "") {
                fireEvents(
                    ConductorEvent.FIP_SEARCH,
                    eventPayload(requestId.value, discriminator.value, { query: fipSearchQuery.value })
                );
            }
        });

        // Selection related stuff
        const multipleSelect = ref(false); // TODO: probably best to resolve this from org config
        const toDiscover = ref<Institution[]>([]);

        const exitDialog = ref(false);
        const consentDialog = ref(false);
        const unableFindBankDialog = ref(false);

        function exitModal() {
            exitDialog.value = true;
        }

        function openConsentModal() {
            consentDialog.value = true;
        }

        function closeModal() {
            consentDialog.value = false;
            unableFindBankDialog.value = false;
        }

        function proceedToDiscovery() {
            isProcessing.value = true;
            conductor.transitionToView(naviViews.discovery, JOURNEY_LAYOUTS.V3);
            conductor.discoverAccountsFromInstitutions(toDiscover.value);
        }

        function goBack() {
            conductor.navigateBack("selectBanks");
        }

        function tryAnotherMethod() {
            anotherMethod.value = true;
            if (existingFips.value.length > 0) {
                const data = {} as any;
                data.title = "Verify existing bank details";
                data.description = "Please verify already existing bank details.";
                data.btnStyle = "flex-col-reverse space-y-4 space-y-reverse";
                data.secondaryText = "Try Another Method",
                    (data.secondaryAction = () => conductor.triggerExitWorld(true, "", "ALTERNATIVE_CHOSEN", false));
                data.primaryText = "Verify Existing Bank";
                data.primaryAction = () => goBack();
                anotherMethodModal.value = data;
            } else {
                conductor.triggerExitWorld(true, "", "ALTERNATIVE_CHOSEN", false);
            }

        }
        // Define a sorting configuration object for fips
        const fipSortingConfig = [
            {
                check: hasSomeLinkedAccounts,
                priority: -1,
            },
            {
                check: hasSomeSavingsAccount,
                priority: -1,
            },
        ];
        const accountsSortingConfig = [
            {
                check: checkForLinkedAcc,
                priority: -1,
                message: "Objects with linked accounts come first",
            },
            {
                check: checkForSavingsAcc,
                priority: -1,
                message: "Objects with 'savings' account type come first",
            },
        ];

        // Custom sorting function based on the sorting configuration
        function customSortFips(a: any, b: any) {
            for (const criteria of fipSortingConfig) {
                const resultA = criteria.check(a.allAccounts().value);
                const resultB = criteria.check(b.allAccounts().value);
                if (resultA && !resultB) {
                    return criteria.priority;
                } else if (!resultA && resultB) {
                    return -criteria.priority;
                }
            }

            return 0;
        }

        function customAccountsSort(a: any, b: any) {
            for (const criteria of accountsSortingConfig) {
                const resultA = criteria.check(a);
                const resultB = criteria.check(b);
                if (resultA && !resultB) {
                    return criteria.priority;
                } else if (!resultA && resultB) {
                    return -criteria.priority;
                }
            }

            return 0;
        }

        // Function to check if an accounts list has linked accounts
        function hasSomeLinkedAccounts(accounts: any[]) {
            return accounts.some((account: { linkRefNumber: string; }) => account.linkRefNumber !== '');
        }

        // Function to check if an accounts list has 'savings' account type
        function hasSomeSavingsAccount(accounts: any[]) {
            return accounts.some((account: { accType: string; }) => account.accType === 'SAVINGS');
        }

        function checkForLinkedAcc(accounts: any) {
            return accounts.linkRefNumber !== '' || accounts.linkRefNumber !== undefined;
        }

        // Function to check if an accounts list has 'savings' account type
        function checkForSavingsAcc(accounts: any) {
            return accounts.accType === 'SAVINGS';
        }


        watch((toDiscover), (newValue) => {
            if (Object.entries(newValue).length > 0 && !templateValue.value.selectFips_showActions) {
                proceedToDiscovery();
            }
        }, { deep: true, immediate: true });

        onMounted(() => {
            selectFipTemplates.value = conductor.filterTemplates(
                "selectFips",
                store.templates
            );
            templateValue.value = splitTemplateString(
                selectFipTemplates.value,
                1,
                undefined
            );
            if (
                store.getFeature(ConductorFeatures.SELECT_FIPS_VIEW_TYPE) ===
                COMPONENT_VIEW_TYPE.MODAL
            ) {
                bankListStyle.value = {
                    listContainerStyle: "w-full grid gap-2",
                    bankContainerStyle: "w-full !flex !flex-row items-center justify-normal py-2.5 bg-white rounded-2xl cursor-pointer relative",
                    bankIconBoxStyle: "w-8 h-8 flex items-center justify-center bg-[#F5F5F5] rounded-full mr-2",
                    bankIconStyle: "max-w-[20px] max-h-[20px]",
                    fipNameStyle: "text-[#1A1A1A] font-NaviBodyRegular text-sm",
                    listSelectedStyle: "hover:custom-border custom-border",
                };
            } else {
                bankListStyle.value = {
                    listContainerStyle: "grid grid-cols-3 gap-4 items-start",
                    bankIconBoxStyle: "w-8 h-8 flex items-center justify-center",
                    bankIconStyle: "max-w-[20px] max-h-[20px]",
                    fipNameStyle: "text-xs text-brandNavi-400",
                    listSelectedStyle: "hover:custom-border custom-border",
                    bankContainerStyle: ""
                };
            }
            if (!templateValue.value.selectFips_showActions) {
                getExistingFips();
            }
        });

        function getExistingFips() {
            const data = Array.from(workingInstitutions.value.values()).sort(customSortFips);
            for (let val of data) {
                const sortedObj = {} as any;
                sortedObj['fipName'] = val.meta.name;
                sortedObj['fipId'] = val.meta.id;
                sortedObj['logo'] = val.meta.logo;
                sortedObj['accounts'] = val.allAccounts().value.sort(customAccountsSort);
                existingFips.value.push(sortedObj);
            }
            existingFips.value = existingFips.value.slice(0, 2);
        }

        // function triggerExitWorld(quite = false, errMsg?: string, errCode?: string) {
        //     conductor.exitTheWorld(quite, errMsg, errCode);
        // }

        // function unableFindBankModal() {
        //     unableFindBankDialog.value = true;
        // }

        return {
            XIcon,
            SearchIcon,
            ExclamationCircleIcon,
            fipSearchQuery,
            NumberOnly,

            fipList,
            consentList,
            periodHuminizeTxt,
            selectedFipList: toDiscover,

            consentDialog,
            unableFindBankDialog,
            validFipList: computed(() => toDiscover.value?.length > 0),

            bankSearchModal,
            exitModal,
            openConsentModal,
            closeModal,
            // unableFindBankModal,
            // triggerExitWorld,

            proceedToDiscovery,
            goBack,
            searchMode,
            toggleSearchMode,
            multipleSelect,
            isProcessing,
            brand,
            themeTemplates,
            templateValue,
            selectFipTemplates,
            bankListStyle,
            tryAnotherMethod,
            anotherMethod,
            anotherMethodModal,
            workingInstitutions,
            humanizeAccountType,
            getExistingFips,
            existingFips,
            defaultTheme
        };
    },
});
