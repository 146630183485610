
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrgInfosTemplate, useItrInfoStore, useOrganizationInfoTemplate } from '@/store';

const __default__ = defineComponent ({
  setup() {
    const { brandColor } = storeToRefs(useOrgInfosTemplate());
    const { itrBrandColor } = storeToRefs(useItrInfoStore());
    const { orgBrandColor } = storeToRefs(useOrganizationInfoTemplate());
    return {
      brandColor,
      itrBrandColor,
      orgBrandColor
    }
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "822e6cdc": (_ctx.brandColor),
  "5b2b2a26": (_ctx.itrBrandColor),
  "1774ce2c": (_ctx.orgBrandColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__